<template>
	<div class="container-fluid">
		<div class="row" v-if="!winecreated">
			<div class="col-12">
				<h1>{{ this.title }}</h1>

				<div v-if="wine" class="close_btn dark small_move" @click="closeEditOpen()">
					<i class="fal fa-times"></i>
				</div>
			</div>
		</div>

		<winecellar-flow-steps v-if="!winecreated" :action="action" :steps="steps" :activestep="activeStep" :goToStepParent="doNothing" :isStepParent="isStep"></winecellar-flow-steps>
		<transition name="fade-long">
			<div class="row" v-if="isStep('active', 0)">
				<div class="col-12 mb-3 text-champagne">Obligatoriske felter er markeret med *</div>

				<div class="col-12 col-md-6">
					<div class="input__wrapper" :class="{ 'error': details.name.error, 'required': details.name.required }">
						<label for="wine_name">Vinens navn</label>
						<input type="text" class="input" name="wine_name" @focus="details.name.error = false" v-model="details.name.content" @input="checkIfWineExists">
						<div v-if="details.name.error" class="input__error">{{ details.name.errormsg }}</div>
						<div v-if="wine_exists && !this.copy_wine" class="input__error">
							OBS! Der findes allerede en vin med dette navn.<br>
							Du kan hente den ind fra Globale Vine
						</div>
					</div>
					<div class="input__wrapper" :class="{ 'error': details.desc.error, 'required': details.name.required }">
						<label for="wine_desc">Beskrivelse</label>
						<textarea rows="9" class="input" name="wine_desc" @focus="details.desc.error = false" v-model="details.desc.content"></textarea>
						<div v-if="details.desc.error" class="input__error">{{ details.desc.errormsg }}</div>
					</div>
				</div>
				<div class="col-12 col-md-6 d-flex flex-wrap align-content-start">
					<div class="input__wrapper half" :class="{ 'error': details.fad.error, 'required': details.name.required }">
						<label for="wine_fad">Fadlagring (måneder)</label>
						<input type="number" class="input no-mobile-margin" @focus="details.fad.error = false" name="wine_fad" v-model="details.fad.content" :disabled="details.fad.disabled">
						<div v-if="details.fad.error" class="input__error">{{ details.fad.errormsg }}</div>
					</div>
					<div class="input__wrapper half">
						<label>&nbsp;</label>
						<label for="wine_fadcheck" class="input__label">
							<input type="checkbox" class="checkedbox" name="wine_fadcheck" id="wine_fadcheck" @input="changeDisabled('fad')" v-model="details.fad.check" value="1">
							<span class="check"><i class="far fa-check"></i></span>
							<span class="label">Denne vin har ingen fadlagring</span>
						</label>
					</div>
					
					<div class="input__wrapper half" :class="{ 'error': details.year.error, 'required': details.name.required }">
						<label for="wine_year">Årgang</label>
						<input type="number" class="input no-mobile-margin" @focus="details.year.error = false" name="wine_year" v-model="details.year.content" :disabled="details.year.disabled" @keyup="maxLengthYear">
						<div v-if="details.year.error" class="input__error">{{ details.year.errormsg }}</div>
					</div>
					<div class="input__wrapper half">
						<label>&nbsp;</label>
						<label for="wine_yearcheck" class="input__label">
							<input type="checkbox" class="checkedbox" name="wine_yearcheck" id="wine_yearcheck" @input="changeDisabled('year')" v-model="details.year.check" value="1">
							<span class="check"><i class="far fa-check"></i></span>
							<span class="label">Denne vin har ingen årgang</span>
						</label>
					</div>

					<div class="input__wrapper half" :class="{ 'error': details.alcpercent.error, 'required': details.name.required }">
						<label for="wine_alcpercent">Alkohol Procent</label>
						<div class="append">%</div>
						<input type="number" class="input" @focus="details.alcpercent.error = false" v-model="details.alcpercent.content" name="wine_alcpercent">
						<div v-if="details.alcpercent.error" class="input__error">{{ details.alcpercent.errormsg }}</div>
					</div>
					
					<div class="input__wrapper half" :class="{ 'error': details.type.error, 'required': details.name.required }">
						<label for="wine_type">Type</label>
						<v-select label="name" :options="search.types.data" @input="removeError(details.type)" v-model="details.type.content"></v-select>
						<div v-if="details.type.error" class="input__error">{{ details.type.errormsg }}</div>
					</div>
					
					<div class="input__wrapper full">
						<label>Evt. link til webshop</label>
						<div class="input__repeat">
							<div v-for="(shop, n) in details.shops.content" :key="n" class="input__remove">
								<input type="text" class="input" name="wine_webshop[]" v-model="details.shops.content[n].url">
								<div class="remove" @click="removeSingle('shops', n)">
									<i class="far fa-times"></i>
								</div>
							</div>
						</div>
						<button class="secondary icon input__repeat--button" @click="addWebshop()">
							Tilføj webshop
							<i class="far fa-plus"></i>
						</button>
					</div>
				</div>

				<div class="col-12 col-md-6">
					<div class="input__wrapper">
						<label for="wine_reviewcheck" class="input__label">
							<input type="checkbox" class="checkedbox" name="wine_reviewcheck" id="wine_reviewcheck" value="1" v-model="details.review.check">
							<span class="check"><i class="far fa-check"></i></span>
							<span class="label">Jeg vil gerne tilføje anmeldelser til denne vin</span>
						</label>
					</div>

					<div class="input__wrapper" data-show="review" v-show="details.review.check">
						<label for="wine_review">Anmeldelse</label>
						<textarea rows="6" class="input" name="wine_review" v-model="details.review.content"></textarea>
					</div>
				</div>

				<div class="col-12 col-md-6 justify-content-end d-flex">
					<button class="icon" @click="goToStep('next')" :disabled="this.button_disabled">
						Videre
						<i class="far fa-chevron-right"></i>
					</button>
				</div>
			</div>
		
			<div class="row" v-if="isStep('active', 1)">
				<div class="col-12 mb-3 text-champagne">Denne side er ikke obligatorisk</div>

				<image-uploader
					class="row"
					:current-images="details.current_images"
					:primary-image="details.images.primary"
					:max-images="1"
					@image-cropped="imageCropped"
					@image-deleted="deleteImage"
					@image-primary="setPrimaryImage"
				></image-uploader>

				<div class="col-12 col-md-6 justify-content-between justify-content-sm-end d-flex mt-3">
					<button class="btn-faded" @click="goToStep('prev')">Tilbage</button>
					<button class="icon" @click="cropImages();goToStep('next')">
						Videre
						<i class="far fa-chevron-right"></i>
					</button>
				</div>
			</div>
		
			<div class="row" v-if="isStep('active', 2)">
				<div class="col-12 mb-3 text-champagne">Obligatoriske felter er markeret med *</div>

				<div class="col-12 col-md-6 mb-3">
					<div class="input__wrapper" :class="{ 'error': details.country.error }">
						<label for="wine_country">Land</label>
						<v-select label="name" :options="search.countries.data" @input="removeNew('country');checkRegionsVineyards('country');" v-model="details.country.content"></v-select>
						<span class="fakelink" v-if="!details.country.new.check" @click="addNew('country')">Tilføj nyt land</span>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div class="input__wrapper" v-if="details.country.new.check">
						<label for="wine_new_country">Tilføj nyt land</label>
						<input type="text" class="input" @input="checkRegionsVineyards()" name="wine_new_country" v-model="details.country.new.content">
						<button class="secondary" @click="createCountry">Opret land</button>
					</div>
				</div>

				<div class="col-12 col-md-6 mb-3">
					<div class="input__wrapper" :class="{ 'error': details.region.error }">
						<label for="wine_region">Region</label>
						<v-select label="name" :options="search.regions" @input="removeNew('region');checkRegionsVineyards('region');" :disabled="details.region.disabled" v-model="details.region.content"></v-select>
						<span class="fakelink" v-if="!details.region.new.check" @click="addNew('region')">Tilføj ny region</span>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div class="input__wrapper" v-if="details.region.new.check">
						<label for="wine_new_region">Tilføj ny region</label>
						<input type="text" class="input" @input="checkRegionsVineyards()" name="wine_new_region" :disabled="details.region.disabled" v-model="details.region.new.content">
						<button class="secondary" @click="createRegion">Opret region</button>
					</div>
				</div>
				
				<div class="col-12 col-md-6 mb-3">
					<div class="input__wrapper" :class="{ 'error': details.vineyard.error }">
						<label for="wine_vineyard">Vingård</label>
						<v-select label="name" :options="search.vineyards" @input="removeNew('vineyard');checkRegionsVineyards('vineyard')" :disabled="details.vineyard.disabled" v-model="details.vineyard.content"></v-select>
						<span class="fakelink" v-if="!details.vineyard.new.check" @click="addNew('vineyard')">Tilføj ny vingård</span>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div class="input__wrapper" v-if="details.vineyard.new.check">
						<label for="wine_new_vineyard">Tilføj ny vingård</label>
						<input type="text" class="input" @input="checkRegionsVineyards()" name="wine_new_vineyard" :disabled="details.vineyard.disabled" v-model="details.vineyard.new.content">
						<button class="secondary" @click="createVineyard">Opret vingård</button>
					</div>
				</div>

				<div class="col-12 justify-content-between justify-content-sm-end d-flex mt-3">
					<button class="btn-faded" @click="goToStep('prev')">Tilbage</button>
					<button class="icon" @click="goToStep('next')">
						Videre
						<i class="far fa-chevron-right"></i>
					</button>
				</div>
			</div>
		
			<div class="row" v-if="isStep('active', 3)">
				<div class="col-12 mb-3 text-champagne">Obligatoriske felter er markeret med *</div>

				<div class="col-12 col-md-6 mb-3">
					<div class="input__wrapper" :class="{ 'error': details.grapes.error }">
						<label for="wine_grapes">Druer</label>
						<v-select 
							label="name" 
							:options="search.grapes.data" 
							id="specialTrigger" 
							@input="addGrapesToArray" 
							:map-keydown="handlers" 
							:value="details.grapes.content" 
							multiple
							:filter="filterGrapes"
						></v-select>
						<span class="fakelink" v-if="!details.grapes.new.check" @click="addNew('grapes')">Tilføj ny druesort</span>
					</div>
				</div>
				<div class="col-12 col-md-6 mb-3 pt-3">
					<div v-for="(grape, l) in details.grapes.content" :key="l" class="multiple__item">
						{{ grape.name }}
						<span class="remove" @click="removeSingle('grapes', l)">
							<i class="far fa-times"></i>
						</span>
					</div>
				</div>

				<div class="col-12 col-md-6">
					<div class="input__wrapper" v-if="details.grapes.new.check">
						<label for="wine_new_grapes">Tilføj ny druesort</label>
						<input type="text" class="input" name="wine_new_grape" v-model="details.grapes.new.content">
						<button class="secondary align-self-end" @click="createGrape">
							Tilføj ny druesort
							<i class="far fa-plus"></i>
						</button>
					</div>
				</div>

				<div class="col-12 col-md-6 justify-content-between justify-content-sm-end d-flex mt-3">
					<button class="btn-faded" @click="goToStep('prev')">Tilbage</button>
					<button class="icon" @click="goToStep('next')">
						Videre
						<i class="far fa-chevron-right"></i>
					</button>
				</div>
			</div>
		
			<div class="row" v-if="isStep('active', 4)">
				<div class="col-12 mb-3 text-champagne">Denne side er ikke obligatorisk</div>

				<div class="col-12 col-md-6 mb-3">
					<div class="input__wrapper" :class="{ 'error': details.fragrances.error }">
						<label for="wine_fragrances">Duftnuancer</label>
						<v-select label="name" :options="search.fragrances.data" @input="removeError(details.fragrances)" :map-keydown="handlers" v-model="details.fragrances.content" multiple></v-select>
						<span class="fakelink" v-if="!details.fragrances.new.check" @click="addNew('fragrances')">Tilføj ny duftnuance</span>
					</div>
				</div>

				<div class="col-12 col-md-6 mb-3 pt-3">
					<div v-for="(fragrance, l) in details.fragrances.content" :key="l" class="multiple__item">
						{{ fragrance.name }}
						<span class="remove" @click="removeSingle('fragrances', l)">
							<i class="far fa-times"></i>
						</span>
					</div>
				</div>

				<div class="col-12 col-md-6">
					<div class="input__wrapper" v-if="details.fragrances.new.check">
						<label for="wine_new_fragrances">Tilføj ny duftnuance</label>
						<input type="text" class="input" name="wine_new_fragrance" v-model="details.fragrances.new.content">
						<button class="secondary align-self-end" @click="createNuance('fragrances')">
							Tilføj ny duftnuance
							<i class="far fa-plus"></i>
						</button>
					</div>
				</div>

				<div class="col-12 col-md-6"></div>

				<div class="col-12 col-md-6 mb-3">
					<div class="input__wrapper" :class="{ 'error': details.tastes.error }">
						<label for="wine_tastes">Smagsnuancer</label>
						<v-select label="name" :options="search.tastes.data" @input="removeError(details.tastes)" :map-keydown="handlers" v-model="details.tastes.content" multiple></v-select>
						<span class="fakelink" v-if="!details.tastes.new.check" @click="addNew('tastes')">Tilføj ny smagsnuance</span>
					</div>
				</div>

				<div class="col-12 col-md-6 mb-3 pt-3">
					<div v-for="(taste, l) in details.tastes.content" :key="l" class="multiple__item">
						{{ taste.name }}
						<span class="remove" @click="removeSingle('tastes', l)">
							<i class="far fa-times"></i>
						</span>
					</div>
				</div>

				<div class="col-12 col-md-6">
					<div class="input__wrapper" v-if="details.tastes.new.check">
						<label for="wine_new_tastes">Tilføj ny smagsnuance</label>
						<input type="text" class="input" name="wine_new_taste" v-model="details.tastes.new.content">
						<button class="secondary align-self-end" @click="createNuance('tastes')">
							Tilføj ny smagsnuance
							<i class="far fa-plus"></i>
						</button>
					</div>
				</div>

				<div class="col-12 col-md-6 justify-content-between justify-content-sm-end d-flex mt-3">
					<button class="btn-faded" @click="goToStep('prev')">Tilbage</button>
					<button class="icon" @click="goToStep('next')">
						Videre
						<i class="far fa-chevron-right"></i>
					</button>
				</div>
			</div>
		
			<div class="row" v-if="isStep('active', 5)">
				<div class="col-12 mb-3 text-champagne">Denne side er ikke obligatorisk</div>
				
				<div class="col-12 col-md-6">
					<h3 class="mb-3">{{ assessment[wineType].data.palette.label }}</h3>
					<div class="input__wrapper" v-for="(dropdown, d) in assessment[wineType].data.palette.data" :key="`palette_${d}`">
						<label>{{ dropdown.label }}</label>
						<v-select label="label" :options="dropdown.data" :reduce="option => option.value" :map-keydown="handlers" v-model="details.assessment.palette[d]"></v-select>
					</div>
				</div>

				<div class="col-12 col-md-6">
					<h3 class="mb-3">{{ assessment[wineType].data.appearance.label }}</h3>
					<div class="input__wrapper" v-for="(dropdown, d) in assessment[wineType].data.appearance.data" :key="`appearance_${d}`">
						<label>{{ dropdown.label }}</label>
						<v-select label="label" :options="dropdown.data" :reduce="option => option.value" :map-keydown="handlers" v-model="details.assessment.appearance[d]"></v-select>
					</div>

					<h3 class="mb-3">{{ assessment[wineType].data.nose.label }}</h3>
					<div class="input__wrapper" v-for="(dropdown, d) in assessment[wineType].data.nose.data" :key="`nose_${d}`">
						<label>{{ dropdown.label }}</label>
						<v-select label="label" :options="dropdown.data" :reduce="option => option.value" :map-keydown="handlers" v-model="details.assessment.nose[d]"></v-select>
					</div>
				</div>

				<div class="col-12 justify-content-between justify-content-sm-end d-flex mt-3" :class="{ 'flex-column align-items-end' : this.action == 'accept' }">
					<button class="" @click="goToStep('prev')">Tilbage</button>
					<button v-if="this.action == null" class="success" @click="createWine">Opret vin</button>
					<button v-if="this.action == 'edit'" class="success" @click="updateWine">Opdater vin</button>
					<button v-if="this.action == 'accept'" class="success icon" @click="acceptWine(wine)">Accepter vin <i class="far fa-check"></i></button>
					<button v-if="this.action == 'add'" class="success" @click="addWineToWineBar(wine.id)">Tilføj vin</button>
				</div>
			</div>

			<winecellar-thankyou v-if="isStep('active', 6)" :title="thankyou.title" :description="thankyou.description" :icon="thankyou.icon" :primaryButton="thankyou.primaryButton"></winecellar-thankyou>
		</transition>

		<div v-if="this.loading" class="modal show fade" id="spinner-modal" tabindex="-1" role="dialog" aria-labelledby="spinner-modal" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered w-100 text-center" role="document">
				<div class="row h-100 d-flex align-items-center m-auto">
					<i class="far fa-spinner-third fa-spin fa-5x fa-fw" style="color:#A60000;"></i>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
    export default {
		props: {
            title: {
                type: String,
            },
            action: {
                type: String,
			},
			wine: {
				type: Object,
			},
			parentCloseEditOpen: {
				type: Function
			},
			wine_bar: {
				type: Object
			},
			copy_wine: {
				type: Boolean
			}
        },
		data() {
			return {
				assessment: {
					white: {
						label: 'Hvid',
						data: {
							appearance: {
								label: 'Appearance',
								data: {
									intensity: {
										label: 'Intensity',
										data: [
											{ label: 'Pale', value: 1 },
											{ label: 'Medium', value: 2 },
											{ label: 'Deep', value: 3 },
										],
									},
									color: {
										label: 'Colour',
										data: [
											{ label: 'Lemon', value: 4 },
											{ label: 'Gold', value: 5 },
											{ label: 'Amber', value: 6 },
										],
									}
								}
							},
							nose: {
								label: 'Nose',
								data: {
									intensity: {
										label: 'Intensity',
										data: [
											{ label: 'Light', value: 7 },
											{ label: 'Medium', value: 8 },
											{ label: 'Pronounced', value: 9 },
										],
									},
								}
							},
							palette: {
								label: 'Palete',
								data: {
									sweetness: {
										label: 'Sweetness',
										data: [
											{ label: 'Dry', value: 10 },
											{ label: 'Off-Dry', value: 11 },
											{ label: 'Medium', value: 12 },
											{ label: 'Sweet', value: 13 },
										],
									},
									acidity: {
										label: 'Acidity',
										data: [
											{ label: 'Low', value: 14 },
											{ label: 'Medium', value: 15 },
											{ label: 'High', value: 16 },
										],
									},
									alcohol: {
										label: 'Alcohol',
										data: [
											{ label: 'Low', value: 17 },
											{ label: 'Medium', value: 18 },
											{ label: 'High', value: 19 },
										],
									},
									body: {
										label: 'Body',
										data: [
											{ label: 'Low', value: 20 },
											{ label: 'Medium', value: 21 },
											{ label: 'High', value: 22 },
										],
									},
									flavor: {
										label: 'Flavour',
										data: [
											{ label: 'Low', value: 23 },
											{ label: 'Medium', value: 24 },
											{ label: 'Pronounced', value: 25 },
										],
									},
									finish: {
										label: 'Finish',
										data: [
											{ label: 'Short', value: 26 },
											{ label: 'Medium', value: 27 },
											{ label: 'Long', value: 28 },
										],
									},
								},
							},
						}
					},
					rose: {
						label: 'Rosé',
						data: {
							appearance: {
								label: 'Appearance',
								data: {
									intensity: {
										label: 'Intensity',
										data: [
											{ label: 'Pale', value: 29 },
											{ label: 'Medium', value: 30 },
											{ label: 'Deep', value: 31 },
										],
									},
									color: {
										label: 'Colour',
										data: [
											{ label: 'Pink', value: 32 },
											{ label: 'Pink-Orange', value: 33 },
											{ label: 'Orange', value: 34 },
										],
									}
								}
							},
							nose: {
								label: 'Nose',
								data: {
									intensity: {
										label: 'Intensity',
										data: [
											{ label: 'Light', value: 35 },
											{ label: 'Medium', value: 36 },
											{ label: 'Pronounced', value: 37 },
										],
									},
								}
							},
							palette: {
								label: 'Palete',
								data: {
									sweetness: {
										label: 'Sweetness',
										data: [
											{ label: 'Dry', value: 38 },
											{ label: 'Off-Dry', value: 39 },
											{ label: 'Medium', value: 40 },
											{ label: 'Sweet', value: 41 },
										],
									},
									acidity: {
										label: 'Acidity',
										data: [
											{ label: 'Low', value: 42 },
											{ label: 'Medium', value: 43 },
											{ label: 'High', value: 44 },
										],
									},
									alcohol: {
										label: 'Alcohol',
										data: [
											{ label: 'Low', value: 45 },
											{ label: 'Medium', value: 46 },
											{ label: 'High', value: 47 },
										],
									},
									body: {
										label: 'Body',
										data: [
											{ label: 'Low', value: 48 },
											{ label: 'Medium', value: 49 },
											{ label: 'High', value: 50 },
										],
									},
									flavor: {
										label: 'Flavour',
										data: [
											{ label: 'Low', value: 51 },
											{ label: 'Medium', value: 52 },
											{ label: 'Pronounced', value: 53 },
										],
									},
									finish: {
										label: 'Finish',
										data: [
											{ label: 'Short', value: 54 },
											{ label: 'Medium', value: 55 },
											{ label: 'Long', value: 56 },
										],
									},
								},
							},
						}
					},
					red: {
						label: 'Rød',
						data: {
							appearance: {
								label: 'Appearance',
								data: {
									intensity: {
										label: 'Intensity',
										data: [
											{ label: 'Pale', value: 57 },
											{ label: 'Medium', value: 58 },
											{ label: 'Deep', value: 59 },
										],
									},
									color: {
										label: 'Colour',
										data: [
											{ label: 'Purple', value: 60 },
											{ label: 'Ruby', value: 61 },
											{ label: 'Garnet', value: 62 },
											{ label: 'Tawny', value: 63 },
										],
									}
								}
							},
							nose: {
								label: 'Nose',
								data: {
									intensity: {
										label: 'Intensity',
										data: [
											{ label: 'Light', value: 64 },
											{ label: 'Medium', value: 65 },
											{ label: 'Pronounced', value: 66 },
										],
									},
								}
							},
							palette: {
								label: 'Palete',
								data: {
									sweetness: {
										label: 'Sweetness',
										data: [
											{ label: 'Dry', value: 67 },
											{ label: 'Off-Dry', value: 68 },
											{ label: 'Medium', value: 69 },
											{ label: 'Sweet', value: 70 },
										],
									},
									acidity: {
										label: 'Acidity',
										data: [
											{ label: 'Low', value: 71 },
											{ label: 'Medium', value: 72 },
											{ label: 'High', value: 73 },
										],
									},
									tannin: {
										label: 'Tannin',
										data: [
											{ label: 'Low', value: 74 },
											{ label: 'Medium', value: 75 },
											{ label: 'High', value: 76 },
										],
									},
									alcohol: {
										label: 'Alcohol',
										data: [
											{ label: 'Low', value: 77 },
											{ label: 'Medium', value: 78 },
											{ label: 'High', value: 79 },
										],
									},
									body: {
										label: 'Body',
										data: [
											{ label: 'Low', value: 80 },
											{ label: 'Medium', value: 81 },
											{ label: 'High', value: 82 },
										],
									},
									flavor: {
										label: 'Smag',
										data: [
											{ label: 'Low', value: 83 },
											{ label: 'Medium', value: 84 },
											{ label: 'Pronounced', value: 85 },
										],
									},
									finish: {
										label: 'Finish',
										data: [
											{ label: 'Short', value: 86 },
											{ label: 'Medium', value: 87 },
											{ label: 'Long', value: 88 },
										],
									},
								},
							},
						}
					},
				},
				cropper: {
					minwidth: 150,
					minheight: 200
				},
				testing: false,
				loading: false,
				activeStep: 1,
				// activeStep: 6,
				winecreated: false,
				wine_exists: false,
				button_disabled: false,
				max_images: 1,
				allow_adding_images: true,
				grapeFocus: false,
				cropper: {
					min_width: 150,
					min_height: 200
				},
				search: {
					types: [],
					countries: [],
					regions: [],
					vineyards: [],
					grapes: [],
					fragrances: [],
					tastes: [],
				},
				details: {
					id: {
						content: null
					},
					name: {
						step: 1,
						required: true,
						content: '',
						error: false,
						errormsg: 'Der skal angives et navn',
					},
					desc: {
						step: 1,
						required: true,
						content: '',
						error: false,
						errormsg: 'Der skal angives en beskrivelse',
					},
					fad: {
						step: 1,
						required: true,
						content: '',
						check: false,
						disabled: false,
						error: false,
						errormsg: 'Der skal angives en fadlagring, eller afkrydse feltet for ingen fadlagring',
					},
					year: {
						step: 1,
						required: true,
						content: '',
						check: false,
						disabled: false,
						error: false,
						errormsg: 'Der skal angives en årgang, eller afkrydse feltet for ingen årgang',
					},
					alcpercent: {
						step: 1,
						required: true,
						content: '',
						error: false,
						errormsg: 'Fejl!',
					},
					type: {
						step: 1,
						required: true,
						content: '',
						// content: {"id":2,"name":"Hvid","model":"App\\Wine"},
						error: false,
						errormsg: 'Fejl!',
					},
					review: {
						step: 1,
						content: '',
						check: false,
					},
					shops: {
						step: 1,
						content: [{url: ''}],
					},
					images: {
						step: 2,
						content: [],
						check: false,
						error: false,
						errormsg: 'Fejl!',
					},
					country: {
						step: 3,
						required: true,
						error: false,
						errormsg: 'Fejl!',
						content: '',
						new: {
							check: false,
							content: '',
						},
					},
					region: {
						step: 3,
						required: true,
						error: false,
						errormsg: 'Fejl!',
						disabled: true,
						content: '',
						new: {
							check: false,
							content: '',
						},
					},
					vineyard: {
						step: 3,
						required: true,
						error: false,
						disabled: true,
						errormsg: 'Fejl!',
						content: '',
						new: {
							check: false,
							content: '',
						},
					},
					grapes: {
						step: 4,
						required: true,
						error: false,
						errormsg: 'Du skal vælge mindst én drue i vinen.',
						new: {
							check: false,
							content: ''
						},
						content: []
					},
					fragrances: {
						step: 5,
						required: false,
						error: false,
						errormsg: 'Fejl!',
						new: {
							check: false,
							content: ''
						},
						content: []
					},
					tastes: {
						step: 6,
						required: false,
						error: false,
						errormsg: 'Fejl!',
						new: {
							check: false,
							content: ''
						},
						content: []
					},
					assessment: {
						palette: {
							sweetness: null,
							acidity: null,
							tannin: null,
							alcohol: null,
							body: null,
							flavor: null,
							finish: null,
						},
						appearance: {
							intensity: null,
							color: null,
						},
						nose: {
							intensity: null,
						},
					},
					new_images: {
						content: []
					},
					current_images: {
						content: [],
					},
					deleted_images: {
						content: []
					},
				},
				steps: [
					'Detaljer',
					'Billede',
					'Oprindelse',
					'Druer',
					'Nuancer',
					'Vurdering',
				],
				thankyou: {
					icon: '<svg height="318" viewBox="0 0 318.639 318.639" width="318" xmlns="http://www.w3.org/2000/svg"><g fill="#BFA271"><path d="m223.256 31.937-29.872-29.872a7.041 7.041 0 0 0 -9.959 0l-9.959 9.959a7.041 7.041 0 0 0 0 9.959l-33.74 33.74a56.29 56.29 0 0 0 -61.772 11.983l-69.7 69.7a28.168 28.168 0 0 0 0 39.831l39.831 39.831a28.167 28.167 0 0 0 39.831 0l69.7-69.7a56.289 56.289 0 0 0 11.984-61.777l33.74-33.74a7.041 7.041 0 0 0 9.959 0l9.959-9.959a7.04 7.04 0 0 0 0-9.954zm-144.382 154.342-39.831-39.831 53.706-53.706 39.831 39.831z" transform="matrix(.70710678 -.70710678 .70710678 .70710678 -.007 159.323)"/><path d="m64.167 80.268-23.689 23.689-8.7-8.7a1.081 1.081 0 0 0 -1.528 0l-2.55 2.543a1.081 1.081 0 0 0 0 1.528l12.015 12.015a1.081 1.081 0 0 0 1.528 0l27-27a1.081 1.081 0 0 0 0-1.528l-2.543-2.547a1.081 1.081 0 0 0 -1.533 0z" transform="translate(112.344 100.699)"/></g></svg>',
					title: 'Din vin er oprettet',
					description: '<p>Du kan nu se din vin på vinkortet.</p><p>Har du endnu en vin du skal oprette, kan du gøre det via knappen herunder, ellers kan du tage direkte tilbage til kontrolpanelet.</p>',
					primaryButton: {
						link: '/wines/create',
						text: 'Opret en ny vin',
					},
				}
			}
		},
        created() {
			this.getTypes();
			this.getGrapes();
			this.getCountries();
			this.getNuances();

			if (this.action != null) {
				this.setContent(this.wine);
			}

			if (this.testing) {
				this.details.name.content = 'Jävla';
				this.details.desc.content = 'DER TESTES';
				this.details.fad.content = '12';
				this.details.year.content = '2019';
				this.details.alcpercent.content = '16';

				let _this = this;
				setTimeout(function() {
					_this.details.type.content = _this.search.types.data[0];
				}, 1500);
			}
		},
		mounted() {},
		methods: {
			filterGrapes(options, search) {
				search = this.normalizeString(search)

				return options.filter((option) => {
					let name = this.normalizeString(option.name)
					
					return name.includes(search)
				})
			},
			normalizeString(string) {
				return string.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()
			},
			imageCropped(path) {
				this.details.current_images.content.push({
					path: path
				})

				this.details.new_images.content.push({
					path: path
				})
			},
			setPrimaryImage(i) {
				this.details.images.primary = i
			},
			toggleLoading: function() {
				this.loading = this.loading ? false : true;

				if (this.loading) {
					$('body').append('<div id="modal-remove" class="modal-backdrop fade show"></div>');
				} else {
					$('#modal-remove').remove();
				}
			},
			commaToDot: function(input) {
				return input.toString().replace(',', '.');
			},
			maxLengthYear: function() {
				if (this.details.year.content.toString().length > 4) {
					this.details.year.content = this.details.year.content.slice(0, -1);
				}
			},
			doNothing: function() {
				// do nothing
				// Vi plejede at bruge goToStep, men hvis man trykkede frem til et step man ikke var nået til endnu, så gik det galt. Så dette var lige den hurtigeste løsning jeg kunne finde.
			},
			addWebshop: function() {
				this.details.shops.content.push({url: ''});
			},
			removeSingle: function(type, l) {
				this.details[type].content.splice(l, 1);
			},
			addNewSingle: function(type) {
				var getNew = this.details[type].new.content;
				this.details[type].new.content = '';				
				this.details[type].content.push(getNew);
			},
			changeDisabled: function(type) {
				var disable_this = this.details[type];

				disable_this.error = false;

				if(!disable_this.check){
					disable_this.content = 'Ingen';
					disable_this.disabled = true;
				} else {
					disable_this.content = '';
					disable_this.disabled = false;
				}
			},
			goToStep: function(i){
				this.grapeFocus = false;
				var astep = this.activeStep;
				var error = false;

				if(i == 'next'){
					error = this.validateStep();

					if(!error){
						this.activeStep = 0;
					}
				} else {
					this.activeStep = 0;
				}

				var self = this;

				setTimeout(function(){
					if(i == 'next'){
						if( !error ){
							self.activeStep = (astep + 1);
						}
					} else if(i == 'prev'){
						self.activeStep = (astep - 1);
					} else {
						if( i < astep || self.action == 'edit' ){
							self.activeStep = i;
						}
					}
				}, 10);
			},
			validateStep: function(){
				var error = false;
				var activeStep = this.activeStep;

				jQuery.each(this.details, function(index, val){
					var required = val.required;

					if(required === true && val.step === activeStep){
						if(val.check !== undefined){
							if(val.check === false && val.content == ''){
								error = true;
								val.error = true;
							}
						} else if(val.content == '' || val.content == null) {
							error = true;
							val.error = true;
						}
					}
				});

				return error;
			},
			isStep: function(type, k){
				var $ret = false;

				if(type == 'active' && k == (this.activeStep - 1)){
					$ret = true;
				} else if(type == 'done' && k < (this.activestep - 1)){
					$ret = true;
				}

				return $ret;
			},
			addNew: function(type){
				switch(type){
					case 'country':
					case 'region':
					case 'vineyard':
						this.details[type].content = '';
						break;
				}

				this.details[type].new.check = true;
			},
			removeNew: function(type){
				var $this = this.details[type];

				$this.new.check = false;
				$this.new.content = '';
				$this.error = false;
			},
			removeError: function(here) {
				here.error = false;
			},
			createWine: function() {
				var error = this.validateStep();
				let _this = this;

				if( !error ) {
					this.toggleLoading();
					let wine = new FormData();

					wine.append('name', this.details.name.content);
					wine.append('alc_vol', this.commaToDot(this.details.alcpercent.content));
					wine.append('description', this.details.desc.content);
					wine.append('grapes', JSON.stringify(this.pluck(this.details.grapes.content, 'id')));
					wine.append('fragrances', JSON.stringify(this.pluck(this.details.fragrances.content, 'id')));
					wine.append('tastes', JSON.stringify(this.pluck(this.details.tastes.content, 'id')));
					wine.append('webshops', JSON.stringify(this.details.shops.content));
					wine.append('assessment', JSON.stringify(this.details.assessment));
					wine.append('type_id', this.details.type.content.id);
					wine.append('vineyard_id', this.details.vineyard.content.id);
					wine.append('wine_bar_id', this.wine_bar.id);

					if (Boolean(Laravel.is_administrator)) {
						wine.append('is_custom', 0);
						wine.append('is_declined', 0);
					}

					if (this.details.new_images.content.length > 0) {
						wine.append('images', JSON.stringify(this.details.new_images.content));
					}

					wine.append('cask', JSON.stringify(!this.details.fad.check));

					if (!this.details.fad.check) {
						wine.append('cask_month', this.details.fad.content);
					}

					if (!this.details.year.check) {
						wine.append('year', this.details.year.content);
					}

					if (this.details.review.check) {
						wine.append('review', this.details.review.content);
					}
					
					axios.post('/api/wines', wine).then((response) => {
						this.toggleLoading();
						_this.winecreated = true;
						_this.goToStep('next');
					})
					.catch((error) => {
						console.warn(error);
					});
				}
			},
			updateWine: function() {
				var error = this.validateStep();
				let _this = this;

				if( !error ) {
					this.toggleLoading();
					let wine = new FormData();

					wine.append('_method', 'PATCH');

					wine.append('name', this.details.name.content);
					wine.append('alc_vol', this.commaToDot(this.details.alcpercent.content));
					wine.append('description', this.details.desc.content);
					wine.append('grapes', JSON.stringify(this.pluck(this.details.grapes.content, 'id')));
					wine.append('fragrances', JSON.stringify(this.pluck(this.details.fragrances.content, 'id')));
					wine.append('tastes', JSON.stringify(this.pluck(this.details.tastes.content, 'id')));
					wine.append('webshops', JSON.stringify(this.details.shops.content));
					wine.append('assessment', JSON.stringify(this.details.assessment));
					wine.append('type_id', this.details.type.content.id);
					wine.append('vineyard_id', this.details.vineyard.content.id);

					if (this.details.new_images.content.length > 0) {
						wine.append('images', JSON.stringify(this.details.new_images.content));
					}

					if (this.details.deleted_images.content.length > 0) {
						wine.append('deleted_images', JSON.stringify(this.details.deleted_images.content));
					}

					wine.append('cask', JSON.stringify(!this.details.fad.check));

					if (!this.details.fad.check) {
						wine.append('cask_month', this.details.fad.content);
					}

					if (!this.details.year.check) {
						wine.append('year', this.details.year.content);
					}

					if (this.details.review.check) {
						wine.append('review', this.details.review.content);
					}
					
					axios.post('/api/wines/' + this.details.id.content, wine)
						.then((response) => {
							_this.winecreated = true;
							_this.goToStep('next');
							this.toggleLoading();
						})
						.catch((error) => {
							console.warn(error);
							this.toggleLoading();
						});
				}
			},
			acceptWine: function(wine) {
				console.log(wine)
				var error = this.validateStep();
				let _this = this;

				if( !error ) {
					let newWine = new FormData();

					newWine.append('name', this.details.name.content);
					newWine.append('alc_vol', this.commaToDot(this.details.alcpercent.content));
					newWine.append('description', this.details.desc.content);
					newWine.append('grapes', JSON.stringify(this.pluck(this.details.grapes.content, 'id')));
					newWine.append('fragrances', JSON.stringify(this.pluck(this.details.fragrances.content, 'id')));
					newWine.append('tastes', JSON.stringify(this.pluck(this.details.tastes.content, 'id')));
					newWine.append('webshops', JSON.stringify(this.details.shops.content));
					newWine.append('assessment', JSON.stringify(this.details.assessment));
					newWine.append('type_id', this.details.type.content.id);
					newWine.append('vineyard_id', this.details.vineyard.content.id);
					newWine.append('is_custom', 0);
					newWine.append('is_declined', 0);

					if (this.details.images.content.length > 0) {
						newWine.append('images', JSON.stringify(this.details.images.content));
					}

					newWine.append('cask', JSON.stringify(!this.details.fad.check));

					if (!this.details.fad.check) {
						newWine.append('cask_month', this.details.fad.content);
					}

					if (!this.details.year.check) {
						newWine.append('year', this.details.year.content);
					}

					if (this.details.review.check) {
						newWine.append('review', this.details.review.content);
					}

					if (this.details.current_images.content.length > 0) {
						newWine.append('current_images', JSON.stringify(this.details.current_images.content));
					}
					
					axios.post('/api/wines', newWine)
						.then((response) => {
							_this.winecreated = true;
							_this.goToStep('next');

							axios.patch('/api/accepted-wines/' + wine.id, {
								'is_declined': 0,
							}).then((response) => {})
							.catch((error) => {
								console.warn(error);
							});
						})
						.catch((error) => {
							console.warn(error);
						});
				}
			},
			addWineToWineBar: function() {
				var error = this.validateStep();
				let _this = this;

				if( !error ) {
					let wine = new FormData();

					wine.append('name', this.details.name.content);
					wine.append('alc_vol', this.commaToDot(this.details.alcpercent.content));
					wine.append('description', this.details.desc.content);
					wine.append('grapes', JSON.stringify(this.pluck(this.details.grapes.content, 'id')));
					wine.append('fragrances', JSON.stringify(this.pluck(this.details.fragrances.content, 'id')));
					wine.append('tastes', JSON.stringify(this.pluck(this.details.tastes.content, 'id')));
					wine.append('webshops', JSON.stringify(this.details.shops.content));
					wine.append('assessment', JSON.stringify(this.details.assessment));
					wine.append('type_id', this.details.type.content.id);
					wine.append('vineyard_id', this.details.vineyard.content.id);
					wine.append('is_custom', 1);
					wine.append('is_declined', 1);

					if (this.details.current_images.content.length > 0) {
						wine.append('current_images', JSON.stringify(this.details.current_images.content));
					}

					if (this.details.images.content != '') {
						wine.append('images', this.details.images.content);
					}

					wine.append('cask', JSON.stringify(!this.details.fad.check));

					if (!this.details.fad.check) {
						wine.append('cask_month', this.details.fad.content);
					}

					if (!this.details.year.check) {
						wine.append('year', this.details.year.content);
					}

					if (this.details.review.check) {
						wine.append('review', this.details.review.content);
					}
					
					axios.post('/api/wines', wine)
						.then((response) => {
							axios.post('/api/winebar-wines', {
									wine_id: response.data.data.id,
									wine_bar_id: _this.wine.wine_bar_id,
								}).then((response) => {
									_this.winecreated = true;
									_this.goToStep('next');
								})
								.catch((error) => {
									console.warn(error);
								});
						})
						.catch((error) => {
							console.warn(error);
						});
				}
			},
			getCountries() {
				axios.get('/api/countries?limit=10000')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.search.countries = response.data;
							var self = this;

							if(this.details.country.content != ''){
								jQuery.each(this.search.countries.data, function(index, value){
									if(value.id == self.details.country.content.id){
										self.search.regions = value.regions;

										if(self.details.region.content != ''){
											jQuery.each(value.regions, function(i, v){
												if(v.id == self.details.region.content.id){
													self.search.vineyards = v.vineyards;
												}
											});
										} else {
											return;
										}
									}
								});
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			checkRegionsVineyards(type) {
				switch(type){
					case 'country':
						this.details.region.content = '';
						this.details.region.disabled = false;
						this.search.regions = this.details.country.content.regions;

						this.details.vineyard.content = '';
						this.details.vineyard.disabled = true;
						break;
					case 'region':
						this.details.vineyard.content = '';
						this.details.vineyard.disabled = false;

						this.search.vineyards = this.details.region.content.vineyards;
						break;
				}
			},
			getTypes() {
				axios.get('/api/wine-types')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.search.types = response.data;
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getGrapes() {
				axios.get('/api/grapes?limit=10000')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.search.grapes = response.data;
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getNuances() {
				axios.get('/api/nuances?limit=10000')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.search.fragrances = response.data;
							this.search.tastes = response.data;
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			addImage (e) {
				this.details.images.content.push(event.target.files[0]);
				this.toggleAllowAddingImages();
			},
			removeImage(index) {
				this.details.images.content.splice(index, 1);
				this.toggleAllowAddingImages();
			},
			deleteImage(image) {
				this.details.current_images.content.splice(this.details.current_images.content.findIndex(o => o.id === image.id), 1);
				this.details.deleted_images.content.push(image.id);
				this.toggleAllowAddingImages();
			},
			uploadImage(event) {
				// Reference to the DOM input element
				var input = event.target;
				// Ensure that you have a file before attempting to read it
				if (input.files && input.files[0]) {
						// create a new FileReader to read this image and convert to base64 format
						var reader = new FileReader();
						// Define a callback function to run, when FileReader finishes its job
						reader.onload = (e) => {
								// Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
								// Read image as base64 and set to imageData
								// this.details.images.content = e.target.result;

								let img = new Image();

								img.src = e.target.result;

								var _this = this;

								img.onload = () => {
									let imgWidth = img.naturalWidth;
									let imgHeight = img.naturalHeight;

									if(imgWidth >= this.cropper.min_width && imgHeight >= this.cropper.min_height){
										_this.details.images.content.push(e.target.result);
										_this.toggleAllowAddingImages();
									} else {
										alert(`Billedet er for lille. Det skal være mindst ${this.cropper.min_width}px bredt og ${this.cropper.min_height}px højt`);
									}
								};
						}
						// Start the reader job - read file as a data url (base64 format)
						reader.readAsDataURL(input.files[0]);
				}
			},
			cropImages() {
				let _this = this;

				$(this.$refs.cropper).each(function(i, cropper) {
					const {coordinates, canvas} = cropper.getResult();
					_this.details.images.coordinates = coordinates;
					_this.details.images.content[i] = canvas.toDataURL();
				});
			},
			pluck(array, key) {
				return array.map(o => o[key]);
			},
			setContent: function(wine) {
				let _this = this;
				
				this.details.id.content = wine.id;
				this.details.name.content = wine.name;
				this.details.desc.content = wine.description;
				this.details.alcpercent.content = wine.alc_vol;
				this.details.type.content = wine.type;

				this.details.country.content = wine.vineyard.region.country;
				this.details.region.content = wine.vineyard.region;
				this.details.vineyard.content = wine.vineyard;

				this.details.grapes.content = wine.grapes;
				this.details.fragrances.content = wine.fragrances;
				this.details.tastes.content = wine.tastes;
				
				this.details.region.disabled = false;
				this.details.vineyard.disabled = false;

				if (wine.cask) {
					this.details.fad.check = true;
					this.changeDisabled('fad');
					this.details.fad.content = wine.cask_month;
					this.details.fad.check = false;
				} else {
					this.details.fad.check = false;
					this.changeDisabled('fad');
					this.details.fad.check = true;
				}

				if (wine.year != null) {
					this.details.year.check = true;
					this.changeDisabled('year');
					this.details.year.content = wine.year;
					this.details.year.check = false;
				} else {
					this.details.year.check = false;
					this.changeDisabled('year');
					this.details.year.check = true;
				}

				this.details.year.content = wine.year;
				
				this.details.current_images.content = wine.images;

				let wineAssessment = wine.assessment

				if (wineAssessment) {
					delete wineAssessment.id
					delete wineAssessment.wine_id
					delete wineAssessment.created_at
					delete wineAssessment.updated_at
	
					Object.entries(wineAssessment).forEach((assessment) => {
						let category = assessment[0].split('_')[0]
						let key = assessment[0].split('_')[1]
	
						this.details.assessment[category][key] = assessment[1]
					})
				}

				if (wine.webshops.length > 0) {
					this.details.shops.content = [];
					$(wine.webshops).each(function(i, webshop) {
						_this.details.shops.content.push({url: webshop.url});
					});
				} else {
					this.details.shops.content = [{url: ''}];
				}
				
				this.toggleAllowAddingImages();
			},
			closeEditOpen: function(){
				this.parentCloseEditOpen();
			},
			addGrapesToArray: function(value) {
				if( !this.wine ) {
					this.grapeFocus = true;
				}

				if(this.grapeFocus) {
					this.details.grapes.content = value;
				}

				this.removeError(this.details.grapes);
				this.grapeFocus = true;
			},
			createCountry: function() {
				let _this = this;

				axios.post('/api/countries', {
					'name': this.details.country.new.content
				})
				.then((response) => {
					if (response.data.data) {
						_this.getCountries();
						_this.removeNew('country');
						_this.details.country.content = response.data.data;
						_this.checkRegionsVineyards('country');
					}
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			createRegion: function() {
				let _this = this;

				axios.post('/api/regions', {
					'name': this.details.region.new.content,
					'country_id': this.details.country.content.id
				})
				.then((response) => {
					if (response.data.data) {
						let country = this.details.country.content;

						_this.getCountries();
						_this.removeNew('country');
						_this.removeNew('region');
						_this.details.country.content = country;
						_this.details.region.content = response.data.data;
						_this.checkRegionsVineyards('region');
					}
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			createVineyard: function() {
				let _this = this;

				axios.post('/api/vineyards', {
					'name': this.details.vineyard.new.content,
					'region_id': this.details.region.content.id
				})
				.then((response) => {
					if (response.data.data) {
						let country = this.details.country.content;
						let region = this.details.region.content;

						_this.getCountries();
						_this.removeNew('country');
						_this.removeNew('region');
						_this.removeNew('vineyard');
						_this.details.country.content = country;
						_this.details.region.content = region;
						_this.details.vineyard.content = response.data.data;
					}
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			createGrape: function() {
				let _this = this;

				axios.post('/api/grapes', {
					'name': this.details.grapes.new.content,
				})
				.then((response) => {
					if (response.data.data) {
						_this.details.grapes.new.content = '';
						_this.details.grapes.new.check = false;
						_this.search.grapes.data.push(response.data.data);
						_this.details.grapes.content.push(response.data.data);
					}
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			createNuance: function(type) {
				let _this = this;
				let nuance = { name: this.details[type].new.content };

				axios.post('/api/nuances', nuance)
					.then((response) => {
						if (response.data.data) {
							_this.details[type].new.content = '';
							_this.details[type].new.check = false;
							_this.search[type].data.push(response.data.data);
							_this.details[type].content.push(response.data.data);
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			checkIfWineExists: function() {
				if (this.details.name.content != '') {
					axios.post('/api/check/wine', {
						name: this.details.name.content
					}).then((response) => {
						if (response.data.data.length >= 1) {
							this.wine_exists = true;
							// this.button_disabled = true;
						} else {
							this.wine_exists = false;
							// this.button_disabled = false;
						}
					});
				}
			},
			toggleAllowAddingImages: function() {
				this.allow_adding_images = (this.details.current_images.content.length + this.details.images.content.length) < this.max_images;
			},
			pixelsRestriction({minWidth, minHeight, maxWidth, maxHeight, imageWidth, imageHeight}) {
				return {
					minWidth: this.cropper.minwidth,
					minHeight: this.cropper.minheight,
					maxWidth: maxWidth,
					maxHeight: maxHeight,
				};
			},
			handlers: (map, vm) => ({
				// ...map, 8: e => {
				// 	e.preventDefault();
				// },
			}),
		},

		computed: {
			wineType() {
				let type = null
				
				if (this.details.type.content != '') {
					if (this.details.type.content.id == 1) {
						type = 'red'
					} else if (this.details.type.content.id == 2) {
						type = 'white'
					} else if (this.details.type.content.id == 3) {
						type = 'rose'
					}
				}

				return type
			}
		}
    }
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';
</style>