<template>
	<div>
		<div class="row">
			<div class="col-12 col-sm-6 margin-tb">
				<h1>Rediger rolle</h1>
			</div>
			<div class="col-12 col-sm-6 text-right">
				<span class="button btn-faded" @click="closeEdit">Tilbage</span>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-12">
				<div class="form-group">
					<strong>Name:</strong>
					<input type="text" placeholder="Navn" v-model="role.name" class="form-control">
				</div>
			</div>
			<div class="col-xs-12 col-sm-12 col-md-12">
				<div class="form-group">
					<strong>Permission:</strong>
					<div v-for="permission in all_permissions" :key="permission.id">
						<label :for="'check_' + permission.id">
							<input
								:id="'check_' + permission.id" 
								:name="'check_' + permission.id" 
								:value="permission" 
								:checked="inArray(permission.id, role.permissions)"
								class="checkedbox name" 
								type="checkbox" 
								v-model="role.permissions"
							>
							<span class="check"><i class="far fa-check"></i></span>
							<span class="label">{{ permission.description }}</span>
						</label>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-sm-12 col-md-12 text-right">
				<span class="button btn-faded" @click="closeEdit">Tilbage</span>
				<button type="submit" @click="updateRole" class="button btn-primary icon">
					Opdater rolle
					<i class="far fa-plus"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: [
			'role',
			'parentCloseThis',
			'all_permissions',
		],
		data() {
			return {}
		},
        mounted() {
			// console.log(this.role)
		},
		methods: {
			updateRole: function() {
				let role = new FormData();

				role.append('name', this.role.name);
				role.append('permission', JSON.stringify(this.pluck(this.role.permissions, 'id')));
				role.append('_method', 'PATCH');
				
				axios.post('/api/roles/' + this.role.id, role)
					.then((response) => {
						this.$emit('updated');
						this.closeEdit();
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			closeEdit: function(){
				this.parentCloseThis();
			},
			inArray: function(needle, haystack) {
				var length = haystack.length;
				for(var i = 0; i < length; i++) {
					if(haystack[i].id == needle) return true;
				}
				return false;
			},
			pluck(array, key) {
				return array.map(o => o[key]);
			},
		}
    }
</script>