<template>
	<div>
		<private-advanced-search v-if="!acourse && !adrink" @search="searchWines" :filter_data="filter_data"></private-advanced-search>

		<div class="row px-3 px-sm-5">
			<div class="row py-3 mt-3 mt-sm-5">
				<div class="col-12 col-sm-6 col-md-4 mb-4" v-for="wine in wine_menu_wines" :key="wine.id">
					<div class="wine__item" @click="openWine(wine)">
						<div class="wine__item--prices" v-if="atype === 'wine'">
							<div class="wine__item--prices__single">
								Antal: {{ wine.bottle_amount != null ? wine.bottle_amount : 1 }}
							</div>
						</div>
						<div class="wine__item--prices" v-if="atype === 'drinks'">
							<div class="wine__item--prices__single">
								<i class="fas fa-glass-martini-alt mr-1"></i>
								{{ wine.glass_price | numberformat }},-
							</div>
							<div class="wine__item--prices__single">
								<i class="far fa-coffee-pot"></i>
								{{ wine.pitcher_price | numberformat }},-
							</div>
						</div>
						
						<div class="wine__item--content">
							<div class="wine__item--content__number" v-if="atype === 'wine'">#{{ wine.position }}</div>
							<div v-if="atype != 'courses'" class="wine__item--content__image mb-2">
								<img v-if="wine.images && wine.images.length > 0" :src="'/storage' + wine.images[0].path" alt="">
								<img v-else src="/images/placeholder_etiqette.jpg" alt="">
							</div>
							<div v-else class="wine__item--content__image mb-2">
								<img src="https://app.vivinobar.com/wine_course-images/wine-course-placeholder.jpg" alt="">
							</div>
							
							<h2 class="wine__item--content__title" v-if="atype !== 'courses'">{{ wine.name }}</h2>
							<div class="wine__item--content__description" v-if="atype === 'wine'"><span v-if="wine.vineyard.region.name != 'Ukendt'">{{ wine.vineyard.region.name }}, </span>{{ wine.vineyard.region.country.name }}</div>
							<div class="wine__item--content__description" v-if="atype === 'drinks'">{{ wine.type.name }}</div>
						</div>
					</div>
				</div>

				<div class="col-12 d-flex justify-content-center align-items-center">
					<scroll-loader :loader-method="infiniteScroll" :loader-disable="disable" :loader-size="55" :loader-distance="200"></scroll-loader>
				</div>
			</div>
		</div>

		<private-single-wine :parentOpenSendLink="openSendLink" :active="single.active" :wine="single.wine" :parentCloseThis="parentCloseThis" :offer="this.archivetype == 'offers'"></private-single-wine>
		<single-course :active="single.courseactive" :course="single.course" :parentCloseThis="cParentCloseThis" :openParentWine="openWineParent"></single-course>

		<private-navigation :active="false" :wine_bar="this.wine_bar"></private-navigation>
		<terms></terms>
		<send-link :parentCloseSendLink="closeSendLink" :wine="single.wine" :active="send_active" :wine_bar="wine_bar"></send-link>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.atype = this.archivetype;

			if(this.archivetype == 'courses'){
				this.acourse = true;
			} else if(this.archivetype == 'drinks'){
				this.adrink = true;
			} else {
				this.awine = true;
			}
		},
		data() {
			return {
				atype: 'wine',
				wine_menu_wines: [],
				disable: false,
				current_page: 1,
				pageSize: 1000,
				single: {
					active: false,
					courseactive: false,
					drinkactive: false,
					wine: {},
					course: {},
				},
				awine: false,
				acourse: false,
				adrink: false,
				send_active: false,
				search_endpoint: '/api/search/wine-menu-wines',
				current_search_page: 1,
				is_searching: false,
				search_filters: null,
				filter_data: {
					types: [],
					grapes: [],
				},
				countries: [],
			}
		},
		props: {
			winemenu: {
				type: Object,
			},
			wine_bar: {
				type: Object,
			},
			archivetype: {
				type: String,
			}
		},
		created() {
			this.getCountries();
		},
		methods: {
			getCountries: function() {
				axios.get('/api/countries?limit=10000')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.countries = response.data.data;
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			populateFilter: function() {
				let filter_data = {
					types: [],
					grapes: [],
					countries: [],
					regions: [],
				};

				this.wine_menu_wines.forEach((wine, i) => {
					filter_data.grapes = filter_data.grapes.concat(wine.grapes);
					filter_data.types.push(wine.type);
					filter_data.countries.push(wine.vineyard.region.country);
					filter_data.regions.push(wine.vineyard.region);
				});

				filter_data.grapes = this.uniqify(filter_data.grapes, 'id').sort(this.sortComparer);
				filter_data.types = this.uniqify(filter_data.types, 'id').sort(this.sortComparer);
				filter_data.countries = this.uniqify(filter_data.countries, 'id').sort(this.sortComparer);
				filter_data.regions = this.uniqify(filter_data.regions, 'id').sort(this.sortComparer);

				filter_data.grapes.unshift({
					id: 0,
					name: 'Alle druer',
				});

				filter_data.types.unshift({
					id: 0,
					name: 'Alle typer',
				});

				filter_data.countries.unshift({
					id: 0,
					name: 'Alle lande',
					regions: []
				});

				filter_data.regions.unshift({
					id: 0,
					name: 'Alle regioner',
				});

				this.$set(this.filter_data, 'grapes', filter_data.grapes);
				this.$set(this.filter_data, 'types', filter_data.types);
				this.$set(this.filter_data, 'countries', filter_data.countries);
				this.$set(this.filter_data, 'regions', filter_data.regions);
			},
			sortComparer: function(a, b) {
				return a.name.localeCompare(b.name);
			},
			uniqify: function(array, property) {
				const result = [];
				const map = new Map();
				for (const item of array) {
					if(!map.has(item[property])){
						map.set(item[property], true);
						result.push({
							id: item.id,
							name: item.name
						});
					}
				}

				return result;
			},
			getWineMenuWines() {
				var apiget = '';

				switch(this.archivetype){
					case 'offers':
						apiget = `winemenu-wines/${this.winemenu.id}?page=${this.current_page}&limit=${this.pageSize}`;
						break;
					case 'drinks':
						apiget = `drinkmenu-drinks/${this.winemenu.id}?page=${this.current_page}&limit=${this.pageSize}`;
						break;
					case 'courses':
						apiget = `active-winecourses/${this.wine_bar.id}?page=${this.current_page}&limit=${this.pageSize}`;
						break;
					default:
						apiget = `winemenu-wines/${this.winemenu.id}?page=${this.current_page}&limit=${this.pageSize}`;
						break;
				}

				axios.get('/api/' + apiget)
					.then((response) => {
						if (response.data.data.length > 0) {
							this.wine_menu_wines = this.wine_menu_wines.concat(response.data.data);
							// console.log(this.wine_menu_wines);
						}

						this.current_page = this.current_page + 1;
						this.disable = response.data.data.length < this.pageSize;

						this.populateFilter();
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			openWine(wine) {
				if(this.archivetype != 'drinks'){
					jQuery('body').addClass('overflow');

					if(this.archivetype == 'courses'){
						this.single.courseactive = true;
						this.single.course = wine;
						this.single.course.wines = [];

						axios.get('/api/winecourse-wines/' + this.single.course.id)
						.then((response) => {
							if (response.data.data.length > 0) {
								this.single.course.wines = response.data.data;
							}
						})
						.catch((error) => {
							console.warn(error);
						});
					} else if(this.archivetype == 'wine' || this.archivetype == 'offers'){
						this.single.active = true;
						this.single.wine = wine;
					}
				}
			},
			openWineParent: function(wine) {
				this.single.active = true;
				this.single.wine = wine;
			},
			toggleMenuOpen() {
				$('body').toggleClass('open--menu');
			},
			parentCloseThis: function() {
				this.single.active = false;
				jQuery('body').removeClass('overflow');
			},
			cParentCloseThis: function() {
				this.single.courseactive = false;
				jQuery('body').removeClass('overflow');
			},
			openSendLink: function() {
				this.send_active = true;
			},
			closeSendLink: function() {
				this.send_active = false;
			},
			searchWines: function(filters) {
				let is_scrolling = (filters == null);

				if (!is_scrolling) {
					this.current_search_page = 1;
					this.search_filters = filters;

					if (this.search_filters.is_default) {
						this.is_searching = false;
					}

					filters['offer'] = (this.archivetype == 'offers') ? true : false;
					// filters[''] = (this.archivetype == 'offers') ? true : false;
				} else {
					if (this.current_search_page == 1) {
						this.current_search_page = 2;
					}
				}
				
				let _this = this;
				let url = `${this.search_endpoint}?page=${this.current_search_page}&limit=${this.pageSize}`;

				axios.post(url, {
					filters: this.search_filters,
					menu: this.winemenu.id
				})
				.then((response) => {
					if (response.data.data.length > 0) {
						if (filters != null) {
							_this.wine_menu_wines = response.data.data;
						} else {
							_this.wine_menu_wines = _this.wine_menu_wines.concat(response.data.data);
						}
					} else {
						_this.wine_menu_wines = [];
					}

					_this.is_searching = true;
					
					_this.disable = response.data.meta.current_page >= response.data.meta.last_page;

					if (is_scrolling) {
						_this.current_search_page = _this.current_search_page + 1;
					} else {
						_this.current_search_page = 1;
					}

					this.populateFilter();
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			infiniteScroll: function() {
				if (this.is_searching) {
					this.searchWines();
				} else {
					this.getWineMenuWines();
				}
			},
		}
	}
</script>

<style lang="scss">
	@import '../../../../sass/utils/_variables.scss';
	@import '../../../../sass/utils/_placeholders.scss';

	.wine {
		&__item {
			overflow: hidden;
			border-radius: 5px;
			background-color: $white-color;
			box-shadow: 0 0 10px rgba($black-color, .1);

			&--prices {
				height: 60px;
				display: flex;
				line-height: 60px;
				color: $white-color;
				justify-content: center;
				background-color: $darkestgray-color;

				&__single {
					padding: 0 8px;
					font-size: 20px;
					font-weight: 600;
				}
			}

			&--content {
				padding: 15px;
				position: relative;
				text-align: center;

				&__title {
					font-size: 16px;
					font-weight: 600;
				}
				
				&__image {
					min-height: 160px;

					img {
						width: auto;
						height: auto;
						max-width: 100%;
						max-height: 160px;
					}
				}
				
				&__description {
					width: 100%;
					font-size: 14px;
					overflow: hidden;
					font-weight: 400;
					white-space: nowrap;
					color: $primary-color;
					text-overflow: ellipsis;
				}
				
				&__number {
					top: 15px;
					left: 25px;
					font-weight: 400;
					position: absolute;
					color: $primary-color;
				}
			}
		}
	}

	.rotate--45 {
		transform-origin: center;
		transform: rotate(-45deg);
	}

	circle.loader__circle {
		stroke: $primary-color !important;
	}
</style>