<template>
	<div class="container-fluid">
        <div class="row">
			<div class="col-12">
				<h1>Rediger bruger</h1>

				<div class="close_btn dark small_move" @click="close()">
					<i class="fal fa-times"></i>
				</div>
			</div>
		</div>

		<form @submit.prevent="updateUser()">
            <div class="form-group">
                <label for="name">Navn</label>
                <input type="text" id="name" class="form-control" v-model="form.name" data-lpignore="true">
            </div>

            <div class="form-group">
                <label for="email">E-mail</label>
                <input type="text" id="email" class="form-control" v-model="form.email" data-lpignore="true">
            </div>

            <div class="form-group">
                <label for="wine_cellar_name">Vinkælderens navn</label>
                <input type="text" id="wine_cellar_name" class="form-control" v-model="form.wine_cellar.name" data-lpignore="true">
            </div>
            
            <div class="form-group">
                <label for="wine_cellar_slug">URL til vinkælder</label>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <input type="text" id="wine_cellar_slug" class="form-control" v-model="form.wine_cellar.slug" data-lpignore="true">
                    </div>
                    <div class="col-12 col-md-6">
                        {{ url }}
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="password">Nyt kodeord</label>
                <input type="password" id="password" class="form-control" v-model="form.password" data-lpignore="true">
            </div>

            <div class="form-group">
                <label for="password_confirmation">Gentag nyt kodeord</label>
                <input type="password" id="password_confirmation" class="form-control" v-model="form.password_confirmation" data-lpignore="true">
            </div>

            <div class="form-group d-flex">
                <button class="mr-2" type="submit">Opdater</button>
                <!-- <a class="button btn-link text-danger">
                    <small>Slet bruger</small>
                </a> -->
            </div>
        </form>
    </div>
</template>

<script>
	export default {
        props: [
            'user'
        ],

		data() {
			return {
                form: {
                    id: this.user.id,
                    name: this.user.name,
                    email: this.user.email,
                    wine_cellar: {
                        name: this.user.wine_bar.name,
                        slug: this.user.wine_bar.slug,
                    },
                    password: null,
                    password_confirmation: null,
                },
            }
		},
        
		methods: {
            updateUser() {
                axios.patch(`/api/users/${this.user.id}`, this.form).then((res) => {
                    console.log(res)

                    this.$emit('updated')
                    this.close()
                })
            },
            close() {
                this.$emit('close')
            }
        },

        computed: {
            url: function () {
                return `${Laravel.base_url}/${this.form.wine_cellar.slug}/welcome`
            }
        }
	}
</script>
