<template>
	<div>
		<div class="row">
			<div class="col-12 col-sm-6 margin-tb">
				<h1>Rolle</h1>
			</div>
			<div class="col-12 col-sm-6 text-right">
				<span class="button btn-faded" @click="closeEdit">Tilbage</span>
			</div>
		</div>
		
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-12">
				<div class="form-group">
					<strong>Navn:</strong>
					{{ this.role.name }}
				</div>
			</div>
			<div class="col-xs-12 col-sm-12 col-md-12">
				<div class="form-group">
					<div class="mb-3">
						<strong>Tiladelser:</strong>
					</div>
					<div class="roles__permission--wrap">
						<ul class="checklist">
							<li v-for="permission in this.role.permissions" :key="permission.id">
								<i class="far fa-plus"></i>
								{{ permission.description }}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: [
			'role',
			'parentCloseThis'
		],
		data() {
			return {
				
			}
		},
        mounted() {
		},
		methods: {
			closeEdit: function(){
				this.parentCloseThis();
			}
		}
    }
</script>