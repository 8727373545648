<template>
	<div>
		<div class="row" v-if="!drink">
			<div class="col-12">
				<h1>{{ this.headline }}</h1>
			</div>
			<div class="col-12 col-xl-8 mb-5">
				<div class="row">
					<div class="col-12 col-sm-5 col-md-3">
						<small class="d-block mb-2">Søg</small>
						<input type="text" @input="searchFunction" v-model="search_field">
					</div>
					<div class="col-12 col-sm-5 offset-sm-2 col-md-3 offset-md-6 col-lg-2 offset-lg-7">
						<small class="d-block mb-2 text-right">Visninger pr. side:</small>
						<select class="pr_page" v-model="pr_page" @change="set_per_page">
							<option value="5">5</option>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</select>
					</div>
				</div>
				<div class="row my-row head-row">
					<div class="col-2 col-md-1">Nr.</div>
					<div class="d-none d-md-flex col-5 col-md-7">Navn</div>
					<div class="col-2 col-md-1">
						<span class="d-none d-sm-block">Pris&nbsp;pr. glas</span>
						<i class="d-block d-sm-none fas fa-drink-glass-alt"></i>
					</div>
					<div class="col-2 col-md-1 center-row d-none d-md-flex drinkmenu__price">Redigér priser</div>
					<div class="col-2 col-md-1 center-row d-none d-md-flex drinkmenu__price">Redigér drinkskort</div>
					<div class="col-2 col-md-1 center-row d-none d-md-flex drinkmenu__price">Fjern</div>
				</div>

				<div>
					<div class="row my-row body-row" v-for="drink in drink_menu_drinks.data" :key="drink.id">
						<div class="col-2 col-md-1">#{{ drink.position }}</div>
						<div class="d-none d-md-block col-5 col-md-7 text-ellipsis">{{ drink.name }}</div>
						<div class="col-2 col-md-1 drinkmenu__price">
							<div v-if="drink.glass_price != null">{{ drink.glass_price | numberformat }},-</div>
							<div v-else>-</div>
						</div>
						<div class="col-2 col-md-1 drinkmenu__price text-center">
							<i v-if="hasPermission('drink-menu-edit-drink')" class="far fa-money-check-edit-alt interact" @click="editDrinkOnDrinkMenu(drink)"></i>
						</div>
						<div class="col-2 col-md-1 drinkmenu__price text-center">
							<i v-if="hasPermission('drink-edit')" class="far fa-edit interact" @click="editDrink(drink)"></i>
						</div>
						<div class="col-2 col-md-1 drinkmenu__price text-center">
							<i v-if="hasPermission('drink-menu-remove-drink')" class="far fa-minus-square interact" @click="removeDrinkFromDrinkMenu(drink)"></i>
						</div>
					</div>
				</div>

				<pagination class="mt-3" :data="drink_menu_drinks" :limit="limit" :show-disabled="true" @pagination-change-page="getDrinkMenuDrinks" v-if="drink_menu_drinks.data && (drink_menu_drinks.data.length > 0)"></pagination>
			</div>

			<div v-if="hasPermission('drink-add')" class="col-12 col-xl-4">
				<div class="dark-bg">
					<div class="row">
						<div class="col-12">
							<label for="type">Type</label>
							<v-select label="name" :options="types.data" v-model="search.type"></v-select>
						</div>
						<div class="col-6">
							<label for="">Søg</label>
							<input type="text" placeholder="Søg efter drink" class="input" v-model="search.text">
						</div>
						<div class="col-6 text-right">
							<label for="">&nbsp;</label>
							<div>
								<button @click="searchDrinks">Filtrér</button>
							</div>
						</div>
					</div>

					<div class="row">
						<nav class="w-100">
							<div class="nav nav-tabs" id="nav-tab" role="tablist">
								<a class="tab active" id="nav-my-drinkdatabase-tab" data-toggle="tab" href="#nav-my-drinkdatabase" role="tab" aria-controls="nav-my-drinkdatabase" aria-selected="true">Min drinksdatabase</a>
								<a class="tab" id="nav-global-drinkdatabase-tab" data-toggle="tab" href="#nav-global-drinkdatabase" role="tab" aria-controls="nav-global-drinkdatabase" aria-selected="false">Global drinksdatabase</a>
							</div>
						</nav>
					</div>

					<div class="tab-content pt-2" id="nav-tabContent">
						<div class="tab-pane fade show active" id="nav-my-drinkdatabase" role="tabpanel" aria-labelledby="nav-my-drinkdatabase-tab">
							<div class="row my-row body-row" v-for="(drink, d) in wine_bar_drinks.data" :key="d">
								<div class="col-11">{{ drink.name }}</div>
								<div class="col-1">
									<i class="far fa-plus-square interact" @click="showModalForAddingDrinkToDrinkMenu(drink)"></i>
								</div>
							</div>

							<pagination class="mt-3" :data="wine_bar_drinks" :limit="limit" :show-disabled="true" @pagination-change-page="getWineBarDrinks" v-if="wine_bar_drinks.data && (wine_bar_drinks.data.length > 0)"></pagination>
						</div>
						
						<div class="tab-pane fade" id="nav-global-drinkdatabase" role="tabpanel" aria-labelledby="nav-global-drinkdatabase-tab">
							<div class="row my-row body-row" v-for="(drink, d) in global_drinks.data" :key="d">
								<div class="col-11">{{ drink.name }}</div>
								<div class="col-1">
									<i v-if="allow_drink_creation" class="far fa-plus-square interact" @click="addDrinkToWineBar(drink)"></i>
								</div>
							</div>
							<pagination class="mt-3" :data="global_drinks" :limit="limit" :show-disabled="true" @pagination-change-page="getGlobalDrinks" v-if="global_drinks.data && (global_drinks.data.length > 0)"></pagination>
						</div>
					</div>
				</div>
			</div>

			<transition name="fade">
				<div id="modal" class="modal" v-if="showmodal == true">
					<div class="modal__background" @click="hideModalForAddingDrinkToDrinkMenu"></div>
					<div class="modal__wrapper">
						<span @click="hideModalForAddingDrinkToDrinkMenu" class="modal__close--button"><i class="far fa-times"></i></span>
						<strong class="modal__headline">{{ modal.drink }}</strong>
						<span id="modal-drink-menu"></span>
						<form id="modal-drink-form" v-on:submit.prevent="addDrinkToDrinkMenu">
							<div class="input__wrapper">
								<label for="modal-drink-price-glass">Pris pr. glas</label>
								<div class="append">kr.</div>
								<input type="number" id="modal-drink-price-glass" class="input" v-model="modal.price.glass">
							</div>

							<div v-if="!this.drinkmenu.is_offer_menu">
								<label for="modal-drink-position">Placering</label>
								<v-select id="modal-drink-position" :options="this.available_positions" v-model="modal.position"></v-select>
							</div>

							<input type="hidden" id="modal-drink-id" v-model="modal.drink_id">
							<input type="submit" class="button primary w-100" :value="modal.buttontext">
						</form>
					</div>
				</div>
			</transition>
		</div>

		<div class="row" v-if="drink">
			<winebar-create-drink :title="this.title" :action="this.action" :drink="this.drink" :parentCloseEditOpen="closeEditOpen" :wine_bar="this.wine_bar"></winebar-create-drink>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.getDrinkMenuDrinks();
			this.getWineBarDrinks();
			this.getGlobalDrinks();
			this.getTypes();
			this.getAvailablePositions();

			var ww = $(window).width();

			if(ww < 768){
				this.limit = -1;
			}
		},
		created() {},
		data() {
			return {
				limit: 1,
				drink_menu_drinks: [],
				wine_bar_drinks: [],
				global_drinks: [],
				types: [],
				available_positions: [''],
				current_page: 1,
				pr_page: 10,
				showmodal: false,
				modal: {
					drink: '',
					price: {
						glass: null,
						pitcher: null,
						offer_price: null,
						pitcher_amount: null,
					},
					position: null,
					buttontext: 'Tilføj til drinkskort',
				},
				search: {
					type: '',
					text: '',
				},
				drink: null,
				action: null,
				title: '',
				search_field: '',
			}
		},
		props: {
			drinkmenu: {
				type: Object,
			},
			headline: {
				type: String,
			},
			permissions: {
				type: Array,
			},
			allow_drink_creation: {
				type: Boolean,
			},
			wine_bar: {
				type: Object,
			}
		},
		methods: {
			set_per_page: function(page = 1) {
				this.getWineMenuWines(page);
				this.wineBarWinePaginator(page);
				this.globalWinePaginator(page);
			},
			wineBarDrinkPaginator: function(page = 1) {
				if (this.is_searching) {
					this.searchDrinks(page);
				} else {
					this.getWineBarDrinks(page);
				}
			},
			globalDrinkPaginator: function(page = 1) {
				if (this.is_searching) {
					this.searchDrinks(page);
				} else {
					this.getGlobalDrinks(page);
				}
			},
			searchDrinks: function(page = 1) {
				let filters = {
					type: null,
					text: null,
				};

				filters.type = (this.search.type != '') ? this.search.type.id : null;
				filters.text = (this.search.text != '') ? this.search.text : null;

				if ($('#nav-my-drinkdatabase-tab').attr('aria-selected') == "true") {
					this.searchWineBarDrinks(page, filters);
				} else if ($('#nav-global-drinkdatabase-tab').attr('aria-selected') == "true") {
					this.searchGlobalDrinks(page, filters);
				}

				this.is_searching = true;
			},
			searchWineBarDrinks: function(page = 1, filters) {
				axios.post('/api/search/winebar-drinks?page=' + page + '&limit=' + this.pr_page, {
					wine_bar_id: this.drinkmenu.wine_bar_id,
					drink_menu_id: this.drinkmenu.id,
					filters: filters,
				})
				.then((response) => {
					console.log(response);
					this.wine_bar_drinks = response.data;
					console.log(this.wine_bar_drinks);
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			searchGlobalDrinks: function(page = 1, filters) {
				axios.post('/api/search/global-drinks?page=' + page + '&limit=' + this.pr_page, {
					wine_bar_id: this.drinkmenu.wine_bar_id,
					filters: filters,
				})
				.then((response) => {
					this.global_drinks = response.data;
					console.log(this.global_drinks);
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			getTypes() {
				axios.get('/api/drink-types')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.types = response.data;

							this.types.data.unshift({
								id: null,
								name: 'Ingen valgt'
							});
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getAvailablePositions() {
				axios.get('/api/drink-positions/' + this.drinkmenu.id)
					.then((response) => {
						this.available_positions = $.map(response.data, function(value, index){
							return [value];
						});
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getDrinkMenuDrinks(page = 1) {
				this.current_page = page;

				axios.get('/api/drinkmenu-drinks/' + this.drinkmenu.id + '?page=' + page)
					.then((response) => {
						if (page <= 1) {
							 this.drink_menu_drinks = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.drink_menu_drinks = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getDrinkMenuDrinks(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			showModalForAddingDrinkToDrinkMenu(drink) {
				$('#modal-drink-menu').text(drink.name);
				this.modal.drink_id = drink.id;
				this.resetPositionSelect();
				this.showmodal = true;
				this.modal.drink = drink.name;
				this.modal.price.glass = drink.glass_price;
				this.modal.price.pitcher = drink.pitcher_price;
				this.modal.price.offer_price = drink.offer_price;
				this.modal.price.pitcher_amount = drink.pitcher_amount;
				this.modal.position = drink.position;
				this.modal.buttontext = 'Tilføj til drinkskort';
			},
			hideModalForAddingDrinkToDrinkMenu() {
				var split = this.available_positions.indexOf(this.modal.position);
				this.available_positions.splice(split, 1);

				this.showmodal = false;
				this.modal.drink = '';
				this.modal.price.glass = null;
				this.modal.price.pitcher = null;
				this.modal.price.offer_price = null;
				this.modal.price.pitcher_amount = null;
				this.modal.position = null;
			},
			resetPositionSelect() {
				$('#default-option').prop('selected', true);
				$('option.remove-me').remove();
			},
			addDrinkToDrinkMenu(submitEvent) {
				let glassPrice = this.modal.price.glass;
				let drink_id = this.modal.drink_id;
				let glassPriceNew = this.modal.price.offer_price ? this.modal.price.offer_price : null;
				let position = this.modal.position ? this.modal.position : null;
				let pitcherPrice = this.modal.price.pitcher ? this.modal.price.pitcher : null;

				axios.post('/api/drinkmenu-drinks', {
						'pitcher_price': pitcherPrice,
						'glass_price': glassPrice,
						'offer_price': glassPriceNew,
						'position': position,
						'drink_id': drink_id,
						'drink_menu_id': this.drinkmenu.id
					}).then((response) => {})
					.catch((error) => {
						console.warn(error);
					}).then(() => {
						this.hideModalForAddingDrinkToDrinkMenu();
						this.getDrinkMenuDrinks();
						this.getWineBarDrinks();
						this.getAvailablePositions();
					});
			},
			removeDrinkFromDrinkMenu(drink) {
				axios.delete('/api/drinkmenu-drinks/' + this.drinkmenu.id + '/' + drink.id)
					.then((response) => {
						this.getAvailablePositions();
					})
					.catch((error) => {
						console.warn(error);
					}).then(() => {
						this.getDrinkMenuDrinks();
						this.getWineBarDrinks();
					});
			},
			editDrinkOnDrinkMenu(drink) {
				$('#modal-drink-menu').text(drink.name);
				this.modal.drink_id = drink.id;

				$('option.remove-me').remove();
				$('select#modal-drink-position').prepend('<option class="remove-me" value="' + drink.position + '">' + drink.position + '</option>');
				$('select#modal-drink-position').find('option[value="' + drink.position + '"]').prop('selected', true);

				this.available_positions.push(drink.position);
				this.available_positions.sort(function(a, b){return a-b});

				this.modal.drink = drink.name;
				this.modal.price.glass = drink.glass_price;
				this.modal.price.pitcher = drink.pitcher_price;
				this.modal.price.offer_price = drink.offer_price;
				this.modal.price.pitcher_amount = drink.pitcher_amount;
				this.modal.position = drink.position;
				this.modal.buttontext = 'Redigér drink';
				this.showmodal = true;
			},
			getWineBarDrinks(page = 1) {
				this.current_page = page;
				console.log('Kører getWineBarDrinks('+this.current_page+')');

				axios.get('/api/winebar-drinks/' + this.drinkmenu.wine_bar_id + '/' + this.drinkmenu.id + '?page=' + page + '&exclude=true')
					.then((response) => {
						console.log(response);
						if (page <= 1) {
							 this.wine_bar_drinks = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.wine_bar_drinks = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getWineBarDrinks(page);
								}
							}
						}

						console.log(this.wine_bar_drinks);
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getGlobalDrinks(page = 1) {
				this.current_page = page;
				
				axios.get('/api/global-drinks/' + this.drinkmenu.wine_bar_id + '?page=' + page)
					.then((response) => {
						if (page <= 1) {
							 this.global_drinks = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.global_drinks = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getGlobalDrinks(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			addDrinkToWineBar(drink) {
				this.drink = drink;
				this.drink['wine_bar_id'] = this.drinkmenu.wine_bar_id;
				this.action = 'add';
				this.title = 'Tilføj drink';
			},
			editDrink: function(drink) {
                this.drink = drink;
				this.action = 'edit';
				this.title = 'Rediger drink';
			},
			closeEditOpen: function(){
				this.drink = null;
			},
			hasPermission: function(permission) {
				return this.permissions.findIndex(x => x.name === permission) > -1;
			},
			searchFunction: function() {
				axios.post('/api/search/drinks', {
					drink_menu: this.drinkmenu.id,
					search: this.search_field
				}).then((res) => {
					console.log(res);
					this.drink_menu_drinks = res.data;
				}).catch((err) => {
					console.log(err);
				});
			}
		}
	}
</script>

<style lang="scss">
@import '../../../sass/utils/_variables.scss';

	.center-row {
		text-align: center;
		justify-content: center;
	}

	.head-row {
		> div {
			display: flex;
			line-height: 1.2;
			align-items: flex-end;
		}

		i {
			font-size: 16px;
		}
	}

	.drinkmenu {
		&__price {
			@media(max-width: $sm - 1px){
				padding: 0;
			}
		}
	}
</style>