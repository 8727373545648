<template>
	<div>
		<div v-if="actions.active">
			<div class="row">
				<div class="col-12 col-sm-6">
					<h1 class="mt-0">Rollestyring</h1>
				</div>
				<div class="col-12 col-sm-6 text-right">
					<span class="button primary" v-if="hasPermission('role-create')" @click="changeRole('create')">Opret ny rolle</span>
				</div>
			</div>

			<div class="row my-row head-row border-bottom mt-5">
				<div class="col-1">#</div>
				<div class="col-8">Navn</div>
				<div class="col-3 justify-content-end">Actions</div>
			</div>
			<div>
				<div class="row my-row my-1 roles__row" v-for="role in this.roles.data" :key="role.id">
					<div class="col col-1">{{ role.id }}</div>
					<div class="col col-8">{{ role.name }}</div>
					<div class="col-3 text-right roles__actions">
						<span class="d-inline-block button btn-link" @click="changeRole('show', role)">
							<i class="far fa-eye"></i>
						</span>
						<span class="ml-3 button btn-link d-inline-block" @click="changeRole('edit', role)" v-if="hasPermission('role-edit')">
							<i class="far fa-edit"></i>
						</span>
						<span class="ml-3 button btn-link d-inline-block" @click="deleteRole(role)" v-if="hasPermission('role-delete')">
							<i class="far fa-trash"></i>
						</span>
					</div>
				</div>
				<pagination class="mt-3" :data="roles" :show-disabled="true" :limit="limit" @pagination-change-page="getRoles" v-if="roles.data && (roles.data.length > 0)"></pagination>
			</div>
		</div>
		<roles-create v-if="hasPermission('role-create') && actions.create" :parentCloseThis="closeThis" :all_permissions="this.permissions" @created="this.getRoles"></roles-create>
		<roles-edit v-if="hasPermission('role-edit') && actions.edit" :role="singlerole" :parentCloseThis="closeThis" :all_permissions="this.all_permissions" @updated="this.getRoles"></roles-edit>
		<roles-show v-if="actions.show" :role="singlerole" :parentCloseThis="closeThis"></roles-show>
	</div>
</template>

<script>
	export default {
		props: [
			'user',
			'permissions',
			// 'all_permissions',
		],
		data() {
			return {
				singlerole: '',
				actions: {
					create: false,
					show: false,
					edit: false,
					active: true,
				},
				limit: 5,
				pr_page: 10,
				roles: [],
				all_permissions: [],
			}
		},
        mounted() {
			this.getRoles();
			this.getPermissions();
		},
		methods: {
			getRoles(page = 1) {
				this.current_page = page;

				axios.get('/api/roles' + '?page=' + page + '&limit=' + this.pr_page)
					.then((response) => {
						if (page <= 1) {
							 this.roles = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.roles = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getRoles(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getPermissions() {
				axios.get('/api/permissions')
					.then((response) => {
						this.all_permissions = response.data.data;
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			deleteRole: function(role) {
				if (confirm("Are you sure?")) {
                    axios.delete('/api/roles/' + role.id)
                        .then((response) => {
							this.getRoles(this.current_page);
						})
                        .catch(() => {
							console.warn(error);
						});
                }
			},
			hasPermission: function(permission) {
				return this.permissions.findIndex(x => x.name === permission) > -1;
			},
			changeRole: function(type, role){
				this.actions.active = false;
				this.actions[type] = true;
				this.singlerole = role;
			},
			closeThis: function(){
				this.actions.active = true;
				this.actions.create = false;
				this.actions.show = false;
				this.actions.edit = false;
				this.singlerole = '';
			},
		}
    }
</script>