<template>
	<div class="container-fluid">
		<div class="row" v-if="!drinkcreated">
			<div class="col-12">
				<h1 class="mb-3">{{ this.title }}</h1>

				<div v-if="drink" class="close_btn dark small_move" @click="closeEditOpen()">
					<i class="fal fa-times"></i>
				</div>
			</div>
		</div>

		<winecellar-flow-steps v-if="!drinkcreated" type="drink" :action="this.action" :steps="steps" :activestep="activeStep" :goToStepParent="goToStep" :isStepParent="isStep"></winecellar-flow-steps>

		<div class="row" v-if="isStep('active', 0)">
            <div class="col-12 col-md-6">
				<div class="input__wrapper" :class="{ 'error': details.name.error, 'required': details.name.required }">
					<label for="drink_name">Drinkens navn</label>
					<input type="text" class="input" name="drink_name" @focus="details.name.error = false" v-model="details.name.content" @input="checkIfDrinkExists">
					<div v-if="details.name.error" class="input__error">{{ details.name.errormsg }}</div>
					<div v-if="drink_exists" class="input__error">Der findes allerede en drink med dette navn</div>
				</div>
				<div class="input__wrapper d-none" :class="{ 'error': details.desc.error, 'required': details.name.required }">
					<label for="drink_desc">Beskrivelse</label>
					<textarea rows="9" class="input" name="drink_desc" @focus="details.desc.error = false" v-model="details.desc.content"></textarea>
					<div v-if="details.desc.error" class="input__error">{{ details.desc.errormsg }}</div>
				</div>

				<div class="input__wrapper" :class="{ 'error': details.type.error, 'required': details.name.required }">
					<label for="drink_type">Type</label>
					<v-select label="name" :options="search.types.data" @input="removeError(details.type)" v-model="details.type.content"></v-select>
					<div v-if="details.type.error" class="input__error">{{ details.type.errormsg }}</div>
				</div>
			</div>
            <div class="col-12 col-md-6">
				<div class="input__wrapper" :class="{ 'error': details.free_text.error }">
					<label for="drink_ingredients">Opskrift / Fremgangsmåde *</label>
					<textarea rows="6" class="input"  @input="removeError(details.free_text)" name="drink_ingredients" id="drink_ingredients" v-model="details.free_text.content"></textarea>
					<div v-if="details.free_text.error" class="input__error">{{ details.free_text.errormsg }}</div>
				</div>
				
				<div class="duallist mt-3" :class="{ 'multiple' : search.recipes.length > 6, 'dual' : search.recipes.length <= 6 }">
					<div class="duallist__item" v-for="(recipe, s) in search.recipes.data" :key="s">
						<div class="icon__button primary round" @click="addToContent(recipe)"><i class="far fa-plus"></i></div> {{ recipe.name }}
					</div>
				</div>
			</div>

			<div class="col-12 col-md-6 mb-3">
				<div class="input__wrapper" :class="{ 'error': details.ingredients.error }">
					<label for="drink_ingredients">Ingredienser</label>
					<v-select label="name" :options="search.ingredients.data" @input="removeError(details.ingredients)" :map-keydown="handlers" v-model="details.ingredients.content" multiple></v-select>
					<span class="fakelink" v-if="!details.ingredients.new.check" @click="addNew('ingredients')">Tilføj ny ingrediens</span>
				</div>
			</div>
            <div class="col-12 col-md-6 mb-3 pt-3">
				<div v-for="(ingredient, l) in details.ingredients.content" :key="l" class="multiple__item">
					{{ ingredient.name }}
					<span class="remove" @click="removeSingle('ingredients', l)">
						<i class="far fa-times"></i>
					</span>
				</div>
			</div>

            <div class="col-12 col-md-6">
				<div class="input__wrapper" v-if="details.ingredients.new.check">
					<label for="drink_new_ingredients">Tilføj ny ingrediens</label>
					<input type="text" class="input" name="drink_new_fragrance" v-model="details.ingredients.new.content">
					<button class="secondary align-self-end" @click="createIngredient">
						Tilføj ny ingrediens
						<i class="far fa-plus"></i>
					</button>
				</div>
			</div>

			<div class="col-12 justify-content-end d-flex">
				<button class="icon" @click="goToStep('next')" :disabled="this.button_disabled">
					Videre
					<i class="far fa-chevron-right"></i>
				</button>
			</div>
        </div>

		<div class="row" v-if="isStep('active', 1)">
			<image-uploader
				class="row"
				:current-images="details.current_images"
				:primary-image="details.images.primary"
				:max-images="3"
				@image-cropped="imageCropped"
				@image-deleted="deleteImage"
				@image-primary="setPrimaryImage"
			></image-uploader>

			<div class="col-12 col-md-6 justify-content-between justify-content-sm-end d-flex mt-3">
				<button class="btn-faded" @click="goToStep('prev')">Tilbage</button>
				<button v-if="this.action == null" @click="createDrink" class="primary">Opret drink</button>
				<button v-if="this.action == 'edit'" class="primary" @click="updateDrink">Opdater drink</button>
				<button v-if="this.action == 'accept'" class="primary icon" @click="acceptDrink(drink.id)">Accepter drink <i class="far fa-check"></i></button>
				<button v-if="this.action == 'add'" class="primary" @click="addDrinkToWineBar(drink.id)">Tilføj drink</button>
			</div>
		</div>

		<div v-if="this.loading" class="modal show fade" id="spinner-modal" tabindex="-1" role="dialog" aria-labelledby="spinner-modal" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered w-100 text-center" role="document">
				<div class="row h-100 d-flex align-items-center m-auto">
					<i class="far fa-spinner-third fa-spin fa-5x fa-fw" style="color:#A60000;"></i>
				</div>
			</div>
		</div>
		
		<winecellar-thankyou v-if="isStep('active', 2)" :title="thankyou.title" :description="thankyou.description" :icon="thankyou.icon" :primaryButton="thankyou.primaryButton"></winecellar-thankyou>
    </div>
</template>

<script>
    export default {
		props: {
            title: {
                type: String,
            },
            action: {
                type: String,
			},
			drink: {
				type: Object,
			},
			parentCloseEditOpen: {
				type: Function
			},
			wine_bar: {
				type: Object,
			}
        },
		data() {
			return {
				activeStep: 1,
				loading: false,
				drinkcreated: false,
				drink_exists: false,
				button_disabled: false,
				max_images: 3,
				allow_adding_images: true,
				cropper: {
					min_width: 150,
					min_height: 200
				},
				search: {
					types: {
						data: []
					},
					ingredients: {
						data: []
					},
					recipes: {
						data: []
					},
					fragrances: {
						data: []
					},
					tastes: {
						data: []
					},
				},
				details: {
					id: {
						content: null
					},
					name: {
						step: 1,
						required: true,
						content: '',
						error: false,
						errormsg: 'Fejl!',
					},
					desc: {
						step: 1,
						required: false,
						content: '',
						error: false,
						errormsg: 'Fejl!',
					},
					type: {
						step: 1,
						required: true,
						content: '',
						error: false,
						errormsg: 'Fejl!',
					},
					free_text: {
						step: 1,
						required: true,
						content: '',
						error: false,
						errormsg: 'Fejl!',
					},
					images: {
						step: 2,
						content: [],
						check: false,
						error: false,
						errormsg: 'Fejl!',
						primary: 0,
					},
					ingredients: {
						step: 3,
						required: true,
						error: false,
						errormsg: 'Fejl!',
						new: {
							check: false,
							content: ''
						},
						content: []
					},
					fragrances: {
						step: 4,
						required: false,
						error: false,
						errormsg: 'Fejl!',
						new: {
							check: false,
							content: ''
						},
						content: []
					},
					new_images: {
						content: []
					},
					current_images: {
						content: []
					},
					deleted_images: {
						content: []
					},
				},
				steps: [
					'Detaljer',
					'Billede(r)',
				],
				thankyou: {
					icon: '<svg height="318" viewBox="0 0 318.639 318.639" width="318" xmlns="http://www.w3.org/2000/svg"><g fill="#BFA271"><path d="m223.256 31.937-29.872-29.872a7.041 7.041 0 0 0 -9.959 0l-9.959 9.959a7.041 7.041 0 0 0 0 9.959l-33.74 33.74a56.29 56.29 0 0 0 -61.772 11.983l-69.7 69.7a28.168 28.168 0 0 0 0 39.831l39.831 39.831a28.167 28.167 0 0 0 39.831 0l69.7-69.7a56.289 56.289 0 0 0 11.984-61.777l33.74-33.74a7.041 7.041 0 0 0 9.959 0l9.959-9.959a7.04 7.04 0 0 0 0-9.954zm-144.382 154.342-39.831-39.831 53.706-53.706 39.831 39.831z" transform="matrix(.70710678 -.70710678 .70710678 .70710678 -.007 159.323)"/><path d="m64.167 80.268-23.689 23.689-8.7-8.7a1.081 1.081 0 0 0 -1.528 0l-2.55 2.543a1.081 1.081 0 0 0 0 1.528l12.015 12.015a1.081 1.081 0 0 0 1.528 0l27-27a1.081 1.081 0 0 0 0-1.528l-2.543-2.547a1.081 1.081 0 0 0 -1.533 0z" transform="translate(112.344 100.699)"/></g></svg>',
					title: 'Din drink er oprettet',
					description: '<p>Du kan nu se din drink på drinkkortet.</p><p>Har du endnu en drink du skal oprette, kan du gøre det via knappen herunder, ellers kan du tage direkte tilbage til kontrolpanelet.</p>',
					primaryButton: {
						link: '/drinks/create',
						text: 'Opret en ny drink',
					},
				}
			}
		},
        mounted() {
			this.getTypes();
			this.getRecipes();
			this.getNuances();
			this.getIngredients();
			
			if (this.action != null) {
				this.setContent(this.drink);
			}
		},
		methods: {
			imageCropped(path) {
				this.details.current_images.content.push({
					path: path
				})

				this.details.new_images.content.push({
					path: path
				})
			},
			setPrimaryImage(i) {
				this.details.images.primary = i
			},
			toggleLoading: function() {
				this.loading = this.loading ? false : true;

				if (this.loading) {
					$('body').append('<div id="modal-remove" class="modal-backdrop fade show"></div>');
				} else {
					$('#modal-remove').remove();
				}
			},
			addWebshop: function() {
				this.details.shops.content.push('');
			},
			removeSingle: function(type, l) {
				this.details[type].content.splice(l, 1);
			},
			addNewSingle: function(type) {
				var getNew = this.details[type].new.content;
				this.details[type].new.content = '';				
				this.details[type].content.push(getNew);
			},
			changeDisabled: function(type) {
				var disable_this = this.details[type];

				disable_this.error = false;

				if(!disable_this.check){
					disable_this.content = 'Ingen';
					disable_this.disabled = true;
				} else {
					disable_this.content = '';
					disable_this.disabled = false;
				}
			},
			goToStep: function(i){
				if(i == 'next'){
					var error = this.validateStep();

					if( !error ){
						this.activeStep = (this.activeStep + 1);
					}
				} else if(i == 'prev'){
					this.activeStep = (this.activeStep - 1);
				} else {
					if( i < this.activeStep ){
						this.activeStep = i;
					}
				}
			},
			validateStep: function(){
				var error = false;
				var activeStep = this.activeStep;

				jQuery.each(this.details, function(index, val){
					var required = val.required;

					if(required === true && val.step === activeStep){
						if(val.check !== undefined){
							if(val.check === false && val.content == ''){
								error = true;
								val.error = true;
							}
						} else if(val.content == '') {
							error = true;
							val.error = true;
						}
					}
				});

				return error;
			},
			isStep: function(type, k){
				var $return = false;

				if(type == 'active' && k == (this.activeStep - 1)){
					$return = true;
				} else if(type == 'done' && k < (this.activestep - 1)){
					$return = true;
				}

				return $return;
			},
			addNew: function(type) {
				switch(type){
					case 'country':
					case 'region':
						this.details[type].content = '';
						break;
				}

				this.details[type].new.check = true;
			},
			removeNew: function(type) {
				var $this = this.details[type];

				$this.new.check = false;
				$this.new.content = '';
				$this.error = false;
			},
			removeError: function(here) {
				here.error = false;
			},
			createDrink: function() {
				var error = this.validateStep();
				let _this = this;

				if( !error ) {
					this.toggleLoading();
					let drink = new FormData();

					drink.append('name', this.details.name.content);
					drink.append('description', this.details.desc.content);
					drink.append('free_text', this.details.free_text.content);
					drink.append('type_id', this.details.type.content.id);
					drink.append('ingredients', JSON.stringify(this.pluck(this.details.ingredients.content, 'id')));
					drink.append('wine_bar_id', this.wine_bar.id);
					drink.append('primary_image', this.details.images.primary);

					if (this.details.new_images.content.length > 0) {
						drink.append('images', JSON.stringify(this.details.new_images.content));
					}
					
					axios.post('/api/drinks', drink).then((response) => {
						console.log(response);
						this.toggleLoading();
						_this.drinkcreated = true;
						_this.goToStep('next');
					})
					.catch((error) => {
						console.warn(error);
					});
				}
			},
			updateDrink: function() {
				var error = this.validateStep();
				let _this = this;

				if( !error ) {
					let drink = new FormData();

					drink.append('_method', 'PATCH');

					drink.append('name', this.details.name.content);
					drink.append('description', this.details.desc.content);
					drink.append('free_text', this.details.free_text.content);
					drink.append('type_id', this.details.type.content.id);
					drink.append('ingredients', JSON.stringify(this.pluck(this.details.ingredients.content, 'id')));
					drink.append('primary_image', this.details.images.primary);

					if (this.details.new_images.content.length > 0) {
						drink.append('images', JSON.stringify(this.details.new_images.content));
					}

					if (this.details.deleted_images.content.length > 0) {
						drink.append('deleted_images', JSON.stringify(this.details.deleted_images.content));
					}
					
					axios.post('/api/drinks/' + this.details.id.content, drink)
						.then((response) => {
							_this.drinkcreated = true;
							_this.goToStep('next');
						})
						.catch((error) => {
							console.warn(error);
						});
				}
			},
			acceptDrink: function(drink_id) {
				var error = this.validateStep();
				let _this = this;

				if( !error ) {
					let drink = new FormData();

					drink.append('name', this.details.name.content);
					drink.append('description', this.details.desc.content);
					drink.append('free_text', this.details.free_text.content);
					drink.append('type_id', this.details.type.content.id);
					drink.append('ingredients', JSON.stringify(this.pluck(this.details.ingredients.content, 'id')));
					// drink.append('wine_bar_id', this.wine_bar.id);
					drink.append('primary_image', this.details.images.primary);

					if (this.details.images.content.length > 0) {
						drink.append('images', JSON.stringify(this.details.images.content));
					}

					axios.post('/api/drinks', drink)
						.then((response) => {
							_this.drinkcreated = true;
							_this.goToStep('next');

							axios.patch('/api/accepted-drinks/' + drink_id, {
								'is_declined': 0,
							}).then((response) => {})
							.catch((error) => {
								console.warn(error);
							});
						})
						.catch((error) => {
							console.warn(error);
						});
				}
			},
			addDrinkToWineBar: function() {
				var error = this.validateStep();
				let _this = this;

				if( !error ) {
					let drink = new FormData();

					drink.append('name', this.details.name.content);
					drink.append('description', this.details.desc.content);
					drink.append('free_text', this.details.free_text.content);
					drink.append('type_id', this.details.type.content.id);
					drink.append('ingredients', JSON.stringify(this.pluck(this.details.ingredients.content, 'id')));
					drink.append('wine_bar_id', this.wine_bar.id);
					drink.append('is_custom', 1);
					drink.append('is_declined', 1);
					drink.append('primary_image', this.details.images.primary);

					if (this.details.current_images.content.length > 0) {
						drink.append('current_images', JSON.stringify(this.details.current_images.content));
					}

					if (this.details.images.content.length > 0) {
						drink.append('images', JSON.stringify(this.details.images.content));
					}
					
					axios.post('/api/drinks', drink)
						.then((response) => {
							axios.post('/api/winebar-drinks', {
									drink_id: response.data.data.id,
									wine_bar_id: _this.drink.wine_bar_id,
								}).then((response) => {
									_this.drinkcreated = true;
									_this.goToStep('next');
								})
								.catch((error) => {
									console.warn(error);
								});
						})
						.catch((error) => {
							console.warn(error);
						});
				}
			},
			updateFreeText: function(value){
				var linked = '[' + value + ']';
			},
			addToContent: function(recipe){
				var myField = document.getElementById('drink_ingredients');
				var myValue = '[' + recipe.id + '|' + recipe.name + ']';

				var endPos = myField.selectionEnd

				//IE support
				if (document.selection) {
					myField.focus();
					sel = document.selection.createRange();
					sel.text = myValue;
				}
				//MOZILLA and others
				else if (myField.selectionStart || myField.selectionStart == '0') {
					var startPos = myField.selectionStart;
					myField.value = myField.value.substring(0, startPos)
						+ myValue
						+ myField.value.substring(endPos, myField.value.length);
				} else {
					myField.value += myValue;
				}

				myField.focus();
				myField.selectionEnd = endPos + myValue.length;

				this.details.free_text.content = myField.value;
				this.details.free_text.error = false;
			},
			getTypes() {
				axios.get('/api/drink-types')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.search.types = response.data;
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getRecipes() {
				axios.post('/api/recipes', {
					'wine_bar_id': this.wine_bar.id
				})
				.then((response) => {
					if (response.data.data.length > 0) {
						this.search.recipes = response.data;
					}
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			getNuances() {
				axios.get('/api/nuances?limit=10000')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.search.fragrances = response.data;
							this.search.tastes = response.data;
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getIngredients() {
				axios.get('/api/ingredients?limit=10000')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.search.ingredients = response.data;
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			setContent: function(drink) {
				let _this = this;

				this.details.id.content = drink.id;
				this.details.name.content = drink.name;
				this.details.desc.content = drink.description;
				this.details.free_text.content = drink.free_text;
				this.details.type.content = drink.type;
				this.details.ingredients.content = drink.ingredients;
				this.details.fragrances.content = drink.fragrances;
				this.details.current_images.content = drink.images;
				this.details.images.primary = drink.primary_image != null ? drink.primary_image : 0;

				this.toggleAllowAddingImages();
			},
			closeEditOpen: function() {
				this.parentCloseEditOpen();
			},
			addImage (e) {
				this.details.images.content.push(event.target.files[0]);
				this.toggleAllowAddingImages();
			},
			removeImage(index) {
				this.details.images.content.splice(index, 1);
				this.toggleAllowAddingImages();
			},
			deleteImage(image) {
				this.details.current_images.content.splice(this.details.current_images.content.findIndex(o => o.id === image.id), 1);
				this.details.deleted_images.content.push(image.id);
				this.toggleAllowAddingImages();
			},
			uploadImage(event) {
				// Reference to the DOM input element
				var input = event.target;
				// Ensure that you have a file before attempting to read it
				if (input.files && input.files[0]) {
						// create a new FileReader to read this image and convert to base64 format
						var reader = new FileReader();
						// Define a callback function to run, when FileReader finishes its job
						reader.onload = (e) => {
								// Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
								// Read image as base64 and set to imageData
								// this.details.images.content = e.target.result;

								let img = new Image();

								img.src = e.target.result;

								var _this = this;

								img.onload = () => {
									let imgWidth = img.naturalWidth;
									let imgHeight = img.naturalHeight;

									if (imgWidth >= this.cropper.min_width && imgHeight >= this.cropper.min_height) {
										_this.details.images.content.push(e.target.result);
										_this.toggleAllowAddingImages();
									} else {
										alert(`Billedet er for lille. Det skal være mindst ${this.cropper.min_width}px bredt og ${this.cropper.min_height}px højt`);
									}
								};
						}
						// Start the reader job - read file as a data url (base64 format)
						reader.readAsDataURL(input.files[0]);
				}
			},
			cropImages() {
				let _this = this;

				$(this.$refs.cropper).each(function(i, cropper) {
					const {coordinates, canvas} = cropper.getResult();
					_this.details.images.coordinates = coordinates;
					_this.details.images.content[i] = canvas.toDataURL();
				});
			},
			pluck(array, key) {
				return array.map(o => o[key]);
			},
			createIngredient: function() {
				let _this = this;
				let ingredient = { name: this.details.ingredients.new.content };

				axios.post('/api/ingredients', ingredient)
					.then((response) => {
						if (response.data.data) {
							_this.details.ingredients.new.content = '';
							_this.details.ingredients.new.check = false;
							_this.search.ingredients.data.push(response.data.data);
							_this.details.ingredients.content.push(response.data.data);
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			createNuance: function(type) {
				let _this = this;
				let fragrance = { name: this.details.fragrances.new.content };

				axios.post('/api/nuances', fragrance)
					.then((response) => {
						if (response.data.data) {
							_this.details.fragrances.new.content = '';
							_this.details.fragrances.new.check = false;
							_this.search.fragrances.data.push(response.data.data);
							_this.details.fragrances.content.push(response.data.data);
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			checkIfDrinkExists: function() {
				if (this.details.name.content != '') {
					axios.post('/api/check/drink', {
						name: this.details.name.content
					}).then((response) => {
						if (response.data.data.length >= 1) {
							this.drink_exists = true;
							// this.button_disabled = true;
						} else {
							this.drink_exists = false;
							// this.button_disabled = false;
						}
					});
				}
			},
			toggleAllowAddingImages: function() {
				this.allow_adding_images = (this.details.current_images.content.length + this.details.images.content.length) < this.max_images;
			},
			pixelsRestriction({minWidth, minHeight, maxWidth, maxHeight, imageWidth, imageHeight}) {
				return {
					minWidth: minWidth,
					minHeight: minHeight,
					maxWidth: maxWidth,
					maxHeight: maxHeight,
				};
			},
			handlers: (map, vm) => ({
				// ...map, 8: e => {
				// 	e.preventDefault();
				// },
			}),
		}
    }
</script>