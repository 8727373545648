<template>
	<div class="row">
		<div class="col-12 col-sm-10 offset-sm-1">
			<div class="thankyou__wrap">
				<div class="thankyou__image" v-html="this.icon"></div>
				<div class="thankyou__content">
					<h2 class="h1 primary black">{{ this.title }}</h2>
					<div class="thankyou__description" v-html="this.description"></div>
					<div class="thankyou__buttons">
						<a v-show="this.primaryButton.link" :href="this.primaryButton.link" class="button">{{ this.primaryButton.text }}</a>
						<a href="/home" class="button btn-faded" :class="{'primary' : hasNoPrimary}">Kontrolpanel</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
		props: [
			'title',
			'description',
			'primaryButton',
			'icon'
		],
		data() {
			return {
				hasNoPrimary: false,
			}
		},
		mounted() {
			if(this.primaryButton.link == ''){
				this.hasNoPrimary = true;
			}
		},
		methods: {}
    }
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';
</style>