<template>
		<div class="plan" :class="{ 'active' : plan.selected }">
			<div class="check" v-if="plan.selected">
				<i class="far fa-check"></i>
			</div>
			<h2>{{ plan.nickname }}</h2>

			<ul class="content">
				<li v-for="(content, c) in plan.content" :key="c">{{ content }}</li>
			</ul>

			<div class="pricing">
				<div class="price" v-if="plan.interval == 'year'">{{ plan.amount / 100 / 12 }},- / måned</div>
				<div class="price" v-if="plan.interval == 'month'">{{ plan.amount / 100 }},- / måned</div>
			</div>

			<div class="actions">
				<button v-if="plan.subscribed_to" class="success icon" @click="select">Valgt <i class="fas fa-check"></i></button>
				<button v-else-if="plan.selected" class="secondary icon" @click="updateSubscription">Bekræft</button>
				<button v-else @click="select">Vælg</button>
			</div>
		</div>
</template>

<script>
	export default {
		props: {
			plan: {
                type: Object,
			},
        },
        mounted() {},
		methods: {
            select: function() {
                this.$emit('selectplan', this.plan.id);
            },
            updateSubscription: function() {
				this.$emit('updatesubscription', this.plan.id);
            },
        }
    }
</script>