<template>
	<div>
		<div class="row">
			<div class="col-12 col-sm-6">
				<h1>Opret ny rolle</h1>
			</div>
			<div class="col-12 col-sm-6 text-right">
				<span class="button btn-faded" @click="closeCreate">Tilbage</span>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-12">
				<div class="form-group">
					<strong>Navn:</strong>
					<input type="text" name="name" placeholder="Navn" v-model="create.name" class="form-control">
				</div>
			</div>
			<div class="col-xs-12 col-sm-12 col-md-12">
				<div class="form-group">
					<strong>Tiladelser:</strong>
					<div v-for="permission in all_permissions" :key="permission.id">
						<label :for="'check_' + permission.id">
							<input :id="'check_' + permission.id" class="checkedbox permission" v-model="create.permissions" type="checkbox" name="permission[]" :value="permission.id">
							<span class="check"><i class="far fa-check"></i></span>
							<span class="label">{{ permission.description }}</span>
						</label>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-sm-12 col-md-12 text-right">
				<span class="button btn-faded" @click="closeCreate">Tilbage</span>
				<button type="submit" @click="createRole" class="button btn-primary icon">
					Opret rolle
					<i class="far fa-plus"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: [
			'parentCloseThis',
			'all_permissions',
		],
		data() {
			return {
				create: {
					name: '',
					permissions: [],
				}
			}
		},
        mounted() {},
		methods: {
			createRole: function() {
				axios.post('/api/roles', {
					'name': this.create.name,
					'permission': this.create.permissions,
				}).then((response) => {
					this.$emit('created');
					this.closeCreate();
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			closeCreate: function() {
				this.parentCloseThis();
			},
		}
    }
</script>