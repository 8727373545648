<template>
	<div>
		<div class="row" v-if="!single.active">
			<div class="col-12">
				<h1>Mails</h1>
			</div>
			<div class="col-12 col-md-8">
				<div>
					<div class="row my-row head-row">
						<div class="col-8 col-sm-10">Name</div>
						<div class="col-2 col-sm-1 justify-content-center"><span v-if="hasPermission('mail-edit')" class="d-none d-sm-inline">Redigér</span></div>
						<div class="col-2 col-sm-1 justify-content-center"><span v-if="hasPermission('mail-delete')" class="d-none d-sm-inline">Slet</span></div>
					</div>
					<div v-if="mails.data && (mails.data.length > 0)">
						<div class="row my-row body-row" v-for="mail in mails.data" :key="mail.id">
							<div class="col-8 col-sm-10">
								<div>{{ mail.name }}</div>
							</div>
							<div class="col-2 col-sm-1 text-center">
								<i v-if="hasPermission('mail-edit')" @click="editMail(mail)" class="far fa-edit interact"></i>
							</div>
							<div class="col-2 col-sm-1 text-center">
								<i v-if="hasPermission('mail-delete')" class="far fa-trash interact"></i>
							</div>
						</div>
					</div>

					<pagination class="mt-3" :data="mails" :show-disabled="true" :limit="limit" @pagination-change-page="getMails" v-if="mails.data && (mails.data.length > 0)"></pagination>
				</div>
			</div>
			<div class="col-12 col-md-4 text-right">
				<div class="button primary icon" @click="createMail">
					Opret ny mail
					<i class="far fa-plus"></i>
				</div>
			</div>
		</div>

		<mail-edit :headline="single.headline" :type="single.type" v-if="single.active" :mail="single.mail" :parentCloseSingleMail="closeSingleMail"></mail-edit>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.getMails();

			var ww = $(window).width();

			if(ww < 768){
				this.limit = -1;
			}
		},
		created() {},
		props: {
            winebar: {
                type: Object,
			},
            permissions: {
                type: Array,
			},
        },
		data() {
			return {
				mails: [],
				current_page: 1,
				limit: 5,
				single: {
					mail: [],
					active: false,
					headline: 'Redigér mail',
					type: 'edit',
				}
			}
		},
		methods: {
			getMails(page = 1) {
				this.current_page = page;

				axios.get('/api/winebar-mails/' + this.winebar.id + '?page=' + page)
					.then((response) => {
						if (page <= 1) {
							 this.mails = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.mails = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getMails(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
            },
			hasPermission: function(permission) {
				return this.permissions.findIndex(x => x.name === permission) > -1;
			},
			editMail: function(mail){
				this.single.active = true;
				this.single.mail = mail;
				this.single.headline = 'Redigér mail';
				this.single.type = 'edit';
			},
			createMail: function(mail){
				this.single.active = true;
				this.single.mail = [];
				this.single.headline = 'Opret mail';
				this.single.type = 'create';
			},
			closeSingleMail: function(){
				this.single.active = false;
				this.single.mail = [];
			}
		}
	}
</script>