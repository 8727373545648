<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<h1>Druer</h1>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-md-7 col-lg-8">
				<div>
					<div class="row my-row head-row">
						<div class="col-2 col-sm-1">Nr.</div>
						<div class="col-7 col-sm-8">Name</div>
						<div class="col-1 text-center"><span class="d-none d-sm-inline">Redigér</span></div>
						<div class="col-1 text-center"><span class="d-none d-sm-inline">Fjern</span></div>
					</div>
					<div id="accordion" v-if="grapes.data && (grapes.data.length > 0)">
						<div class="row my-row body-row" v-for="grape in grapes.data" :key="grape.id">
							<div class="col-2 col-sm-1">#{{ grape.id }}</div>
							<div class="col-7 col-sm-8">
								<div v-if="editing != grape.id">{{ grape.name }}</div>
								<div v-if="editing == grape.id" class="d-flex">
									<input type="text" v-bind:id="'grape-name-' + grape.id" v-bind:value="grape.name" class="inline minusleft">
									<button class="button success" v-on:click="updateGrape(grape.id)">Save</button>
								</div>
							</div>
							<div class="col-1 text-center">
								<i class="far fa-edit interact" @click="toggleUpdateGrape(grape.id)"></i>
							</div>
							<div class="col-1 text-center">
								<i class="far fa-trash interact" v-on:click="deleteGrape(grape.id)"></i>
							</div>
						</div>
					</div>

					<pagination class="mt-3" :data="grapes" :show-disabled="true" :limit="limit" @pagination-change-page="getGrapes" v-if="grapes.data && (grapes.data.length > 0)"></pagination>
				</div>
			</div>
			<div class="col-12 col-md-5 col-lg-4">
				<div class="dark-bg">
					<h2>Opret ny drue</h2>
					<form id="grape-form" class="w-100" v-on:submit.prevent="createGrape()">
						<label for="name">Name</label>
						<input type="text" id="name">
						<div class="text-right">
							<button id="create-btn" class="icon mt-1">Create <i class="far fa-plus"></i></button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.getGrapes();

			var ww = $(window).width();

			if(ww < 768){
				this.limit = -1;
			}
		},
		created() {},
		data() {
			return {
				grapes: [],
				current_page: 1,
				editing: 0,
				limit: 5,
			}
		},
		methods: {
			getGrapes(page = 1) {
				this.current_page = page;

				axios.get('/api/grapes?page=' + page)
					.then((response) => {
						if (page <= 1) {
							 this.grapes = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.grapes = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getGrapes(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			createGrape() {
				var self = this;
				var $name = $('#name');
				
				axios.post('/api/grapes', {
						name: $name.val()
					})
					.then(function (response) {
						self.getGrapes(self.current_page);
						$name.val('');
						$('#create-btn').html('<i class="fad fa-check"></i>');
						setTimeout(function() {
								$('#create-btn').html('Create');
						}, 2000);
					})
					.catch(function (error) {
						console.warn(error);
					});
			},
			updateGrape(grape) {
				var self = this;
				
				axios.patch('/api/grapes/' + grape, {
						name: $('#grape-name-' + grape).val()
					})
					.then(function (response) {
						self.getGrapes(self.current_page);
						self.editing = 0;
					})
					.catch(function (error) {
						console.warn(error);
					});
			},
			deleteGrape(grape) {
				if (confirm("Are you sure?")) {
					var self = this;
					
					axios.delete('/api/grapes/' + grape)
						.then(function (response) {
							self.getGrapes(self.current_page);
						})
						.catch(function (error) {
							console.warn(error);
						});
				}
			},
			toggleUpdateGrape(id) {
				this.editing = (this.editing == id) ? 0 : this.editing = id;
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';

	.my-row {
		min-height: 40px;
		line-height: 40px;
		transition: .3s ease-out;

		&:not(.head-row) {
			&:hover {
				background-color: rgba($black-color, .03);
			}
		}

		&.end {
			align-items: flex-end;
		}
	}
</style>