<template>
	<div class="navigation__wrapper">
		<div class="navigation">
			<div class="row" v-for="(nav, k) in navigation" :key="k" :class="{ 'flex-column-reverse' : navigation.length == 1 }">
				<a :href="nav.route" class="d-flex col-12 navigation__content--wrap flex-grow-1" :class="{ 'col-sm-6' : navigation.length > 1 }">
					<div class="navigation__content">
						<h2>{{ nav.title }}</h2>
						<div v-html="nav.description"></div>
					</div>
				</a>
				<a :href="nav.route" class="d-none d-sm-block navigation__image flex-grow-1" :style="'background-image: url(' + nav.image + ');'" :class="{ 'col-6' : navigation.length > 1, 'col-12' : navigation.length == 1 }"></a>
			</div>
			<div class="close_btn" @click="toggleMenuOpen()" v-if="!this.active">
				<i class="far fa-times"></i>
			</div>
		</div>

		<div class="frontend-navigation" @click="toggleMenuOpen()">
			<div class="burger">
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {
			console.log(this.wine_bar_slug);
		},
		created() {
			console.log(this.wine_bar_slug);
		},
		data() {
			return {
				navigation: [
					{
						title: 'Vinkort',
						description: 'Mange spændende vine fra hele verden. Søg hurtig og nemt efter den vin du ønsker!',
						image: 'https://app.vivinobar.com/images/splash-red-wine.jpg',
						route: `/g/${this.wine_bar_slug}/wines`,
					},
					{
						title: 'Vinsmagning',
						description: 'Prøv en spændende <br> sammensat vinsmagning. <br> 6 cl. pr. glas.',
						image: 'https://app.vivinobar.com/images/wine-course-image.jpg',
						route: `/g/${this.wine_bar_slug}/courses`,
					},
					{
						title: 'Vin på tilbud',
						description: 'Tidligere vin fra vinkortet til <br> nedsat pris.',
						image: 'https://app.vivinobar.com/images/splash-white-wine.jpg',
						route: `/g/${this.wine_bar_slug}/offerwines`,
					},
					{
						title: 'Cocktails',
						description: 'Lyst til andet end vin? <br> Her er vores udvalg af cocktails.',
						image: 'https://app.vivinobar.com/images/drinks-image.jpg',
						route: `/g/${this.wine_bar_slug}/drinks`,
					},
					{
						title: 'Mad & snacks',
						description: 'Find specialiteter <br> og lækkerier til vinen.',
						image: 'https://app.vivinobar.com/images/tapas.jpg',
						route: `/g/${this.wine_bar_slug}/foodmenus`,
					}
				],
			}
		},
		props: ['active', 'wine_bar_slug'],
		methods: {
			toggleMenuOpen() {
				$('body').toggleClass('open--menu');
			},
		}
	}
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';

	.navigation {
		top: 0;
		left: 0;
		opacity: 0;
		z-index: 90;
		width: 100vw;
		height: 100vh;
		display: flex;
		overflow: auto;
		padding: 0 15px;
		position: fixed;
		align-items: stretch;
		pointer-events: none;
		flex-direction: column;
		justify-content: stretch;
		transition: opacity .3s ease-out .0s;

		max-width: 768px;
		left: 50%;
		transform: translateX(-50%);

		.active--menu &,
		.open--menu & {
			opacity: 1;
			pointer-events: all;
			transition: opacity .3s ease-out .6s;
		}

		&__image {
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}
		
		&__content {
			width: 100%;
			display: flex;
			margin: 0 auto;
			padding: 15px 0;
			max-width: 285px;
			text-align: center;
			color: $white-color;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			h2 {
				margin: 0;
				font-size: 20px;
				// text-transform: uppercase;

				@media(min-width: $sm){
					font-size: 22px;
				}
				
				+ div {
					font-size: 14px;

					@media(min-width: $sm){
						font-size: 16px;
					}
				}
			}

			&--wrap {
				display: flex;
				color: $white-color;
				align-items: center;
				//background-image: url('https://app.vivinobar.com/images/vivino-draaber-bg.svg');
				background-repeat: no-repeat;
				
				&:hover {
					text-decoration: none;
				}
			}
		}

		> .row {
			opacity: 0;
			flex-grow: 1;
			flex-basis: 0;
			min-height: 20vh;
			transition: opacity .3s ease-out .0s;

			.active--menu &,
			.open--menu & {
				opacity: 1;
			}

			&:nth-child(even) {
				.navigation__content--wrap {
					order: 2;
				}
			}

			&:nth-child(5n + 1) {
				background-color: $menu_1;
				transition: opacity .3s ease-out .6s;
			}

			&:nth-child(5n + 2) {
				background-color: $menu_2;
				transition: opacity .3s ease-out 0.7s;
			}

			&:nth-child(5n + 3) {
				background-color: $menu_3;
				transition: opacity .3s ease-out 0.8s;
			}

			&:nth-child(5n + 4) {
				background-color: $menu_4;
				transition: opacity .3s ease-out 0.9s;
			}

			&:nth-child(5n + 5) {
				background-color: $menu_5;
				transition: opacity .3s ease-out 1.0s;
			}
		}

		.close_btn {
			opacity: 0;
			transition: opacity .3s ease-out 1.2s;

			.active--menu &,
			.open--menu & {
				opacity: 1;
			}
		}
	}

	.frontend-navigation {
		right: 0;
		bottom: 0;
		z-index: 50;
		width: 100px;
		height: 100px;
		position: fixed;
		
		&:before {
			right: 0;
			bottom: 0;
			content: "";
			width: 150px;
			display: block;
			position: absolute;
			padding-top: 150px;
			border-radius: 50% 0 0 0;
			transform: translate(50%, 50%);
			background-color: rgba($darkestgray-color, .8);
			transition: .7s ease-out 0.4s, border-radius .3s ease-out 0.3s, background-color .5s ease-out 0.3s;

			@media(min-width: $sm){
				width: 200px;
				padding-top: 200px;
			}
		}

		.burger {
			right: 15px;
			width: 28px;
			height: 26px;
			top: calc(61% - 3px);

			@media(min-width: $sm){
				right: 20px;
				width: 38px;
				height: 26px;
				top: calc(50% - 3px);
			}

			span {
				width: 100%;
				height: 3px;
				border-radius: 2px;
				background-color: $white-color;

				@media(min-width: $sm){
					height: 4px;
				}

				&:first-child {
					top: calc(50% - 9px);

					@media(min-width: $sm){
						top: calc(50% - 11px);
					}

					.active--menu &,
					.open--menu & {
						top: 50%;
					}
				}
				&:last-child {
					top: calc(50% + 9px);

					@media(min-width: $sm){
						top: calc(50% + 11px);
					}
					
					.active--menu &,
					.open--menu & {
						top: 50%;
					}
				}
			}
		}

		.active--menu &,
		.open--menu & {
			.burger {
				opacity: 0;
			}

			&:before {
				width: 100vw;
				border-radius: 0;
				padding-top: 100vh;
				transform: translate(0%, 0%);
				background-color: $darkestgray-color;
				transition: .5s ease-out, border-radius .5s ease-out 0.3s, background-color .5s ease-out 0.3s;
			}
		}
	}
</style>