<template>
	<div id="terms" class="rowwrap p-3 p-sm-5">
		<div class="close_btn" @click="closeThis()">
			<i class="far fa-times"></i>
		</div>
		<div class="row">
			<div class="col-12">
				<h2 class="slim mb-4">Personoplysninger</h2>
			</div>
			<div class="col-12 mt-4">
				<strong>Generelt</strong>
				<p>Personoplysninger er alle slags informationer, der i et eller andet omfang kan henføres til dig. Når du benytter vores website indsamler og behandler vi en række sådanne informationer. Det sker ved alm. tilgang af indhold, udfyldelse af kontaktformular og hvis du tilmelder dig vores nyhedsbrev. Vi indsamler og behandler typisk følgende typer af oplysninger ved alm. brug af vores website:</p>

				<ul>
					<li>Unikt ID og tekniske oplysninger om din computer, tablet eller mobiltelefon</li>
					<li>IP-nummer</li>
					<li>Geografisk placering</li>
					<li>Hvilke sider du klikker på (interesser)</li>
				</ul>

				<p>I forbindelse med udfyldelse af kontaktformular eller tilmelding til nyhedsbrev, indtaster du selv informationer og giver samtykke til behandling af følgende:</p>
				<ul>
					<li>Navn</li>
					<li>E-mail</li>
				</ul>

				<strong>Sikkerhed</strong>
				<p>Vi har truffet tekniske og organisatoriske foranstaltninger mod, at dine oplysninger hændeligt eller ulovligt bliver slettet, offentliggjort, fortabt, forringet eller kommer til uvedkommendes kendskab, misbruges eller i øvrigt behandles i strid med lovgivningen.</p>

				<strong>Formål</strong>
				<p>Oplysningerne bruges til at identificere dig som bruger og vise dig de annoncer, som vil have størst sandsynlighed for at være relevante for dig, samt at kunne levere de services, du har efterspurgt, som f.eks. at fremsende et nyhedsbrev. Herudover anvender vi oplysningerne til at optimere vores services og indhold.</p>

				<strong>Periode for opbevaring</strong>
				<p>Oplysningerne opbevares i det tidsrum, der er tilladt i henhold til lovgivningen, og vi sletter dem, når de ikke længere er nødvendige. Perioden afhænger af karakteren af oplysningen og baggrunden for opbevaring. Det er derfor ikke muligt at angive en generel tidsramme for, hvornår informationer slettes.</p>

				<strong>Videregivelse af oplysninger</strong>
				<p>Data om din brug af websitet, hvilke annoncer, du modtager og evt. klikker på, geografisk placering, køn og alderssegment m.v. videregives til tredjeparter i det omfang disse oplysninger er kendt. Du kan se hvilke tredjeparter, der er tale om, i afsnittet om ”Cookies” på vivinobar.com. Oplysningerne anvendes til målretning af annoncering.</p>
				<p>Vi benytter herudover en række tredjeparter til opbevaring og behandling af data. Disse behandler udelukkende oplysninger på vores vegne og må ikke anvende dem til egne formål. Videregivelse af personoplysninger som navn og e-mail m.v. vil kun ske, hvis du giver samtykke til det. Vi anvender kun databehandlere i EU eller i lande, der kan give dine oplysninger en tilstrækkelig beskyttelse.</p>

				<strong>Indsigt og klager</strong>
				<p>Du har ret til at få oplyst, hvilke personoplysninger, vi behandler om dig. Du kan desuden til enhver tid gøre indsigelse mod, at oplysninger anvendes. Du kan også tilbagekalde dit samtykke til, at der bliver behandlet oplysninger om dig. Hvis de oplysninger, der behandles om dig, er forkerte har du ret til at de bliver rettet eller slettet. Henvendelse herom kan ske til: Roskilde@vivinobar.dk.</p>
				<p>Hvis du vil klage over vores behandling af dine personoplysninger, har du også mulighed for at tage kontakt til Datatilsynet.</p>

				<strong>Udgiver</strong>
				<p>Websitet ejes og publiceres af:<br>
				Vivino bar Scandinavia ApS<br>
				Allehelgensgade 3<br>
				4000 Roskilde<br>
				Email: <a class="hidden-link" href="mailto:roskilde@vivinobar.dk">roskilde@vivinobar.dk</a></p>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
		data() {
			return {
				
			}
		},
		mounted() {
			
		},
		methods: {
			closeThis: function(){
				$('body').removeClass('open--terms');
			}
		}
    }
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';

	#terms {
		top: 0;
		left: 0;
		opacity: 0;
		width: 100%;
		z-index: 52;
		height: 100vh;
		overflow: auto;
		position: fixed;
		transition: .3s ease-out;
		pointer-events: none;
		transform: translateY(25px);
		background-color: $darkestgray-color;

		.open--terms & {
			opacity: 1;
			pointer-events: all;
			transform: translateY(0);
		}
	}
</style>