<template>
	<div>
		<div class="row px-3 px-sm-5">
			<div class="row py-3 mt-3 mt-sm-5">
				<div class="col-12 col-sm-6 col-md-4 mb-4" v-for="recipe in recipes.data" :key="recipe.id">
					<div class="wine__item" @click="openDrink(recipe)">
						<div class="wine__item--content">
							<div v-if="recipe.images.length > 0">
								<div class="wine__item--content__image mb-2">
									<img :src="'/storage' + recipe.images[0].path" alt="">
								</div>
							</div>
							<div v-else>
								<div class="wine__item--content__image mb-2">
									<img src="/images/placeholder_etiqette.jpg" alt="">
								</div>
							</div>

							<h2 class="wine__item--content__title">{{ recipe.name }}</h2>
							<div class="wine__item--content__description"></div>
						</div>
					</div>
				</div>

				<div class="col-12 d-flex justify-content-center align-items-center">
					<scroll-loader :loader-method="getRecipes" :loader-disable="disable" :loader-size="55" :loader-distance="200"></scroll-loader>
				</div>
			</div>
		</div>

		<staff-singlerecipe :active="single.active" :recipe="single.recipe" :parentCloseThis="parentCloseThis"></staff-singlerecipe>

		<private-navigation :active="false" :wine_bar="this.wine_bar"></private-navigation>
	</div>
</template>

<script>
	export default {
		mounted() {
			
		},
		data() {
			return {
				recipes: [],
				disable: false,
				current_page: 1,
				pageSize: 10,
				single: {
					active: false,
					recipe: {},
				},
			}
		},
		props: ['winemenu', 'wine_bar'],
		methods: {
			getRecipes() {
				axios.post('/api/recipes', {
					'wine_bar_id': this.wine_bar.id
				})
				.then((response) => {
					console.log(response);
					if (response.data.data.length > 0) {
						this.recipes = response.data;
					}

					this.current_page = this.current_page + 1;
						this.disable = response.data.data.length < this.pageSize;
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			openDrink(recipe) {
				this.single.active = true;
				this.single.recipe = recipe;
			},
			openDrinkParent: function(recipe){
				this.single.active = true;
				this.single.recipe = recipe;
			},
			toggleMenuOpen() {
				$('body').toggleClass('open--menu');
			},
			parentCloseThis: function() {
				this.single.active = false;
			},
		}
	}
</script>

<style lang="scss">
	@import '../../../../sass/utils/_variables.scss';
	@import '../../../../sass/utils/_placeholders.scss';
</style>