<template>
	<div>
		<div class="row">
			<div class="col-12">
				<h1>Venteliste</h1>
			</div>
		</div>

        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="wines-tab" data-toggle="tab" href="#wines" role="tab" aria-controls="wines" aria-selected="true">Vine</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="drinks-tab" data-toggle="tab" href="#drinks" role="tab" aria-controls="drinks" aria-selected="false">Drinks</a>
            </li>
        </ul>

		<transition name="fade-long">
			<div class="tab-content" id="myTabContent">
				<div class="tab-pane fade show active" id="wines" role="tabpanel" aria-labelledby="wines-tab">
					<div>
						<select class="pr_page" v-model="pr_page" @change="getWinesOnWaitingList">
							<option value="5">5</option>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</select>
					</div>
					<div class="row" v-if="!this.wine">
						<div class="col-12">
							<div>
								<div class="row my-row head-row">
									<div class="col-2 col-sm-1">ID</div>
									<div class="col-8 col-sm-9">Navn</div>
									<div class="col-1 justify-content-center"><span class="d-none d-sm-inline">Vis</span></div>
									<div class="col-1 justify-content-center"><span class="d-none d-sm-inline">Afvis</span></div>
								</div>
								<div v-if="wines.data && (wines.data.length > 0)">
									<div class="row my-row body-row" v-for="wine in wines.data" :key="wine.id">
										<div class="col-2 col-sm-1">#{{ wine.id }}</div>
										<div class="col-8 col-sm-9">
											<div>{{ wine.name }}</div>
										</div>
										<div class="col-1 text-center">
											<i class="far fa-eye interact" @click="acceptWine(wine)"></i>
										</div>
										<div class="col-1 text-center">
											<i class="far fa-times interact" @click="declineWine(wine)"></i>
										</div>
									</div>
								</div>

								<pagination class="mt-3" :data="wines" :show-disabled="true" :limit="limit" @pagination-change-page="getWinesOnWaitingList" v-if="wines.data && (wines.data.length > 0)"></pagination>
							</div>
						</div>
					</div>

					<div class="row mt-3" v-if="this.wine">
						<winecellar-create-wine title="Accepter vin" :action="this.action" :wine="this.wine" :parentCloseEditOpen="closeEditOpen"></winecellar-create-wine>
					</div>
				</div>
				
				<div class="tab-pane fade" id="drinks" role="tabpanel" aria-labelledby="drinks-tab">
					<div>
						<select class="pr_page" v-model="pr_page" @change="getDrinksOnWaitingList">
							<option value="5">5</option>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</select>
					</div>
					<div class="row" v-if="!this.drink">
						<div class="col-12">
							<div>
								<div class="row my-row head-row">
									<div class="col-2 col-sm-1">ID</div>
									<div class="col-8 col-sm-9">Navn</div>
									<div class="col-1 justify-content-center"><span class="d-none d-sm-inline">Vis</span></div>
									<div class="col-1 justify-content-center"><span class="d-none d-sm-inline">Afvis</span></div>
								</div>
								<div v-if="drinks.data && (drinks.data.length > 0)">
									<div class="row my-row body-row" v-for="drink in drinks.data" :key="drink.id">
										<div class="col-2 col-sm-1">#{{ drink.id }}</div>
										<div class="col-8 col-sm-9">
											<div>{{ drink.name }}</div>
										</div>
										<div class="col-1 text-center">
											<i class="far fa-eye interact" @click="acceptDrink(drink)"></i>
										</div>
										<div class="col-1 text-center">
											<i class="far fa-times interact" @click="declineDrink(drink)"></i>
										</div>
									</div>
								</div>

								<pagination class="mt-3" :data="drinks" :show-disabled="true" :limit="limit" @pagination-change-page="getDrinksOnWaitingList" v-if="drinks.data && (drinks.data.length > 0)"></pagination>
							</div>
						</div>
					</div>

					<div class="row mt-3" v-if="this.drink">
						<winebar-create-drink title="Accepter drink" :action="this.action" :drink="this.drink" :parentCloseEditOpen="closeEditOpen" :wine_bar="this.wine_bar"></winebar-create-drink>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.getWinesOnWaitingList();
			this.getDrinksOnWaitingList();

			var ww = $(window).width();

			if (ww < 768) {
				this.limit = -1;
			}
		},
		created() {
			console.log('WINEBAR', this.wine_bar);
		},
		data() {
			return {
				wines: [],
				drinks: [],
                current_page: 1,
				limit: 5,
				pr_page: 10,
				wine: null,
				drink: null,
                action: null,
			}
		},
		props: ['wine_bar'],
		methods: {
			getWinesOnWaitingList(page = 1) {
				this.current_page = page;

				axios.get('/api/waiting/wines?page=' + page + '&limit=' + this.pr_page)
					.then((response) => {
						if (page <= 1) {
							 this.wines = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.wines = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getWinesOnWaitingList(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			acceptWine: function(wine) {
                this.wine = wine;
				this.action = 'accept';
			},
			declineWine: function(wine) {
				if (confirm('Er du sikker?')) {
					axios.patch('/api/accepted-wines/' + wine.id, {
							'is_declined': 1,
						}).then((response) => {
							this.getWinesOnWaitingList(this.current_page);
						})
						.catch((error) => {
							console.warn(error);
						});
				}
			},
			getDrinksOnWaitingList(page = 1) {
				this.current_page = page;

				axios.get('/api/waiting/drinks?page=' + page + '&limit=' + this.pr_page)
					.then((response) => {
						if (page <= 1) {
								this.drinks = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.drinks = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getDrinksOnWaitingList(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			acceptDrink: function(drink) {
				this.drink = drink;
				this.action = 'accept';
			},
			declineDrink: function(drink) {
				axios.patch('/api/accepted-drinks/' + drink.id, {
						'is_declined': 1,
					}).then((response) => {
						this.getDrinksOnWaitingList(this.current_page);
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			closeEditOpen: function() {
				this.wine = '';
				this.drink = '';
			},
		}
	}
</script>