<template>
	<div class="row">
		<div class="col-12 col-xl-8 mb-5">
			<div class="row my-row head-row">
				<div class="col-2 col-md-1">Nr.</div>
				<div class="d-none d-md-flex col-3 col-md-6">Navn</div>
				<div class="col-2 col-md-1">Pris</div>
				<div class="col-2 col-md-1 center-row d-none d-md-flex winemenu__price">Redigér priser</div>
				<div class="col-2 col-md-1 center-row d-none d-md-flex winemenu__price">Redigér vinkort</div>
				<div class="col-2 col-md-1 center-row d-none d-md-flex winemenu__price">Fjern</div>
			</div>

			<div class="row my-row body-row" v-for="wine in wine_menu_wines.data" :key="wine.id">
				<div class="col-2 col-md-1">#{{ wine.position }}</div>
				<div class="d-none d-md-block col-3 col-md-6 text-ellipsis">{{ wine.name }}</div>
				<div class="col-2 col-md-1 winemenu__price">
					<div v-if="wine.bottle_price != null">{{ wine.bottle_price | numberformat }},-</div>
					<div v-else>-</div>
				</div>
				<div class="col-2 col-md-1 winemenu__price text-center">
					<i class="far fa-money-check-edit-alt interact" @click="editWineOnWineMenu(wine)"></i>
				</div>
				<div class="col-2 col-md-1 winemenu__price text-center">
					<i class="far fa-edit interact"></i>
				</div>
				<div class="col-2 col-md-1 winemenu__price text-center">
					<i class="far fa-trash interact" @click="removeWineFromWineMenu(wine)"></i>
				</div>
			</div>
		</div>

		<div class="col-12 col-xl-4">
			<div class="dark-bg">
				<div class="row">
					<div class="col-6">
						<label for="type">Type</label>
						<v-select label="name" :options="types.data"></v-select>
					</div>
					<div class="col-6">
						<label for="country">Land</label>
						<v-select label="name" :options="countries.data"></v-select>
					</div>
					<div class="col-6">
						<label for="">Søg</label>
						<input type="text" placeholder="Søg efter vin" class="input">
					</div>
					<div class="col-6 text-right">
						<label for="">&nbsp;</label>
						<div>
							<button>Filtrér</button>
						</div>
					</div>
				</div>

				<div class="row">
					<nav class="w-100">
						<div class="nav nav-tabs" id="nav-tab" role="tablist">
							<a class="tab active" id="nav-my-winedatabase-tab" data-toggle="tab" href="#nav-my-winedatabase" role="tab" aria-controls="nav-my-winedatabase" aria-selected="true">Min vindatabase</a>
							<a class="tab" id="nav-global-winedatabase-tab" data-toggle="tab" href="#nav-global-winedatabase" role="tab" aria-controls="nav-global-winedatabase" aria-selected="false">Global vindatabase</a>
						</div>
					</nav>
				</div>

				<div class="tab-content pt-2" id="nav-tabContent">
					<div class="tab-pane fade show active" id="nav-my-winedatabase" role="tabpanel" aria-labelledby="nav-my-winedatabase-tab">
						<div class="row my-row body-row" v-for="wine in wine_bar_wines.data" :key="wine.id">
							<div class="col-10 col-md-11">{{ wine.name }}</div>
							<div class="col-2 col-md-1">
								<i class="far fa-plus-square interact" v-on:click="showModalForAddingWineToWineMenu(wine)"></i>
							</div>
						</div>

						<pagination class="mt-3" :data="wine_bar_wines" :limit="-1" :show-disabled="true" @pagination-change-page="getWineBarWines" v-if="wine_bar_wines.data && (wine_bar_wines.data.length > 0)"></pagination>
					</div>
					
					<div class="tab-pane fade" id="nav-global-winedatabase" role="tabpanel" aria-labelledby="nav-global-winedatabase-tab">
						<div class="row my-row body-row" v-for="wine in global_wines.data" :key="wine.id">
							<div class="col-10 col-md-11">{{ wine.name }}</div>
							<div class="col-2 col-md-1">
								<i class="far fa-plus-square interact" v-on:click="addWineToWineBar(wine)"></i>
							</div>
						</div>

						<pagination class="mt-3" :data="global_wines" :limit="-1" :show-disabled="true" @pagination-change-page="getWineBarWines" v-if="global_wines.data && (global_wines.data.length > 0)"></pagination>
					</div>
				</div>
			</div>
		</div>

		<transition name="fade">
			<div id="modal" class="modal" v-if="showmodal == true">
				<div class="modal__background" @click="hideModalForAddingWineToWineMenu"></div>
				<div class="modal__wrapper">
					<span @click="hideModalForAddingWineToWineMenu" class="modal__close--button"><i class="far fa-times"></i></span>
					<strong class="modal__headline">{{ modal.wine }}</strong>
					<span id="modal-wine-menu"></span>
					<form id="modal-wine-form" v-on:submit.prevent="addWineToWineMenu">
						<div class="input__wrapper">
							<label for="modal-wine-price-glass">Pris pr. glas</label>
							<div class="append">kr.</div>
							<input type="number" id="modal-wine-price-glass" name="glassPrice" class="input" v-model="modal.price.glass">
						</div>

						<div class="input__wrapper">
							<label for="modal-wine-price-bottle">Pris pr. flaske</label>
							<div class="append">kr.</div>
							<input type="number" id="modal-wine-price-bottle" name="bottlePrice" class="input" v-model="modal.price.bottle">
						</div>

						<div class="">
							<label for="modal-wine-position">Placering</label>
							<v-select :options="this.available_positions" v-model="modal.position"></v-select>
						</div>

						<input type="hidden" id="modal-wine-id" name="wine_id">
						<input type="submit" class="button primary w-100" :value="modal.buttontext">
					</form>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.getWineMenuWines();
			this.getWineBarWines();
			this.getGlobalWines();
			this.getTypes();
			this.getCountries();
			this.getAvailablePositions();
		},
		created() {},
		data() {
			return {
				wine_menu_wines: [],
				wine_bar_wines: [],
				global_wines: [],
				types: [],
				countries: [],
				available_positions: [''],
				current_page: 1,
				showmodal: false,
				modal: {
					wine: '',
					price: {
						glass: null,
						bottle: null,
					},
					position: null,
					buttontext: 'Tilføj til vinkort',
				}
			}
		},
		props: {
			winemenu: {
				type: Object,
			}
		},
		methods: {
			getTypes() {
				axios.get('/api/types')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.types = response.data;
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getCountries() {
				axios.get('/api/countries?limit=10000')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.countries = response.data;
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getAvailablePositions() {
				axios.get('/api/wine-positions/' + this.winemenu.id)
					.then((response) => {
						this.available_positions = $.map(response.data, function(value, index){
							return [value];
						});
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getWineMenuWines(page = 1) {
				this.current_page = page;

				axios.get('/api/winemenu-wines/' + this.winemenu.id + '?page=' + page)
					.then((response) => {
						if (page <= 1) {
							 this.wine_menu_wines = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.wine_menu_wines = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getWineMenuWines(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			showModalForAddingWineToWineMenu(wine) {
				$('#modal-wine-menu').text(wine.name);
				$('#modal-wine-id').val(wine.id);
				this.resetPositionSelect();
				this.showmodal = true;
				this.modal.wine = wine.name;
				this.modal.price.glass = wine.glass_price;
				this.modal.price.bottle = wine.bottle_price;
				this.modal.position = wine.id;
				this.modal.buttontext = 'Tilføj til vinkort';
			},
			hideModalForAddingWineToWineMenu() {
				this.available_positions.splice(this.modal.position, 1);
				this.showmodal = false;
				this.modal.wine = '';
				this.modal.price.glass = null;
				this.modal.price.bottle = null;
				this.modal.position = null;
			},
			resetPositionSelect() {
				$('#default-option').prop('selected', true);
				$('option.remove-me').remove();
			},
			addWineToWineMenu(submitEvent) {
				let bottlePrice = submitEvent.target.elements.bottlePrice.value;
				let glassPrice = submitEvent.target.elements.glassPrice.value;
				let position = submitEvent.target.elements.position.value;
				let wine_id = submitEvent.target.elements.wine_id.value;

				axios.post('/api/winemenu-wines', {
						'bottle_price': bottlePrice,
						'glass_price': glassPrice,
						'position': position,
						'wine_id': wine_id,
						'wine_menu_id': this.winemenu.id
					}).then((response) => {})
					.catch((error) => {
						console.warn(error);
					}).then(() => {
						this.hideModalForAddingWineToWineMenu();
						this.getWineMenuWines();
						this.getWineBarWines();
						this.getAvailablePositions();
					});
			},
			removeWineFromWineMenu(wine) {
				axios.delete('/api/winemenu-wines/' + this.winemenu.id + '/' + wine.id)
					.then((response) => {})
					.catch((error) => {
						console.warn(error);
					}).then(() => {
						this.getWineMenuWines();
						this.getWineBarWines();
					});
			},
			editWineOnWineMenu(wine) {
				$('#modal-wine-menu').text(wine.name);
				$('#modal-wine-id').val(wine.id);

				$('option.remove-me').remove();
				$('select#modal-wine-position').prepend('<option class="remove-me" value="' + wine.position + '">' + wine.position + '</option>');
				$('select#modal-wine-position').find('option[value="' + wine.position + '"]').prop('selected', true);

				this.available_positions.push(wine.position);
				this.available_positions.sort(function(a, b){return a-b});

				this.modal.wine = wine.name;
				this.modal.price.glass = wine.glass_price;
				this.modal.price.bottle = wine.bottle_price;
				this.modal.position = wine.id;
				this.modal.buttontext = 'Redigér vin';
				this.showmodal = true;
			},
			getWineBarWines(page = 1) {
				this.current_page = page;

				axios.get('/api/winebar-wines/' + this.winemenu.wine_bar_id + '/' + this.winemenu.id + '?page=' + page)
					.then((response) => {
						if (page <= 1) {
							 this.wine_bar_wines = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.wine_bar_wines = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getWineBarWines(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getGlobalWines(page = 1) {
				this.current_page = page;
				
				axios.get('/api/global-wines/' + this.winemenu.wine_bar_id + '?page=' + page)
					.then((response) => {
						if (page <= 1) {
							 this.global_wines = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.global_wines = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getGlobalWines(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			addWineToWineBar(wine) {
				axios.post('/api/winebar-wines', {
						wine_id: wine.id,
						wine_bar_id: this.winemenu.wine_bar_id
					}).then((response) => {
						this.getWineMenuWines();
						this.getWineBarWines();
						this.getGlobalWines();
					})
					.catch((error) => {
						console.warn(error);
					});
			}
		}
	}
</script>

<style lang="scss">
@import '../../../sass/utils/_variables.scss';

	.center-row {
		text-align: center;
		justify-content: center;
	}

	.head-row {
		> div {
			display: flex;
			line-height: 1.2;
			align-items: flex-end;
		}

		i {
			font-size: 16px;
		}
	}

	.winemenu {
		&__price {
			@media(max-width: $sm - 1px){
				padding: 0;
			}
		}
	}
</style>