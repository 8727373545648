<template>
	<div>
		<transition name="fade-long">
			<div class="row" v-if="!this.wine">
				<div class="col-12">
					<h1>{{ this.headline }}</h1>
				</div>
				<div class="col-12" v-if="this.is_admin">
					<label v-if="this.type == 'wines'" class="mr-1">Vis alle vine</label>
					<label v-else class="mr-1">Vis alle drinks</label>
					<label class="switch">
						<input type="checkbox" v-model="all_wines" @change="something">
						<span class="switch__slider"></span>
					</label>
				</div>
				<div class="col-12 col-sm-5 col-md-3">
					<small class="d-block mb-2">Søg</small>
					<input type="text" @input="searchFunction(current_page)" v-model="search_field">
				</div>
				<div class="col-12 col-sm-5 offset-sm-2 col-md-3 offset-md-6 col-lg-2 offset-lg-7">
					<small class="d-block mb-2 text-right">Visninger pr. side:</small>
					<select class="pr_page" v-model="pr_page" @change="getWines">
						<option value="5">5</option>
						<option value="10">10</option>
						<option value="20">20</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</select>
				</div>
				<div class="col-12">
					<div>
						<div class="row my-row head-row">
							<div class="col-8 col-sm-10">Navn</div>
							<div class="col-1 justify-content-center"><span class="d-none d-sm-inline">Redigér</span></div>
							<div class="col-1 justify-content-center"><span class="d-none d-sm-inline">Slet</span></div>
						</div>
						<div v-if="models.data && (models.data.length > 0)">
							<div class="row my-row body-row" v-for="wine in models.data" :key="wine.id">
								<div class="col-8 col-sm-10">
									<div>{{ wine.name }}</div>
								</div>
								<div class="col-2 col-sm-1 text-center">
									<i v-if="hasPermission('wine-edit')" class="far fa-edit interact" @click="editWine(wine)"></i>
								</div>
								<div class="col-2 col-sm-1 text-center">
									<i v-if="hasPermission('wine-delete')" class="far fa-trash interact" @click="deleteWine(wine)"></i>
								</div>
							</div>
						</div>

						<pagination class="mt-3" :data="models" :show-disabled="true" :limit="limit" @pagination-change-page="getWines" v-if="models.data && (models.data.length > 0)"></pagination>
					</div>
				</div>
			</div>

			<div class="row" v-if="this.wine">
				<winebar-create-wine v-if="this.type == 'wines'" :title="edittitle" :action="this.action" :wine="this.wine" :parentCloseEditOpen="closeEditOpen"></winebar-create-wine>
				<winebar-create-drink v-if="this.type == 'drinks'" :title="edittitle" :action="this.action" :drink="this.wine" :parentCloseEditOpen="closeEditOpen" :wine_bar="wine_bar"></winebar-create-drink>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.getWines();

			var ww = $(window).width();

			if (ww < 768) {
				this.limit = -1;
			}

			this.edittitle = 'Redigér ' + this.headline;
		},
		created() {},
		props: {
            permissions: {
                type: Array,
			},
			type: {
				type: String,
			},
			headline: {
				type: String,
			},
			wine_bar: {
				type: Object,
			},
			wine_menu: {
				type: Object,
			},
			is_admin: {
				type: Boolean,
			},
        },
		data() {
			return {
				edittitle: '',
				models: [],
				current_page: 1,
				limit: 5,
				pr_page: 10,
                wine: null,
				action: null,
				all_wines: false,
				search_field: '',
			}
		},
		methods: {
			getWines(page = 1) {
				this.current_page = page;

				let api = this.all_wines ? `/api/${this.type}` : `/api/winebar-${this.type}/${this.wine_bar.id}/${this.wine_menu.id}`;
				
				axios.get(`${api}?page=${page}&limit=${this.pr_page}`)
					.then((response) => {
						if (page <= 1) {
							 this.models = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.models = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getWines(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
            editWine: function(wine) {
                this.wine = wine;
				this.action = 'edit';
			},
			deleteWine: function(wine) {
                if (confirm("Are you sure?")) {
                    axios.delete(`/api/${this.type}/${wine.id}`)
                        .then((response) => {
                            this.getWines(this.current_page);
                        })
                        .catch((error) => {
                            console.warn(error);
                        });
                }
            },
			closeEditOpen: function() {
				this.wine = '';
			},
			hasPermission: function(permission) {
				return this.permissions.findIndex(x => x.name === permission) > -1;
			},
			something: function() {
				this.getWines(1);
			},
			searchFunction: function(page = 1) {
				this.current_page = page;

				let api = this.all_wines ? `/api/search/all-${this.type}` : `/api/search/${this.type}`;

				axios.post(`${api}?page=${page}&limit=${this.pr_page}`, {
					wine_bar: this.wine_bar.id,
					search: this.search_field
				}).then((res) => {
					this.models = res.data;
				}).catch((err) => {
					console.log(err);
				});
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';
</style>