<template>
	<div>
		<recipe-search @search="getRecipes" :filter_data="filter_data"></recipe-search>

		<div class="row px-3 px-sm-5">
			<div class="row py-3 mt-3 mt-sm-5">
				<div class="col-12 col-sm-6 col-md-4 mb-4" v-for="recipe in recipes.data" :key="recipe.id">
					<div class="wine__item" @click="openDrink(recipe)">
						<div class="wine__item--content">
							<div v-if="recipe.images.length > 0">
								<div class="wine__item--content__image mb-2">
									<img :src="'/storage' + recipe.images[0].path" alt="">
								</div>
							</div>
							<div v-else>
								<div class="wine__item--content__image mb-2">
									<img src="/images/placeholder_etiqette.jpg" alt="">
								</div>
							</div>

							<h2 class="wine__item--content__title">{{ recipe.name }}</h2>
							<div class="wine__item--content__description"></div>
						</div>
					</div>
				</div>

				<div class="col-12 d-flex justify-content-center align-items-center">
					<scroll-loader :loader-method="getRecipes" :loader-disable="disable" :loader-size="55" :loader-distance="200"></scroll-loader>
				</div>
			</div>
		</div>

		<staff-singlerecipe :active="single.active" :recipe="single.recipe" :parentCloseThis="parentCloseThis"></staff-singlerecipe>

		<staff-navigation :active="false" :wine_bar_slug="this.wine_bar.slug"></staff-navigation>
	</div>
</template>

<script>
	export default {
		created() {
			this.getRecipes();
		},
		data() {
			return {
				recipes: [],
				disable: true,
				current_page: 1,
				pageSize: 1000,
				single: {
					active: false,
					recipe: {},
				},
				filter_data: {
					ingredients: [],
					types: [],
				},
			}
		},
		props: ['winemenu', 'wine_bar'],
		methods: {
			getRecipes: function(filters = null) {
				axios.post(`/api/search/winebar-drinks?limit=${this.pageSize}`, {
						filters: filters,
						wine_bar_id: this.wine_bar.id
					})
					.then((response) => {
						if (response.data.data.length > 0) {
							this.recipes = response.data;
							this.populateFilter();
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			openDrink: function(recipe) {
				this.single.active = true;
				this.single.recipe = recipe;
			},
			openDrinkParent: function(recipe) {
				this.single.active = true;
				this.single.recipe = recipe;
			},
			toggleMenuOpen: function() {
				$('body').toggleClass('open--menu');
			},
			parentCloseThis: function() {
				this.single.active = false;
			},
			populateFilter: function() {
				let filter_data = {
					ingredients: [],
					types: [],
				};

				this.recipes.data.forEach((recipe, i) => {
					filter_data.ingredients = filter_data.ingredients.concat(recipe.ingredients);
					filter_data.types.push(recipe.type);
				});

				filter_data.ingredients = this.uniqify(filter_data.ingredients, 'id').sort(this.sortComparer);
				filter_data.types = this.uniqify(filter_data.types, 'id').sort(this.sortComparer);

				filter_data.ingredients.unshift({
					id: 0,
					name: 'Alle ingredienser',
				});

				filter_data.types.unshift({
					id: 0,
					name: 'Alle typer',
				});

				this.$set(this.filter_data, 'ingredients', filter_data.ingredients);
				this.$set(this.filter_data, 'types', filter_data.types);
			},
			uniqify: function(array, property) {
				const result = [];
				const map = new Map();
				for (const item of array) {
					if(!map.has(item[property])){
						map.set(item[property], true);
						result.push({
							id: item.id,
							name: item.name
						});
					}
				}

				return result;
			},
			sortComparer: function(a, b) {
				return a.name.localeCompare(b.name);
			},
		}
	}
</script>

<style lang="scss">
	@import '../../../../sass/utils/_variables.scss';
	@import '../../../../sass/utils/_placeholders.scss';
</style>