<template>
	<div class="rowwrap p-3 p-sm-5">
		<div class="row">
			<div class="col-12 col-md-6 mb-3 mb-sm-0" :class="{'hideclear' : selected.country.id == 0}">
				<v-select :options="filterdata.countries" label="name" @input="search(), enableRegions()" v-model="selected.country"></v-select>
			</div>
			<div class="col-12 col-md-6" :class="{'hideclear' : selected.type.id == 0}">
				<v-select :options="filterdata.types" label="name" v-model="selected.type" @input="search()"></v-select>
			</div>
		</div>

		<transition name="slide">
			<div class="row" v-if="show_advanced">
				<div class="col-12 col-md-6 pt-3" :class="{'hideclear' : selected.regions.id == 0}">
					<v-select :options="filterdata.regions" label="name" v-model="selected.regions" @input="search()"></v-select>
				</div>
				<div class="col-12 col-md-6 pt-3" :class="{'hideclear' : selected.grapes.id == 0}">
					<v-select :options="filterdata.grapes" label="name" v-model="selected.grapes" @input="search()"></v-select>
				</div>
				<div class="col-12 col-md-8 pt-3">
					<input type="text" class="white-background mb-0" placeholder="Søg..." v-model="selected.search" @input="search()">
				</div>
				<div class="col-12 col-md-4 pt-3">
					<button class="front w-100" @click="resetSearch()">Nulstil søgning</button>
				</div>
			</div>
		</transition>

		<div class="toggle_search" @click="toggleAdvancedSearch()">
			<i class="transition far fa-chevron-down" :class="{ 'fa-rotate-180' : show_advanced }"></i>
		</div>
	</div>
</template>

<script>
    export default {
		data() {
			return {
				show_advanced: false,
				countries: [],
				regions: [],
				types: [],
				grapes: [],
				selected: {
					is_default: true,
					country: {
						id: 0,
						name: 'Alle lande',
						regions: []
					},
					type: {
						id: 0,
						name: 'Alle typer',
					},
					regions: {
						id: 0,
						name: 'Alle regioner',
						vineyards: []
					},
					grapes: {
						id: 0,
						name: 'Alle druer',
					},
					price: '',
					search: '',
				},
				disabled: {
					regions: true,
				},
				filterdata: this.filter_data,
			}
		},
		props: ['filter_data'],
		mounted() {
			// this.getCountries();
			// this.getAllRegions();
			// this.getTypes();
			// this.getGrapes(1);
			console.log('MOUNTED, Filterdata', this.filterdata);
		},
		methods: {
			getCountries: function() {
				axios.get('/api/countries?limit=10000')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.countries = response.data;
						}

						this.countries.data.unshift({
							id: 0,
							name: 'Alle lande',
							regions: []
						});
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getTypes: function() {
				axios.get('/api/wine-types')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.types = response.data;
						}

						this.types.data.unshift({
							id: 0,
							name: 'Alle typer',
						});
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getAllRegions: function() {
				axios.get('/api/regions')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.regions = response.data.data;
						}

						this.regions.unshift({
							id: 0,
							name: 'Alle regioner',
							vineyards: []
						});
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			toggleAdvancedSearch: function(){
				jQuery('body').toggleClass('advanced--active');
				if(this.show_advanced){
					this.show_advanced = false;
				} else {
					this.show_advanced = true;
				}
			},
			enableRegions: function(){
				if(this.selected.country.id == 0){
					this.disabled.regions = true;
					this.selected.regions = {
						id: 0,
						name: 'Alle regioner',
						vineyards: []
					};

					this.getAllRegions();
				} else {
					this.disabled.regions = false;
					this.regions = this.selected.country.regions;
				}
			},
			getGrapes(page = 1) {
				this.current_page = page;

				axios.get('/api/grapes?page=' + page + '&limit=10000')
					.then((response) => {
						if (page <= 1) {
							 this.grapes = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.grapes = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getGrapes(page);
								}
							}
						}

						this.grapes.data.unshift({
							id: 0,
							name: 'Alle druer',
						});
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			resetSearch: function() {
				this.selected.is_default = true;
				
				this.selected.country = {
					id: 0,
					name: 'Alle lande',
					regions: []
				};

				this.selected.type = {
					id: 0,
					name: 'Alle typer',
				};

				this.selected.regions = {
					id: 0,
					name: 'Alle regioner',
					vineyards: []
				};

				this.selected.grapes = {
					id: 0,
					name: 'Alle druer',
				};

				this.selected.price = '';
				this.selected.search = '';

				this.search();

				this.getAllRegions();
			},
			search: function() {
				this.checkEmpty();

				this.selected.is_default = 
				((this.selected.country.id == 0) && 
				(this.selected.type.id == 0) &&
				(this.selected.regions.id == 0) &&
				(this.selected.grapes.id == 0) &&
				(this.selected.price == '') &&
				(this.selected.search == ''))
				? true : false;
				
				this.$emit('search', this.selected);

				jQuery('body, html').animate({scrollTop: 0}, 800);
			},
			checkEmpty: function(){
				if(!this.selected.country){
					this.selected.country = {
						id: 0,
						name: 'Alle lande',
						regions: []
					};
				}
				if(!this.selected.type){
					this.selected.type = {
						id: 0,
						name: 'Alle typer',
					};
				}
				if(!this.selected.type){
					this.selected.type = {
						id: 0,
						name: 'Alle typer',
					};
				}
				if(!this.selected.regions){
					this.selected.regions = {
						id: 0,
						name: 'Alle regioner',
						vineyards: []
					};
				}
				if(!this.selected.grapes){
					this.selected.grapes = {
						id: 0,
						name: 'Alle druer',
					};
				}
			}
		}
    }
</script>

<style lang="scss">
	@import '../../../../sass/utils/_variables.scss';
	@import '../../../../sass/utils/_placeholders.scss';
</style>