<template>
    <div>
        <div class="row">
            <div class="col-12 col-md-7 col-lg-8">
                <div v-if="models.data && (models.data.length > 0)">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <select class="pr_page" v-model="pr_page" @change="getModels">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>

                        <div class="col-12 col-md-6">
                            <input placeholder="Søg.." type="text" v-model="search.value" @input="searchModels">
                        </div>
                    </div>

                    <div class="row my-row end">
                        <div class="col-8 col-sm-9 col-md-10">
                            <div class="row head-row">
                                <div v-for="column in columns" :key="column.key" :class="column.class">
                                    {{ capitalize(column.name) }}
                                </div>
                            </div>
                        </div>
                        <div class="col-4 col-sm-3 col-md-2">
                            <div class="row head-row">
                                <div class="col-12">Handlinger</div>
                            </div>
                        </div>
                    </div>
                    
                    <div id="accordion" :class="this.model + '-accordion'" v-if="models.data && (models.data.length > 0)">
                        <div class="row my-row body-row" v-for="model in models.data" :key="model.id">
                            <div class="col-8 col-sm-9 col-md-10" v-if="editing != model.id">
                                <div class="row">
                                    <div class="text-ellipsis" v-for="column in columns" :key="column.key" :class="column.class">{{ column.prepend }}{{ model[column.name] }}</div>
                                </div>
                            </div>
                            <div class="col-8 col-sm-9 col-md-10 w-100 py-4" v-if="editing == model.id" :class="model + '-form'">
                                <form @submit.prevent="updateModel(model)">
                                    <div class="input__wrapper" v-for="field in fields" :key="field.key">
                                        <label :for="'model-' + field.key + '-' + model.id">{{ field.label }}</label>
                                        <textarea v-if="field.inputType == 'textarea'" :class="field.class" v-model="model[field.key]"></textarea>
                                        <input v-else :class="field.class" :type="field.type" v-model="model[field.key]">
                                    </div>

                                    <div v-for="(relation, key) in computedRelations" :key="relation.key">
                                        <label>{{ relation.label }}</label>
                                        <v-select v-if="relation.type == 'single'" :loading="dropdown.loading" label="name" :options="relation.data" v-model="model[relation.attribute[0]]" :class="(key + 1) != relations.length ? '' : ''"></v-select>
                                        <v-select v-if="relation.type == 'multiple'" :loading="dropdown.loading" label="name" :options="relation.data" v-model="model[relation.attribute[0]]" :class="(key + 1) != relations.length ? '' : ''" :multiple="true"></v-select>
                                    </div>

                                    <button type="submit" class="mt-1">Gem</button>
                                </form>
                            </div>
                            <div class="col-4 col-sm-3 col-md-2">
                                <span class="icon__wrap">
                                    <i class="far fa-edit interact" v-if="editing != model.id" @click="editModel(model.id)"></i>
                                    <i class="far fa-times interact" v-if="editing == model.id" @click="editModel(model.id)"></i>
                                </span>
                                <span class="icon__wrap">
                                    <i class="far fa-trash interact" @click="deleteModel(model.id)"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <pagination class="mt-3"
                        v-if="models.data && (models.data.length > 0)"
                        :data="models"
                        :limit="pagination_limit"
                        :show-disabled="true"
                        @pagination-change-page="myPaginator"
                    ></pagination>
                </div>
            </div>

            <div class="col-12 col-md-5 col-lg-4">
				<div class="dark-bg">
                    <div class="input__inside">
                        <h2 class="mb-4">Opret ny {{ this.singular }}</h2>
                        <form id="model-form" class="w-100" @submit.prevent="createModel()">
                            <div class="input__wrapper" v-for="field in fields" :key="field.key">
                                <label :for="'form-model-' + field.key + '-' + model.id">{{ field.label }}</label>
                                <textarea v-if="field.inputType == 'textarea'" :class="field.class" v-model="form_model[field.key]"></textarea>
                                <input v-else :class="field.class" :type="field.type" v-model="form_model[field.key]">
                            </div>

                            <div v-for="(relation, key) in computedRelations" :key="relation.key">
                                <label>{{ relation.label }}</label>
                                <v-select v-if="relation.type == 'single'" :loading="dropdown.loading" label="name" :options="relation.data" v-model="form_model[relation.attribute[0]]" :class="(key + 1) != relations.length ? '' : ''"></v-select>
                                <v-select v-if="relation.type == 'multiple'" :loading="dropdown.loading" label="name" :options="relation.data" v-model="form_model[relation.attribute[0]]" :class="(key + 1) != relations.length ? '' : ''" :multiple="true"></v-select>
                            </div>

                            <button id="create-btn" type="submit" class="mt-1" :class="[ active_createbtn ? 'icon' : 'success' ]">
                                <i class="far fa-check" v-if="active_createbtn == false"></i>
                                <span v-if="active_createbtn == true">
                                    Opret {{ this.singular }}
                                    <i class="far fa-plus"></i>
                                </span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.getModels();

            var ww = $(window).width();

			if (ww < 768) {
				this.pagination_limit = -1;
			}
        },
        created() {},
        data() {
            return {
                models: [],
                current_page: 1,
                input_field: null,
                form_model: {},
                pagination_limit: 5,
                pr_page: 10,
                editing: 0,
                active_createbtn: true,
                search: {
                    searching: false,
                    value: ''
                },
                dropdown: {
                    loading: true
                }
            }
        },
        computed: {
            computedRelations() {
                if (!this.relations) return

                let tempRelations = this.relations
                
                tempRelations.forEach((relation) => {
                    axios.get(`/api/${relation.endpoint}?limit=10000`).then((res) => {
                        relation.data = res.data.data.map(obj => ({
                            id: obj.id,
                            name: ('country' in obj) && (obj.country != null) ? `${obj.name} (${obj.country.name})` : obj.name 
                        }));

                        this.dropdown.loading = false
                    })
                    .catch((error) => {
                        console.warn(error);
                    });
                });

                return tempRelations
            }
        },
        props: {
            model: {
                type: String,
            },
            columns: {
                type: Array,
            },
            fields: {
                type: Array,
            },
            relations: {
                type: Array,
            },
            singular: {
                type: String,
            },
            multiple: {
                type: String,
            }
        },
        methods: {
            myPaginator: function(page = 1) {
                if (this.search.searching) {
                    this.searchModels(page);
                } else {
                    this.getModels(page);
                }
            },
            capitalize: function(value) {
                value = value.toString();

                return value.charAt(0).toUpperCase() + value.slice(1);
            },
            getModels: function(page = 1) {
                this.current_page = page;

                axios.get(`/api/${this.model}?page=${page}&limit=${this.pr_page}`)
                    .then((res) => {
                        if (page <= 1) {
                             this.models = res.data;
                        } else {
                            if (res.data.data.length > 0) {
                                this.models = res.data;
                            } else {
                                page = page - 1;
                                if (page > 0) {
                                    this.getModels(page);
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.warn(error);
                    });
            },
            createModel: function() {
                $(this.relations).each((i, relation) => {
                    if (relation.type == 'single') {
                        this.form_model[relation.attribute[1]] = this.form_model[relation.attribute[0]].id;
                    } else {  
                        this.form_model[relation.attribute[0]] = this.pluck(this.form_model[relation.attribute[0]], 'id');
                    }
                });
                
                axios.post(`/api/${this.model}`, this.form_model)
                    .then((res) => {
                        // this.getModels(this.current_page);
                        this.myPaginator(this.current_page);
                        this.active_createbtn = false;
                        this.form_model = {};

                        setTimeout(() => {
                            this.active_createbtn = true;
                        }, 2000);
                    })
                    .catch((error) => {
                        console.warn(error);
                    });
            },
            updateModel: function(model) {
                $(this.relations).each(function(i, relation) {
                    if (relation.type == 'single') {
                        model[relation.attribute[1]] = model[relation.attribute[0]].id;
                    } else {  
                        model[relation.attribute[0]] = this.pluck(model[relation.attribute[0]], 'id');
                    }
                });
                
                axios.patch(`/api/${this.model}/${model.id}`, model)
                    .then((res) => {
                        // this.getModels(this.current_page);
                        this.myPaginator(this.current_page);
                        this.editing = 0;
                    })
                    .catch((error) => {
                        console.warn(error);
                    });
            },
            deleteModel: function(model) {
                if (confirm("Are you sure?")) {
                    axios.delete(`/api/${this.model}/${model}`)
                        .then((res) => {
                            // this.getModels(this.current_page);
                            this.myPaginator(this.current_page);
                        })
                        .catch((error) => {
                            console.warn(error);
                        });
                }
            },
            searchModels: function(page = 1) {
                if (this.search.value == '') {
                    this.search.searching = false;
                    this.getModels(1);
                    return;
                } else {
                    this.search.searching = true;
                }

                this.current_page = page;
                
                axios.post(`/api/search/${this.model}?page=${page}&limit=${this.pr_page}`, {
                    search: this.search.value
                }).then((res) => {
                    if (page <= 1) {
                        this.models = res.data;
                    } else {
                        if (res.data.data.length > 0) {
                            this.models = res.data;
                        } else {
                            page = page - 1;
                            if (page > 0) {
                                this.searchModels(page);
                            }
                        }
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            pluck: function(array, key) {
                return array.map(o => o[key]);
            },
            editModel: function(model_id) {
                if(this.editing == 0 || this.editing != model_id){
                    this.editing = model_id;
                } else {
                    this.editing = 0;
                }
            }
        }
    }
</script>