<template>
	<div class="rowwrap p-3 p-sm-5">
		<div class="row">
			<div class="col-12 col-md-6 mb-3 mb-sm-0" :class="{'hideclear' : selected.ingredients.id == 0}">
				<v-select :options="filterdata.ingredients" label="name" @input="search()" v-model="selected.ingredients"></v-select>
			</div>
			<div class="col-12 col-md-6" :class="{'hideclear' : selected.type.id == 0}">
				<v-select :options="filterdata.types" label="name" v-model="selected.type" @input="search()"></v-select>
			</div>
		</div>

		<transition name="slide">
			<div class="row" v-if="show_advanced">
				<div class="col-12 col-md-8 pt-3">
					<input type="text" class="white-background mb-0" placeholder="Søg..." v-model="selected.text" @input="search">
				</div>
				<div class="col-12 col-md-4 pt-3">
					<button class="front w-100" @click="resetSearch()">Nulstil søgning</button>
				</div>
			</div>
		</transition>

		<div class="toggle_search" @click="toggleAdvancedSearch()">
			<i class="transition far fa-chevron-down" :class="{ 'fa-rotate-180' : show_advanced }"></i>
		</div>
	</div>
</template>

<script>
    export default {
		data() {
			return {
				show_advanced: false,
				selected: {
					is_default: true,
					text: '',
					ingredients: {
						id: 0,
						name: 'Alle ingredienser',
					},
					type: {
						id: 0,
						name: 'Alle typer',
					},
				},
				filterdata: this.filter_data
			}
		},
		mounted() {},
		props: ['filter_data'],
		methods: {
			toggleAdvancedSearch: function(){
				if(this.show_advanced){
					this.show_advanced = false;
				} else {
					this.show_advanced = true;
				}
			},
			search: function() {
				this.checkEmpty();

				this.selected.is_default = 
				((this.selected.ingredients.id == 0) && 
				(this.selected.type.id == 0) &&
				(this.selected.text == ''))
				? true : false;

				if (this.selected.is_default) {
					this.$emit('search', null);
				} else {
					let filter = {};
					
					if (this.selected.ingredients.id != 0) {
						filter.ingredients = this.selected.ingredients;
					}

					if (this.selected.type.id != 0) {
						filter.type = this.selected.type;
					}

					if (this.selected.text != '') {
						filter.text = this.selected.text;
					}

					this.$emit('search', filter);
				}
			},
			checkEmpty: function(){
				if(!this.selected.ingredients){
					this.selected.ingredients = {
						id: 0,
						name: 'Alle ingredienser',
						regions: []
					};
				}
				if(!this.selected.type){
					this.selected.type = {
						id: 0,
						name: 'Alle typer',
					};
				}
			},
			resetSearch: function() {
				this.selected.is_default = true;
				this.selected.text = '';
				this.selected.ingredients = {
					id: 0,
					name: 'Alle ingredienser',
				};
				
				this.selected.type = {
					id: 0,
					name: 'Alle typer',
				};

				this.$emit('search', null);
			}
		}
    }
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';
</style>