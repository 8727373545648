<template>
	<div class="row steps__row">
		<div class="steps col-12">
			<div class="steps__box--wrap steps__box--wrap_extras steps__box--wrap_extras--first done d-sm-none">
				<div class="steps__box done">
					<span class="steps__box--number">...</span>
					<span class="steps__box--check">
						<i class="far fa-check"></i>
					</span>
				</div>
				<div class="steps__box--text">
					<i class="far fa-chevron-down"></i>
				</div>
			</div>
			<div v-for="(step, k) in this.steps" :key="k" class="steps__box--wrap" :class="{ 'active' : isActiveStep(k), 'done' : isDoneStep(k) }" @click="goToStep(k + 1)">
				<div class="steps__box" :class="{ 'active' : isActiveStep(k), 'done' : isDoneStep(k) }">
					<span class="steps__box--number">{{ k+1 }}</span>
					<span class="steps__box--check">
						<i class="far fa-check"></i>
					</span>
				</div>
				<div class="steps__box--text" :class="{'cellar' : type == 'drink'}">{{ step }}</div>
			</div>
			<div class="steps__box--wrap steps__box--wrap_extras steps__box--wrap_extras--final d-sm-none">
				<div class="steps__box">
					<span class="steps__box--number">...</span>
					<span class="steps__box--check">
						<i class="far fa-check"></i>
					</span>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
    export default {
		props: [
			'steps',
			'activestep',
			'goToStepParent',
			'action',
			'type'
		],
		data() {
			return {
				donesteps: 0,
			}
		},
		mounted() {},
		methods: {
			isActiveStep: function(k){
				if(k == (this.activestep - 1)){
					return true;
				} else {
					return false;
				}
			},
			isDoneStep: function(k){
				if(k < (this.activestep - 1)){
					this.donesteps = (k + 1);
					return true;
				} else if( this.action == 'edit') {
					return true;
				} else {
					return false;
				}
			},
			goToStep: function(k){
				this.goToStepParent(k);
			}
		}
    }
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';

	.steps {
		display: flex;
		overflow: hidden;
		justify-content: space-between;

		&__row {
			position: relative;
			margin-top: 15px;
    		margin-bottom: 45px;

			&:before {
				content:"";
				background-color: $lightgray-color;
				width:15px;
				height: 30px;
				position: absolute;
				top:0;
				left:0;
			}
		}

		&:before {
			@extend %line;
			content: "";
			top: 21px;
			width: calc(100% - 50px);
			left: 15px;
		}

		&__box {
			height: 40px;
			width: 40px;
			padding: 0 5px;
			line-height: 40px;
			text-align: center;
			transition: .3s ease-out;
			border: 2px solid $black-color;
			background-color: $white-color;

			&.done,
			&.active {
				position: relative;
				color: $white-color;
				border-color: $success-color;
				background-color: $success-color;

				&:before {
					@extend %line;
					content: "";
					right: 100%;
					left: auto;
					top: 19px;
					width: 100vw;
					background-color: $success-color;
				}
			}
			
			&--number,
			&--check {
				height: 36px;
				line-height: 36px;
			}

			&--number {
				display: block;
				font-size: 1.125rem;
				font-weight: 500;

				.done:not(.active) & {
					display: none;
				}
			}
			
			&--check {
				display: none;
				font-size: 20px;
				text-align: center;
				
				.done:not(.active) & {
					display: block;
				}
			}

			&--text {
				left: 50%;
				display: block;
				font-weight: 500;
				padding-top: 5px;
				width: fit-content;
				position: relative;
				color: $black-color;
				font-size: 0.875rem;
				white-space: nowrap;
				transform: translateX(-50%);

				.active &,
				.done:not(.active) & {
					color: $success-color;
				}
			}

			&--wrap {
				width: 40px;
				position: relative;
				display: none;

				&.active,
				&:first-child,
				&:last-child {
					display: block;
				}

				@media(min-width: $sm){
					display: block;
				}

				&.done:not(.active) {
					cursor: pointer;
				}

				&:nth-last-child(2) {
					.steps__box--text {
						&.cellar {
							padding-right: 22px;
						}
					}
				}
			}
		}
	}
</style>