/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//import createWine from './components/createWine.vue';
//import wineFlow from './components/wineFlow.vue';

import vSelect from "vue-select";
import ScrollLoader from 'vue-scroll-loader';
import draggable from 'vuedraggable';
import { Cropper } from 'vue-advanced-cropper';

Vue.component('v-select', vSelect);
Vue.component('draggable', draggable);

Vue.use(ScrollLoader);
// Vue.component('scroll-loader', ScrollLoader);

Vue.filter('numberformat', function (value) {
	var num = new Intl.NumberFormat('de-DE').format(value);
	return num;
});

Vue.filter('priceformat', function (value) {
	var num = new Intl.NumberFormat('da-DK', {style: 'currency', currency: 'DKK'}).format(value);
	return num;
});

Vue.filter('formatQuantity', function (value) {
	return value.replace(',', '.');
});

Vue.component('cropimage', require('./components/vendor/AdvancedCropImage.vue'));
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('image-uploader', require('./components/ImageUploader.vue').default);

/**
 * Admin
 */
Vue.component('roles', require('./components/admin/Roles.vue').default);
Vue.component('roles-edit', require('./components/admin/RolesEdit.vue').default);
Vue.component('roles-show', require('./components/admin/RolesShow.vue').default);
Vue.component('roles-create', require('./components/admin/RolesCreate.vue').default);
Vue.component('create-winebar', require('./components/admin/CreateWineBar.vue').default);
Vue.component('waiting-list', require('./components/admin/WaitingList.vue').default);
Vue.component('crud-list-test', require('./components/admin/CrudListTest.vue').default);

/**
 * Frontend
 */

Vue.component('recipe-search', require('./components/frontend/RecipeSearch.vue').default);
Vue.component('frontend-navigation', require('./components/frontend/FrontendNavigation.vue').default);
Vue.component('winemenu', require('./components/frontend/WineMenu.vue').default);
Vue.component('advanced-search', require('./components/frontend/AdvancedSearch.vue').default);
Vue.component('single-wine', require('./components/frontend/SingleWine.vue').default);
Vue.component('single-course', require('./components/frontend/SingleCourse.vue').default);
Vue.component('terms', require('./components/frontend/Terms.vue').default);
Vue.component('send-link', require('./components/frontend/SendLink.vue').default);
Vue.component('foodmenu', require('./components/frontend/FoodMenu.vue').default);
Vue.component('staff-navigation', require('./components/frontend/staff/Navigation.vue').default);
Vue.component('staff-recipemenu', require('./components/frontend/staff/RecipeMenu.vue').default);
Vue.component('staff-drinkmenu', require('./components/frontend/staff/DrinkMenu.vue').default);
Vue.component('staff-singledrink', require('./components/frontend/staff/SingleDrink.vue').default);
Vue.component('staff-singlerecipe', require('./components/frontend/staff/SingleRecipe.vue').default);
Vue.component('staff-winemenu', require('./components/frontend/staff/WineMenu.vue').default);
Vue.component('staff-foodmenu', require('./components/frontend/staff/FoodMenu.vue').default);
Vue.component('private-navigation', require('./components/frontend/private/Navigation.vue').default);
Vue.component('private-winemenu', require('./components/frontend/private/WineMenu.vue').default);
Vue.component('private-drinkmenu', require('./components/frontend/private/DrinkMenu.vue').default);
Vue.component('private-recipemenu', require('./components/frontend/private/RecipeMenu.vue').default);
Vue.component('private-single-wine', require('./components/frontend/private/SingleWine.vue').default);
Vue.component('private-single-drink', require('./components/frontend/private/SingleDrink.vue').default);
Vue.component('private-advanced-search', require('./components/frontend/private/AdvancedSearch.vue').default);

/**
 * Winebar
 */

Vue.component('winebar-grape-list', require('./components/winebar/GrapeList.vue').default);
Vue.component('winebar-crud-list', require('./components/winebar/CrudList.vue').default);
Vue.component('winebar-winemenu-editor', require('./components/winebar/WineMenuEditor.vue').default);
Vue.component('winebar-drinkmenu-editor', require('./components/winebar/DrinkMenuEditor.vue').default);
Vue.component('winebar-foodmenu-editor', require('./components/winebar/FoodMenuEditor.vue').default);
Vue.component('winebar-foodmenu', require('./components/winebar/FoodMenu.vue').default);
Vue.component('winebar-create-wine', require('./components/winebar/CreateWine.vue').default);
Vue.component('winebar-flow-steps', require('./components/winebar/FlowSteps.vue').default);
Vue.component('winebar-thankyou', require('./components/winebar/ThankYou.vue').default);
Vue.component('winebar-create-drink', require('./components/winebar/CreateDrink.vue').default);
Vue.component('winebar-wine-course-list', require('./components/winebar/WineCourseList.vue').default);
Vue.component('winebar-wine-course-form', require('./components/winebar/WineCourseForm.vue').default);
Vue.component('winebar-food-menu-list', require('./components/winebar/FoodMenuList.vue').default);
Vue.component('winebar-wine-list', require('./components/winebar/WineList.vue').default);
Vue.component('winebar-mail-list', require('./components/winebar/MailList.vue').default);
Vue.component('winebar-mail-edit', require('./components/winebar/MailEdit.vue').default);
Vue.component('winebar-slug-edit', require('./components/winebar/SlugEdit.vue').default);

Vue.component('payment-plan-overview', require('./components/PaymentPlans.vue').default);
Vue.component('payment-plan-overview-new', require('./components/PaymentPlans-new.vue').default);
Vue.component('payment-plan', require('./components/PaymentPlan.vue').default);

/**
 * Winecellar
 */
Vue.component('winecellar-grape-list', require('./components/winecellar/GrapeList.vue').default);
Vue.component('winecellar-crud-list', require('./components/winecellar/CrudList.vue').default);
Vue.component('winecellar-winemenu-editor', require('./components/winecellar/WineMenuEditor.vue').default);
Vue.component('winecellar-drinkmenu-editor', require('./components/winecellar/DrinkMenuEditor.vue').default);
Vue.component('winecellar-foodmenu-editor', require('./components/winecellar/FoodMenuEditor.vue').default);
Vue.component('winecellar-foodmenu', require('./components/winecellar/FoodMenu.vue').default);
Vue.component('winecellar-create-wine', require('./components/winecellar/CreateWine.vue').default);
Vue.component('winecellar-flow-steps', require('./components/winecellar/FlowSteps.vue').default);
Vue.component('winecellar-thankyou', require('./components/winecellar/ThankYou.vue').default);
Vue.component('winecellar-create-drink', require('./components/winecellar/CreateDrink.vue').default);
Vue.component('winecellar-wine-course-list', require('./components/winecellar/WineCourseList.vue').default);
Vue.component('winecellar-wine-course-form', require('./components/winecellar/WineCourseForm.vue').default);
Vue.component('winecellar-food-menu-list', require('./components/winecellar/FoodMenuList.vue').default);
Vue.component('winecellar-wine-list', require('./components/winecellar/WineList.vue').default);
Vue.component('winecellar-mail-list', require('./components/winecellar/MailList.vue').default);
Vue.component('winecellar-mail-edit', require('./components/winecellar/MailEdit.vue').default);


Vue.component('users', require('./components/user/Index.vue').default);
Vue.component('user-edit', require('./components/user/Edit.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


const app = new Vue({
	el: '#app',
	components: {}
});