<template>
	<transition name="move-up-singlewine">
		<div class="single__wine" v-if="this.active">
			<div class="close_btn" @click="closeThis()">
				<i class="far fa-times"></i>
			</div>

			<div class="container-fluid">
				<div class="rowwrap position-relative p-3 p-sm-5">
					<div class="row">
						<div v-if="this.recipe.images.length > 0" class="d-flex">
							<div v-for="image in this.recipe.images" :key="image.id" class="col-12 col-sm single__image--col">
								<div class="single__image">
									<img :src="'/storage' + image.path" alt="">
								</div>
							</div>
						</div>
						<div v-else>
							<div class="col-12 col-sm single__image--col">
								<div class="single__image">
									<img src="/images/placeholder_etiqette.jpg" alt="">
								</div>
							</div>
						</div>
						<div class="col-12 col-sm">
							<h1 class="mt-4">{{ this.recipe.name }}</h1>
							<h2 class="slim mt-3">
								<span class="d-block d-sm-inline">{{ this.recipe.type.name }}</span>
							</h2>
						</div>
					</div>
				</div>

				<div class="row px-3 px-sm-5">
					<div class="row py-4">
						<div class="col-12 col-sm-8">
							<div v-if="this.recipe.description">
								<h2 class="primary">Om drinken</h2>
								<div class="mb-3">{{ this.recipe.description }}</div>
							</div>
							<div v-if="this.recipe.free_text">
								<h2 class="primary">Opskrift</h2>
								<div class="mb-3" v-html="convertFreetext()"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		mounted() {},
		created() {},
		data() {
			return {}
		},
		props: ['recipe', 'active', 'parentCloseThis'],
		methods: {
			closeThis: function() {
				this.parentCloseThis();
			},
			convertFreetext: function() {
				var freetext_first = this.recipe.free_text,
					freetext_html = '';

				var freetext = freetext_first.split("\n").join("<br>");
				freetext = freetext.replace(']', '[');
				freetext = freetext.split('[');

				var f = 1;
				jQuery.each(freetext, function(i, v){
					if(f % 2 == 0){
						var arr = v.split('|');
						var id = arr[0];
						var text = arr[1];

						freetext_html += '<span class="fakelink recipe__link" data-id="' + id + '">' + text + '</span>';
					} else {
						freetext_html += v;
					}

					f += 1;
				});

				return freetext_html.trim();
			},
		}
	}
</script>

<style lang="scss">
	@import '../../../../sass/utils/_variables.scss';
	@import '../../../../sass/utils/_placeholders.scss';
</style>