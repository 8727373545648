<template>
	<div>
		<h3 class="mb-3">Betalingsplan</h3>
		<div class="payment_plans mb-3">
			<payment-plan :plan="product" @selectplan="setSelectedPlan" @updatesubscription="updateSubscription" v-for="(product, p) in temp_products" :key="p"></payment-plan>
		</div>
	</div>
</template>

<script>
	export default {
		created() {
			this.setSelectedPlan();
		},
		props: [
			'permissions',
			'products',
			'subscribed_to',
			'user',
		],
		data() {
			return {
				temp_products: this.products,
				temp_subscribed_to: this.subscribed_to,
			}
		},
		methods: {
			setSelectedPlan: function(id = null) {
				let setInitial = false;

				if (id == null) {
					setInitial = true;
				}

				id = id != null ? id : this.temp_subscribed_to;

				this.temp_products.forEach((p, i) => {
					this.$set(this.temp_products[i], 'selected', false);

					if (setInitial) {
						this.$set(this.temp_products[i], 'subscribed_to', false);
					}

					if (p.id == id) {
						this.$set(this.temp_products[i], 'selected', true);

						if (setInitial) {
							this.$set(this.temp_products[i], 'subscribed_to', true);
						}
					}
				});
			},
			updateSubscription: function(id) {
				$('body').append('<div class="loading">dadfasdfgadfs</div>');
				axios.patch('/api/customer-subscription', {
					user: this.user,
					plan_id: id
				})
				.then((response) => {
					$('.loading').remove();
					console.log(response.data);
					this.temp_subscribed_to = response.data.plan_id;

					this.setSelectedPlan();
				})
				.catch((error) => {
					$('.loading').remove();
					console.warn(error);
				});
			},
			pluck(array, key) {
				return array.map(o => o[key]);
			},
		}
    }
</script>