<template>
	<div>
		<h3 class="mb-3">Betalingsplan</h3>

		<div class="payment_plans mb-3">
			<div v-for="(plan, k) in payment_plans" class="plan" :key="k" :class="{ 'active' : plan.active }">
				<div class="check" v-if="plan.active">
					<i class="far fa-check"></i>
				</div>
				<h2>{{ plan.title }}</h2>

				<ul class="content">
					<li v-for="(content, c) in plan.content" :key="c">{{ content }}</li>
				</ul>

				<div class="pricing">
					<div class="price" v-if="!plan.price.yearly && !plan.price.monthly">{{ plan.price }}</div>
					<div class="price secondary" v-if="!plan.price.yearly && !plan.price.monthly">&nbsp;</div>
					
					<div class="price" v-if="plan.price.monthly">{{ plan.price.monthly }},- / måned</div>
					<div class="price secondary" v-if="plan.price.monthly && plan.price.yearly">(Eller {{ plan.price.yearly }},- / år)</div>
					<div class="price secondary" v-if="plan.price.monthly && !plan.price.yearly">&nbsp;</div>

					<div class="price" v-if="!plan.price.monthly && plan.price.yearly">{{ plan.price.yearly }},- / år</div>
					<div class="price secondary" v-if="!plan.price.monthly && plan.price.yearly">&nbsp;</div>
				</div>

				<div class="actions">
					<button @click="selectplan(plan, k)" v-if="!plan.active && ((!plan.price.yearly && !plan.price.monthly) || (plan.price.yearly && !plan.price.monthly) || (!plan.price.yearly && plan.price.monthly))">Vælg</button>
					<button @click="chooseplan(k)" v-if="!plan.active && plan.price.yearly && plan.price.monthly">Vælg</button>
					<button v-if="plan.active" class="primary icon">Valgt <i class="fas fa-check"></i></button>

					<div class="hidden" v-if="plan.chosen">
						<select name="action_payment" id="action_payment" v-if="plan.price.yearly && plan.price.monthly" v-model="plan.selected_price">
							<option value="monthly">Månedligt ({{ plan.price.monthly }},-)</option>
							<option value="yearly">Årligt ({{ plan.price.yearly }},-)</option>
						</select>

						<button class="primary" @click="selectplan(plan, k)">Bekræft valg</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: [
			'permissions',
		],
		data() {
			return {
				payment_plans: [
					{
						title: 'Basis',
						price: 'Gratis',
						content: [
							'Op til 3 vine',
							'Op til 3 drinks',
						],
						active: true,
						chosen: false,
						selected_price: 'free',
					},
					{
						title: 'Personlig',
						price: {
							monthly: 69,
						},
						content: [
							'Op til 10 vine',
							'Op til 10 drinks',
							'Højest 2 brugere',
						],
						active: false,
						chosen: false,
						selected_price: 'monthly',
					},
					{
						title: 'Enterprise',
						price: {
							yearly: 999,
						},
						content: [
							'Op til ∞ vine',
							'Op til ∞ drinks',
							'Højest 10 brugere',
							'Supporthjælp',
						],
						active: false,
						chosen: false,
						selected_price: 'yearly',
					},
				],
				selected : {},
				all_permissions: [],
			}
		},
        mounted() {
			this.getPermissions();
			this.selected = this.payment_plans[0];
		},
		methods: {
			getPermissions() {
				axios.get('/api/permissions')
					.then((response) => {
						this.all_permissions = response.data.data;
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			hasPermission: function(permission) {
				return this.permissions.findIndex(x => x.name === permission) > -1;
			},
			chooseplan: function(k){
				var _this = this;

				jQuery.each(this.payment_plans, function(index, val){
					if(index == k){
						_this.payment_plans[k].chosen = (_this.payment_plans[k].chosen === true) ? false : true;
					} else {
						_this.payment_plans[index].chosen = false;
					}
				});
			},
			selectplan: function(plan, k){
				var _this = this;

				this.selected = plan;

				jQuery.each(this.payment_plans, function(index, val){
					if(index == k){
						_this.payment_plans[k].active = true;
						_this.payment_plans[k].chosen = false;
					} else {
						_this.payment_plans[index].active = false;
					}
				});
			}
		}
    }
</script>