<template>
    <div>
        <div class="row mb-3">
            <form id="model-form" class="w-100" v-on:submit.prevent="createModel()">
                <label for="name">Name</label>
                <input type="text" v-bind:id="this.model + '-name'" class="form-control">
                <button id="create-btn" class="button btn-success mt-1">Create</button>
            </form>
        </div>

        <div v-if="models.data && (models.data.length > 0)">
            <div class="row my-row head-row">
                <div v-for="column in columns" :key="column.key" v-bind:class="column.class">
                        {{ cappitalize(column.name) }}
                    </div>
                <div class="col-1">Actions</div>
            </div>
            <div id="accordion" v-bind:class="this.model + '-accordion'" v-if="models.data && (models.data.length > 0)">
                <div class="row my-row body-row" v-for="model in models.data" :key="model.id">
                    <div v-for="column in columns" :key="column.key" v-bind:class="column.class">
                        {{ model[column.name] }}
                    </div>
                    <div class="col-1">
                        <i class="fad fa-edit text-warning interact" data-toggle="collapse" v-bind:data-target="'#collapse-' + model.id" aria-expanded="false" v-bind:aria-controls="'collapse-' + model.id"></i>
                        <i class="fad fa-trash text-danger interact" v-on:click="deleteModel(model.id)"></i>
                    </div>
                    <div class="col-10 offset-1 collapse w-100" v-bind:class="model + '-form'" v-bind:id="'collapse-' + model.id" data-parent="#accordion">
                        <hr>
                        <div v-bind:id="'form-' + model.id" v-for="field in fields" :key="field.key">
                            <label v-bind:for="'model-' + field.key + '-' + model.id">{{ field.name }}</label>
                            <input v-if="field.inputType == 'input'" v-bind:class="field.class" v-bind:type="field.type" v-bind:value="model[field.key]" v-bind:name="field.key">
                            <input v-else-if="field.inputType == 'number'" v-bind:class="field.class" v-bind:type="field.type" v-bind:value="model[field.key]" v-bind:name="field.key">
                            <textarea v-else-if="field.inputType == 'textarea'" v-bind:class="field.class" v-bind:value="model[field.key]" v-bind:name="field.key"></textarea>
                        </div>
                        <button class="button btn-success mt-1" v-on:click="updateModel(model.id)">Save</button>
                    </div>
                </div>
            </div>

            <pagination class="mt-3" :data="models" :show-disabled="true" @pagination-change-page="getModels" v-if="models.data && (models.data.length > 0)"></pagination>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.getModels();
        },
        created() {},
        data() {
            return {
                models: [],
                current_page: 1,
            }
        },
        props: {
            model: {
                type: String,
            },
            columns: {
                type: Array,
            },
            fields: {
                type: Array,
            }
        },
        methods: {
            cappitalize(value) {
                value = value.toString();

                return value.charAt(0).toUpperCase() + value.slice(1);
            },
            getModels(page = 1) {
                this.current_page = page;

                axios.get('/api/' + this.model + '?page=' + page)
                    .then((response) => {
                        if (page <= 1) {
                             this.models = response.data;
                        } else {
                            if (response.data.data.length > 0) {
                                this.models = response.data;
                            } else {
                                page = page - 1;
                                if (page > 0) {
                                    this.getModels(page);
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.warn(error);
                    });
            },
            createModel() {
                var self = this;
                var $name = $('#' + this.model + '-name');
                
                axios.post('/api/' + this.model, {
                        name: $name.val()
                    })
                    .then(function (response) {
                        self.getModels(self.current_page);
                        $name.val('');
                        $('#create-btn').html('<i class="fad fa-check"></i>');
                        setTimeout(function() {
                                $('#create-btn').html('Create');
                        }, 2000);
                    })
                    .catch(function (error) {
                        console.warn(error);
                    });
            },
            updateModel(model) {
                var self = this;

                
                axios.patch('/api/' + this.model + '/' + model, {
                        name: $('#model-name-' + model).val()
                    })
                    .then(function (response) {
                        self.getModels(self.current_page);
                        $('#collapse-' + model).collapse('hide');
                    })
                    .catch(function (error) {
                        console.warn(error);
                    });
            },
            deleteModel(model) {
                if (confirm("Are you sure?")) {
                    var self = this;
                    
                    axios.delete('/api/' + this.model + '/' + model)
                        .then(function (response) {
                            self.getModels(self.current_page);
                        })
                        .catch(function (error) {
                            console.warn(error);
                        });
                }
            }
        }
    }
</script>