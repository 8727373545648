<template>
	<div>
		<div class="row">
			<div class="col-12">
				<h1>Madmenuer</h1>
			</div>
			<div class="col-12 col-xl-7">
				<div class="row">
					<div class="col-12">
						<div class="d-inline-block">
							<draggable v-model="menus" group="menus" ghost-class="ghost" @start="drag=true" @end="drag=false;editsmade=true;">
								<div class="button__tab--wrap" v-for="(menu, k) in menus" :key="k">
									<div class="button__tab" :class="{ 'primary' : k == active_menu }" @click="active_menu = k;active_content = 0">
										{{ menu.name }}
									</div>
									<div class="button__tab--remove icon__button round fast" @click="removeTab(k)"><i class="far fa-minus"></i></div>
								</div>
							</draggable>
						</div>

						<div class="icon__button primary round" @click="addMenuPoint()">
							<i class="far fa-plus"></i>
						</div>
					</div>
				</div>
				<div class="row mt-5">
					<div class="col-12">
						<div class="input__wrapper">
							<label for="foodmenu_name">Overskrift</label>
							<input type="text" name="foodmenu_name" @input="editsmade = true;" id="foodmenu_name" v-model="menus[active_menu].name">
						</div>
						
						<div class="input__wrapper">
							<label for="foodmenu_description">Introduktion</label>
							<textarea type="text" name="foodmenu_description" @input="editsmade = true;" id="foodmenu_description" v-model="menus[active_menu].description"></textarea>
						</div>
					</div>
				</div>

				<div class="row mt-3">
					<div class="col-12">
						<h2>Produkter</h2>
					</div>
				</div>
				<div class="row mt-3">
					<div class="col-12">
						<div class="d-inline-block float-left">
							<draggable v-model="menus[active_menu].content" group="menu_content" ghost-class="ghost" @start="cdrag=true" @end="cdrag=false;editsmade=true;">
								<!--<transition-group name="fade" tag="div">-->
									<div class="button__tab--wrap" v-for="(content, i) in menus[active_menu].content" :key="i">
										<div class="button__tab button__tab--small" :class="{ 'primary' : i == active_content }" @click="active_content = i;">{{ content.name }}</div>
										<div class="button__tab--remove icon__button round fast" @click="removeContent(i)"><i class="far fa-minus"></i></div>
									</div>
								<!--</transition-group>-->
							</draggable>
						</div>

						<div class="icon__button primary round" @click="addMenuContent()">
							<i class="far fa-plus"></i>
						</div>
					</div>
				</div>

				<div class="row mt-3">
					<div class="col-12">
						<div class="input__wrapper">
							<label for="foodmenu_content_name">Produktliste-overskrift</label>
							<input type="text" @input="editsmade = true;" v-model="menus[active_menu].content[active_content].name">
						</div>
						
						<div class="input__wrapper">
							<label for="foodmenu_content_description">Produktliste-overskrifts parantes</label>
							<input type="text" @input="editsmade = true;" v-model="menus[active_menu].content[active_content].description">
						</div>
					</div>
				</div>
				
				<div class="row mt-3">
					<div class="col-11 col-sm-12 input__inline">
						<div class="input__wrapper">
							<input type="text" style="height: 0 !important;">
							<label for="foodmenu_products">Produkt-navn</label>
						</div>
						<div class="input__wrapper half">
							<input type="text" style="height: 0 !important;">
							<label for="foodmenu_products">Pris</label>
						</div>
					</div>
					<div class="col-12">
						<draggable v-model="menus[active_menu].content[active_content].products" class="row" group="products" ghost-class="ghost" @start="pdrag=true" @end="pdrag=false;editsmade=true;">
							<div class="col-11 col-sm-12 input__inline" v-for="(product, u) in menus[active_menu].content[active_content].products" :key="u">
								<div class="input__wrapper mr-3 mr-md-0">
									<input @input="editsmade = true;" type="text" v-model="product.name">
								</div>
								<div class="input__wrapper std half">
									<div class="append">kr.</div>
									<input @input="editsmade = true;" type="text" v-model="product.price">
									<transition name="fade">
										<div v-if="menus[active_menu].content[active_content].products.length > 1" class="remove" @click="removeProduct(u)"><i class="far fa-times"></i></div>
									</transition>
								</div>
								<transition name="fade">
									<div v-if="menus[active_menu].content[active_content].products.length > 1" class="move--burger move--hover">
										<span></span>
										<span></span>
									</div>
								</transition>
							</div>
						</draggable>
					</div>
					<div class="col-12" id="add_new_product">
						<button class="icon" @click="addNewProduct()">
							Tilføj produkt
							<i class="far fa-plus"></i>
						</button>
					</div>
				</div>
			</div>
			<div class="col-12 col-xl-5 mt-5 mt-xl-0">
				<div class="row">
					<div class="col-12 d-flex align-items-end justify-content-between mb-3">
						<h3>Forhåndsvisning</h3>

						<button class="primary mt-3 mt-md-0" :disabled="!editsmade" :class="{ 'success icon' : edit_success }" @click="save()">
							<span v-if="!edit_success">Gem ændringer</span>
							<span v-if="edit_success">Ændringer gemt <i class="far fa-check"></i></span>
						</button>
					</div>
					<div class="col-12">
						<div class="preview tablet--portrait">
							<div class="preview__wrap">
								<foodmenu :foodmenu="{content: JSON.stringify({content: menus})}" :hidenav="true"></foodmenu>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.menus = JSON.parse(this.food_menu.content).content;
		},
		created() {},
		data() {
			return {
				content_id: -1,
				active_menu: 0,
				active_content: 0,
				editsmade: false,
				edit_success: false,
				menus: [
					{
						"name": "",
						"description": "",
						"content": [
							{
								"name": "",
								"description": "",
								"products": [
									{
										"name": "",
										"price": 0
									},
									{
										"name": "",
										"price": 0
									},
									{
										"name": "",
										"price": 0
									},
									{
										"name": "",
										"price": 0
									},
								]
							}
						]
					}
				],
			}
		},
		props: {
            food_menu: {
                type: Object,
            }
        },
		methods: {
			addNewProduct: function(){
				var new_prod = {
					name: '',
					price: ''
				};

				this.menus[this.active_menu].content[this.active_content].products.push(new_prod);

				jQuery('body, html').animate({'scrollTop': jQuery('#add_new_product').offset().top - 350}, 300);
			},
			addMenuPoint: function(){
				var newMenu = {
					name: 'Nyt punkt',
					description: '',
					content: [
						{
							name: '',
							description: '',
							products: [
								{
									name: '',
									price: ''
								},
							],
						}
					]
				};

				this.active_content = 0;
				this.menus.push(newMenu);
				this.active_menu = this.menus.length - 1;
				this.editsmade = true;
			},
			addMenuContent: function(){
				var newMenuContent = {
					name: 'Nyt indhold',
					description: '',
					products: [
						{
							name: '',
							price: ''
						},
					],
				};

				this.menus[this.active_menu].content.push(newMenuContent);
				this.active_content = this.menus[this.active_menu].content.length - 1;
				this.editsmade = true;
			},
			removeTab: function(k) {
				this.active_menu = 0;
				this.menus.splice(k, 1);
				this.editsmade = true;
			},
			removeContent: function(i) {
				this.active_content = 0;
				this.menus[this.active_menu].content.splice(i, 1);
				this.editsmade = true;
			},
			removeProduct: function(u) {
				this.menus[this.active_menu].content[this.active_content].products.splice(u, 1);
				this.editsmade = true;
			},
			save: function() {
				var self = this;
				let fmenu = {content: []};
				fmenu.content = this.menus;
				fmenu = JSON.stringify(fmenu);

				axios.patch('/api/foodmenus/' + this.food_menu.id, {
					content: fmenu
				}).then((response) => {
					self.edit_success = true;

						setTimeout(function(){
							self.editsmade = false;
							self.edit_success = false;
						}, 1500);
                    })
                    .catch((error) => {
                        console.warn(error);
                    });
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';
</style>