<template>
	<div>
		<div class="row" id="wine-course-list" v-if="!showModal">
			<div class="col-12 col-md-6">
				<h1>Mine Vinmenuer</h1>
			</div>
			<div class="col-12 col-md-6">
				<a v-if="hasPermission('wine-course-create')" class="button btn-primary text-white icon float-right" @click="createWineCourse">
					Opret vinmenu
					<i class="far fa-chevron-right"></i>
				</a>
			</div>

			<div class="col-12 col-lg-8">
				<div class="row my-row head-row">
					<div class="col-6 col-md-3">Titel</div>
					<div class="col-5 d-none d-md-flex">Undertitel</div>
					<div class="col-1 d-none d-md-flex">Pris</div>
					<div class="col-2 col-md-1 justify-content-center">Status</div>
					<div class="col-4 col-md-2 justify-content-center">Handlinger</div>
				</div>
				<div v-if="winecourses.data && (winecourses.data.length > 0)">
					<div class="row my-row body-row" v-for="winecourse in winecourses.data" :key="winecourse.id">
						<div class="col-6 text-ellipsis col-md-3">{{ winecourse.title }}</div>
						<div class="col-5 d-none d-md-block">{{ winecourse.subtitle }}</div>
						<div class="col-1 d-none d-md-block">{{ winecourse.price | numberformat }},-</div>
						<div class="col-2 px-0 px-md-3 col-md-1 text-center" style="line-height: 30px;">
							<label v-if="hasPermission('wine-course-status')" class="switch">
								<input type="checkbox" v-model="winecourse.active" :value="winecourse.id" @change="updateWineCourseState(winecourse.id, winecourse.active)">
								<span class="switch__slider"></span>
							</label>
							<label v-else class="switch">
								<input type="checkbox" v-model="winecourse.active" :value="winecourse.id" disabled>
								<span class="switch__slider"></span>
							</label>
						</div>
						<div class="col-4 col-md-2 text-center">
							<div class="icon__wrap">
								<i v-if="hasPermission('wine-course-edit')" class="far fa-edit interact" @click="editWineCourse(winecourse)"></i>
							</div>
							<div class="icon__wrap">
								<i v-if="hasPermission('wine-course-delete')" class="far fa-trash interact" @click="deleteWineCourse(winecourse.id)"></i>
							</div>
						
						</div>
					</div>
				</div>

				<pagination class="mt-3" :data="winecourses" :show-disabled="true" @pagination-change-page="getWineCourses" v-if="winecourses.data && (winecourses.data.length > 0)"></pagination>
			</div>
		</div>

		<div id="my-modal" v-if="showModal">
			<winecellar-wine-course-form :modaltitle="modaltitle" :closeModalParent="closeModal" :wine_bar="wine_bar" :wine_course="wine_course" :shouldUpdate="shouldUpdate"></winecellar-wine-course-form>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.getWineCourses();
		},
		created() {},
		data() {
			return {
				winecourses: [],
				current_page: 1,
				wine_course: {
					id: null,
					title: null,
					subtitle: null,
					price: null,
					selected_wines: [],
				},
				shouldUpdate: false,
				modaltitle: 'Opret vinmenu',
				showModal: false,
			}
		},
		props: {
			wine_bar: {
				type: Object,
			},
			permissions: {
				type: Array,
			}
		},
		methods: {
			getWineCourses(page = 1) {
				this.current_page = page;

				axios.get('/api/winecourses/' + this.wine_bar.id + '?page=' + page)
					.then((response) => {

						if (page <= 1) {
							this.winecourses = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.winecourses = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getWineCourses(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			createWineCourse() {
				this.wine_course.id = null;
				this.wine_course.title = null;
				this.wine_course.subtitle = null;
				this.wine_course.price = null;
				this.wine_course.selected_wines = [];
				this.shouldUpdate = false;
				this.modaltitle = 'Opret vinmenu';

				this.showModal = true;
			},
			editWineCourse(winecourse) {
				this.wine_course.id = winecourse.id;
				this.wine_course.title = winecourse.title;
				this.wine_course.subtitle = winecourse.subtitle;
				this.wine_course.price = winecourse.price;
				this.wine_course.selected_wines = this.pluck(winecourse.wines, 'id');
				this.shouldUpdate = true;
				this.modaltitle = 'Redigér vinmenu';

				this.showModal = true;
			},
			deleteWineCourse(winecourse_id) {
				axios.delete('/api/winecourses/' + winecourse_id)
					.then((response) => {
						this.getWineCourses(this.current_page);
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			closeModal() {
				this.showModal = false;
				this.getWineCourses(this.current_page);
			},
			updateWineCourseState(winecourse_id, is_active) {
				axios.patch('/api/winecourse-state/' + winecourse_id, {
					active: is_active,
				}).then((response) => {})
				.catch((error) => {
					console.warn(error);
				});
			},
			pluck(array, key) {
				return array.map(o => o[key]);
			},
			hasPermission: function(permission) {
				return this.permissions.findIndex(x => x.name === permission) > -1;
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';

	#my-modal {
		position: relative;
	}
</style>