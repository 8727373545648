<template>
	<transition name="move-up-singlewine">
		<div class="single__wine single__course" v-if="this.active">
			<div class="close_btn" @click="closeThis()">
				<i class="far fa-times"></i>
			</div>
			<div class="container-fluid">
				<div class="rowwrap position-relative p-3 p-sm-5">
					<div class="row">
						<div class="col-12">
							<h1 class="mt-4">{{ this.course.title }}</h1>
							<h2 class="slim mt-3">
								<span class="d-block d-sm-inline">{{ this.course.subtitle }}</span>
							</h2>
							<div class="single__wine--prices mt-4">
								<div class="d-inline-block">
									<i class="fas fa-glass-cheers"></i>
									{{ this.course.price | numberformat }},-
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row px-3 px-sm-5">
					<div class="row py-4" v-if="this.course.wines.length > 0">
						<div class="col-12 col-sm-6 col-md-4 mb-4" v-for="wine in this.course.wines" :key="wine.id">
							<div class="wine__item" @click="openWine(wine)">
								<div class="wine__item--content">
									<div class="wine__item--content__number">#{{ wine.id }}</div>
									<div v-if="wine.images.length > 0" class="wine__item--content__image mb-2">
										<img :src="'/storage' + wine.images[0].path" alt="">
									</div>
									<div v-else class="wine__item--content__image mb-2">
										<img src="/images/placeholder_etiqette.jpg" alt="">
									</div>
									<h2 class="wine__item--content__title">{{ wine.name }}</h2>
									<div class="wine__item--content__description">{{ wine.vineyard.region.name }}, {{ wine.vineyard.region.country.name }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row py-4" v-else>
						Henter vine..
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		mounted() {},
		created() {},
		data() {
			return {
				wines: [],
			}
		},
		props: ['course', 'active', 'parentCloseThis', 'parentOpenSendLink', 'openParentWine'],
		methods: {
			closeThis: function() {
				this.parentCloseThis();
			},
			openSendLink: function() {
				this.parentOpenSendLink();
			},
			openWine: function(wine) {
				this.openParentWine(wine);
			},
			// getWines: function() {
			// 	setTimeout(() => {
			// 		console.log('Yeet1', this.course);
			// 		console.log('Yeet2', this.course[0]);
			// 		console.log('Yeet1', this.course.wines);
			// 	}, 5000);
			// 	console.log(this.course.id);
			// 	axios.get('/api/winecourse-wines/' + this.course.id)
			// 		.then((response) => {
			// 			if (response.data.data.length > 0) {
			// 				this.wines = response.data;
			// 			}
			// 		})
			// 		.catch((error) => {
			// 			console.warn(error);
			// 		});
			// },
		}
	}
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';

	.single__wine:not(.single__course) {
		z-index: 50;
	}
</style>