<template>
	<div>
        <div class="row">
            <div class="col-12">
                <a :href="this.complete_url" target="_BLANK">{{ url + '/' + new_slug + '/welcome' }}</a>
                <div v-if="editing" class="d-block mt-2">
                    <input type="text" class="w-100" :class="{ 'bg-danger' : this.errors.new_slug }" v-model="new_slug">
                </div>
            </div>
            <div class="col-12 mt-2">
                <button class="btn-secondary" v-if="!editing" @click="editSlug">Rediger URL</button>
                <button class="btn-secondary" v-else @click="saveSlug">Gem URL</button>
            </div>
        </div>
	</div>
</template>

<script>
	export default {
		mounted() {},
		created() {},
		data() {
			return {
                new_slug: this.winebar.slug,
                complete_url: `${this.url}${this.type}/${this.winebar.slug}/welcome`,
                editing: false,
                errors: {}
			}
		},
		props: ['url', 'winebar', 'type'],
		methods: {
            editSlug: function() {
                this.editing = true;
            },
            saveSlug: function() {
                axios.patch(`/api/winebar-slug/${this.winebar.id}`, {
                    slug: this.new_slug
                }).then((res) => {
                    this.editing = false;
                    this.complete_url = `${this.url}${this.type}/${this.new_slug}/welcome`;
                    location.reload();
                }).catch((err) => {
                    if (err.response.status === 422) {
                        this.errors = {
                            new_slug: 'slug' in err.response.data.errors,
                        }

                        alert('Den URL eksisterer desværre allerede.');

                        setTimeout(() => {
                            this.errors = {};
                        }, 350);
                    } else {
                        console.warn(err);
                    }
                });
            }
		}
	}
</script>