<template>
		<div>
			<transition name="move-up-singlewine">
				<div class="single__wine" v-if="this.active">
					<div class="close_btn" @click="closeThis()">
						<i class="far fa-times"></i>
					</div>
					<div class="container-fluid">
						<div class="rowwrap position-relative p-3 p-sm-5">
							<div class="row">
								<div v-if="this.drink.images.length > 0" class="d-flex">
									<div v-for="image in this.drink.images" :key="image.id" class="col-12 col-sm single__image--col">
										<div class="single__image">
											<img :src="'/storage' + image.path" alt="">
										</div>
									</div>
								</div>
								<div v-else>
									<div class="col-12 col-sm single__image--col">
										<div class="single__image">
											<img src="/images/placeholder_etiqette.jpg" alt="">
										</div>
									</div>
								</div>
								<div class="col-12 col-sm">
									<h1 class="mt-4">{{ this.drink.name }}</h1>
									<h2 class="slim mt-3">
										<span class="d-block d-sm-inline">{{ this.drink.type.name }}</span>
									</h2>
									<div class="single__wine--prices mt-4">
										<div class="d-inline-block">
											<i class="fas fa-glass-martini-alt mr-1"></i>
											{{ this.drink.glass_price | numberformat }},-
										</div>
										<div class="d-inline-block ml-3">
											<i class="far fa-coffee-pot"></i>
											{{ this.drink.pitcher_price | numberformat }},-
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="row px-3 px-sm-5">
							<div class="row py-4">
								<div class="col-12 col-sm-8">
									<div v-if="this.drink.description">
										<h2 class="primary">Om drinken</h2>
										<div class="mb-3">{{ this.drink.description }}</div>
									</div>
									<div v-if="this.drink.free_text">
										<h2 class="primary">Opskrift / Fremgangsmåde</h2>
										<div class="mb-3" v-html="convertFreetext()" @click="handleRecipeClick"></div>
									</div>
								</div>
								<div class="col-12 col-sm-4 mt-4 mt-sm-0 single__wine--list">
									<div v-if="this.drink.ingredients">
										<h3 class="primary">Ingrediensliste</h3>
										<div class="mb-4">
											<span class="commaseparated" v-for="(ingredient, k) in this.drink.ingredients" :key="k">{{ ingredient.name }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</transition>

			<staff-singlerecipe :active="single.active" :recipe="single.recipe" :parentCloseThis="subParentCloseThis"></staff-singlerecipe>
		</div>
</template>

<script>
	export default {
		mounted() {
			console.log(this.drink);
		},
		created() {},
		data() {
			return {
				single: {
					active: false,
					recipe: '',
				}
			}
		},
		props: ['drink', 'active', 'parentCloseThis', 'wine_bar'],
		methods: {
			closeThis: function() {
				this.parentCloseThis();
			},
			subParentCloseThis: function() {
				this.single.active = false;
				this.single.recipe = '';
			},
			convertFreetext: function() {
				var freetext_first = this.drink.free_text,
					freetext_html = '';

				var freetext = freetext_first.split("\n").join("<br>");
				freetext = freetext.replace(']', '[');
				freetext = freetext.split('[');

				var f = 1;
				jQuery.each(freetext, function(i, v){
					if(f % 2 == 0){
						var arr = v.split('|');
						var id = arr[0];
						var text = arr[1];

						freetext_html += '<span class="fakelink recipe__link" data-id="' + id + '">' + text + '</span>';
					} else {
						freetext_html += v;
					}

					f += 1;
				});

				return freetext_html.trim();
			},
			handleRecipeClick: function(event){
				if (event.target.matches('.recipe__link, .recipe__link *')) {
					var $this = jQuery(this);
					this.getRecipe( jQuery(event.target).data('id') );
				}
			},
			getRecipe(recipe_id) {
				axios.post('/api/recipes', {
					'wine_bar_id': this.wine_bar.id
				})
				.then((response) => {
					if (response.data.data.length > 0) {
						this.single.recipe = response.data.data[0];
						this.single.active = true;
					}
				})
				.catch((error) => {
					console.warn(error);
				});
			},
		}
	}
</script>

<style lang="scss">
	@import '../../../../sass/utils/_variables.scss';
	@import '../../../../sass/utils/_placeholders.scss';
</style>