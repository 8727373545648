<template>
	<div>
        <transition name="fade-long">
            <template v-if="recentlyUpdated">
                <div class="full-overlay">
                    <div class="full-overlay__icon">
                        <i class="far fa-check"></i>
                    </div>
                    <div class="full-overlay__text">
                        Oplysningerne<br>
                        blev gemt
                    </div>
                </div>
            </template>
        </transition>

        <template v-if="!user && !recentlyUpdated">
            <div class="row">
                <div class="col-12">
                    <h1>{{ this.title }}</h1>
                </div>
            </div>

            <div class="row" v-if="recentlyUpdated">
                <div class="col-12"></div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="row my-row head-row">
                        <div class="col-3">Navn</div>
                        <div class="col-2">Status</div>
                        <div class="col-1">Drinks</div>
                        <div class="col-1">Vin</div>
                        <div class="col-2">Oprettelsesdato</div>
                        <div class="col-2">Sidst aktiv</div>
                        <div class="col-1 justify-content-end">Handlinger</div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="row my-row body-row" v-for="user in users.data" :key="user.id">
                        <div class="col-3">{{ user.name }}</div>
                        <div class="col-2" :class="{
                            'text-danger': user.billing_status.key == 0,
                            'text-success': user.billing_status.key == 1,
                        }">{{ user.billing_status.value }}</div>
                        <div class="col-1">{{ user.drink_count }}</div>
                        <div class="col-1">{{ user.wine_count }}</div>
                        <div class="col-2">{{ user.created_at }}</div>
                        <div class="col-2">
                            <span v-if="!user.login_at">-</span>
                            <span v-else>{{ user.login_at }}</span>
                        </div>
                        <div class="col-1 text-right">
                            <i class="far fa-edit cursor-pointer" @click="editUser(user)"></i>
                            <!-- <i class="far fa-trash-alt text-danger cursor-pointer ml-2"></i> -->
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6 mt-3">
                    <div class="d-inline-block">
                        <pagination 
                            class="mt-3" 
                            v-if="users.data && (users.data.length > 0)"
                            :data="users" 
                            :show-disabled="true" 
                            :limit="limit" 
                            @pagination-change-page="fetchUsers" 
                        ></pagination>
                    </div>
                </div>

                <div class="col-12 col-md-3 offset-md-3 mt-3">
                    <label class="d-block text-right">Visninger pr. side:</label>
                    <select class="pr_page" v-model="limit" @change="fetchUsers">
                        <option :value="5">5</option>
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="50">50</option>
                        <option :value="100">100</option>
                    </select>
                </div>
            </div>
        </template>

        <div class="row" v-if="user">
			<user-edit 
                :title="title" 
                :user="user"
                @close="user = null"
                @updated="userUpdated()"
            ></user-edit>
		</div>
    </div>
</template>

<script>
	export default {
        props: [
            'title'
        ],

        created() {
            this.fetchUsers()
        },

		data() {
			return {
                users: [],
                user: null,
                current_page: 1,
				limit: 10,
                recentlyUpdated: false,
            }
		},
        
		methods: {
            fetchUsers(page = 1) {
                this.current_page = page;

				axios.get('/api/users', {
                    params: {
                        page: page, 
                        limit: this.limit,
                    }
                }).then((response) => {
                    if (page <= 1) {
                            this.users = response.data;
                    } else {
                        if (response.data.data.length > 0) {
                            this.users = response.data;
                        } else {
                            page = page - 1;
                            if (page > 0) {
                                this.fetchUsers(page);
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.warn(error);
                });
            },
            editUser(user) {
                this.user = user
            },
            userUpdated() {
                this.recentlyUpdated = true
                this.fetchUsers()  
            }
        },

        watch: {
            recentlyUpdated() {
                if (this.recentlyUpdated) {
                    setTimeout(() => {
                        this.recentlyUpdated = false
                    }, 2000)
                }
            }
        }
	}
</script>
