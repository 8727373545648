<template>
    <div class="col-12 col-md-6">
        <div class="loading-overlay" :class="{ 'd-none': !loading }">
            <div class="lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="input__wrapper" :class="{ 'error': details.images.error }">
                    <label v-if="allowAddingImages" for="drink_image" class="file__box">
                        <input type="file" class="input" @change="chooseImage" accept="image/*" name="drink_image">
                        <button class="secondary">Tilføj billede</button>
                    </label>
                    <div v-if="details.images.error" class="input__error">{{ details.images.errormsg }}</div>
                </div>
            </div>
        </div>
        
        <div v-if="details.images.content.length > 0">
            <div class="image input__wrapper" v-for="(image, i) in details.images.content" :key="i">
                <div class="icon__button primary round" @click="removeImage(i)">
                    <i class="far fa-times"></i>
                </div>
                <div class="label">Billede</div>
                <cropper 
                    :stencil-props="{
                        aspectRatio: 150/200
                    }"
                    :src="image"
                    :min-width="cropper.minWidth"
                    :min-height="cropper.minHeight"
                    :restrictions="pixelsRestriction"
                    imageRestriction="none"
                    ref="cropper"
                ></cropper>
                <button class="btn btn-secondary" @click="cropImage(i)">Accepter</button>
            </div>
        </div>

        <div v-if="currentImages.content.length > 0">
            <div class="image input__wrapper" v-for="(image, i) in currentImages.content" :key="image.id">
                <div class="icon__button primary round" @click="deleteImage(image)">
                    <i class="far fa-times"></i>
                </div>
                <div class="label">Billede</div>
                <img :src="'/storage/' + image.path">
                <label class="mb-3" v-if="maxImages > 1">
                    <input type="radio" class="radioedbox" name="primary_image[]" v-model="details.primaryImage" :value="i" @change="selectPrimaryImage()">
                    <span class="check"></span>
                    <span class="label">Brug dette billede som primært</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
		props: ['currentImages', 'primaryImage', 'maxImages'],
		data() {
			return {
				loading: false,
				allowAddingImages: true,
				cropper: {
					minWidth: 150,
					minHeight: 200
				},
                details: {
                    images: {
						step: 2,
						content: [],
						check: false,
						error: false,
						errormsg: 'Fejl!',
						primary: 0,
					},
                    primaryImage: this.primaryImage
                }
			}
		},
        mounted() {
            this.toggleAllowAddingImages();
        },
		methods: {
            selectPrimaryImage() {
                this.$emit('image-primary', this.details.primaryImage)
            },
			removeImage(index) {
				this.details.images.content.splice(index, 1)
				this.toggleAllowAddingImages()
			},
			deleteImage(image) {
                this.$emit('image-deleted', image)
				this.toggleAllowAddingImages()
			},
			chooseImage(event) {
				// Reference to the DOM input element
				var input = event.target;
				// Ensure that you have a file before attempting to read it
				if (input.files && input.files[0]) {
                    // create a new FileReader to read this image and convert to base64 format
                    var reader = new FileReader();
                    // Define a callback function to run, when FileReader finishes its job
                    reader.onload = (e) => {
                        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                        // Read image as base64 and set to imageData
                        // this.details.images.content = e.target.result;

                        let img = new Image();

                        img.src = e.target.result;

                        var _this = this;

                        img.onload = () => {
                            let imgWidth = img.naturalWidth;
                            let imgHeight = img.naturalHeight;

                            if (imgWidth >= this.cropper.minWidth && imgHeight >= this.cropper.minHeight) {
                                _this.details.images.content.push(e.target.result);
                                _this.toggleAllowAddingImages();
                            } else {
                                alert(`Billedet er for lille. Det skal være mindst ${this.cropper.minWidth}px bredt og ${this.cropper.minHeight}px højt`);
                            }
                        };
                    }
                    // Start the reader job - read file as a data url (base64 format)
                    reader.readAsDataURL(input.files[0]);
				}
			},
			cropImage(i) {
                this.loading = true

                setTimeout(() => {
                    let cropper = this.$refs.cropper[i]
                    const {coordinates, canvas} = cropper.getResult();

                    axios.post('/api/images', {
                        image: canvas.toDataURL()
                    }).then((res) => {
                        if (res.status === 201) {
                            this.$emit('image-cropped', res.data.path)
                            this.details.images.content = []
                            this.toggleAllowAddingImages();
                            this.loading = false
                        }
                    })
                }, 250)
			},
            toggleAllowAddingImages() {
				this.allowAddingImages = (this.currentImages.content.length < this.maxImages) && (this.details.images.content.length == 0);
			},
            pixelsRestriction({minWidth, minHeight, maxWidth, maxHeight, imageWidth, imageHeight}) {
				return {
					minWidth: minWidth,
					minHeight: minHeight,
					maxWidth: maxWidth,
					maxHeight: maxHeight,
				};
			},
		}
    }
</script>

<style lang="scss">
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, 0.5);
        z-index: 50;
    }

    .lds-ripple {
        display: inline-block;
        width: 80px;
        height: 80px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .lds-ripple div {
        position: absolute;
        border: 4px solid #fff;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }

    @keyframes lds-ripple {
        0% {
            top: 144px;
            left: 144px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 288px;
            height: 288px;
            opacity: 0;
        }
    }

</style>