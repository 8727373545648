<template>
	<div>
		<transition name="move-up-singlewine">
			<div class="send_link px-2 px-md-0" v-if="this.active">
				<div class="close_btn" @click="closeSendLink()">
					<i class="far fa-times"></i>
				</div>
				<div class="row">
					<div class="col col-12">
						<div class="w-100 col vh-40 background-image" :style="'background-image: url(' + image.src + ');'">
							<div>{{ image.text }}</div>
						</div>
					</div>
					<div class="col col-12 col-sm-6 offset-sm-3">
						<form class="w-100" @submit.prevent="sendMail()">
							<div class="input__wrapper">
								<input type="text" class="flashy" placeholder="Navn*" v-model="details.name" required>
							</div>
							<div class="input__wrapper">
								<input type="email" class="flashy" placeholder="E-mail*" v-model="details.email" required>
							</div>
							<div class="input__wrapper mb-3">
								<label for="send_link_newsletter" class="d-flex justify-content-start">
									<input type="checkbox" id="send_link_newsletter" class="checkedbox flashy" v-model="details.newsletter">
									<div class="check"><i class="far fa-check"></i></div>
									<div class="label mt-1">Ja tak – jeg vil gerne modtage nyhedsbrev om vinarrangementer og tilbud fra Minvinogbar. Du kan altid nemt afmelde dig igen.</div>
								</label>
							</div>
							<div class="input__wrapper">
								<label for="send_link_privacy" class="d-flex justify-content-start">
									<input type="checkbox" id="send_link_privacy" name="send_link_privacy" class="checkedbox flashy" v-model="details.privacy" required>
									<div class="check"><i class="far fa-check"></i></div>
									<div class="label mt-1">Jeg har læst og accepterer <span class="fakelink" style="color: #ffffff;" @click="openTerms()">persondatapolitikken</span></div>
								</label>
							</div>
							<div class="input__wrapper mt-3">
								<button v-if="sent" class="fancy success large">Sendt!</button>
								<button v-else-if="!sending" class="fancy success large" type="submit">Send mig et link!</button>
								<button v-else class="fancy secondary large">Sender..</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
    export default {
		data() {
			return {
				image: {
					src: 'https://theblacksheeponline.com/wp-content/uploads/2017/09/chateau-diana-1.jpg',
					text: 'Få tilsendt et link til en eller flere webshops, så du kan købe vinen privat'
				},
				details: {
					name: '',
					email: '',
					privacy: false,
					newsletter: false,
				},
				sending: false,
				sent: false,
			}
		},
		props: ['active', 'parentCloseSendLink', 'wine', 'wine_bar'],
		mounted() {
			
		},
		methods: {
			closeSendLink: function() {
				this.parentCloseSendLink();
			},
			openTerms: function() {
				$('body').addClass('open--terms');
			},
			sendMail: function() {
				let _this = this;
				_this.sending = true;

				axios.post('/api/mail/webshop/send', {
					name: _this.details.name,
					email: _this.details.email,
					gdpr: _this.details.privacy,
					newsletter: _this.details.newsletter,
					mail_id: _this.wine_bar.id,
					wine_id: this.wine.id,
				})
				.then(function (response) {
					console.log(response);
					_this.sending = false;
					_this.sent = true;
				})
				.catch(function (error) {
					console.warn(error);
				});
			}
		}
    }
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';

	.send_link {
		top: 0;
    	right: 50%;
		width: 100%;
		z-index: 51;
		height: 100vh;
		position: fixed;
		max-width: 768px;
		font-weight: 400;
		color: $white-color;
		background-color: $darkestgray-color;

		&:not(.move-up-singlewine-enter-active):not(.move-up-singlewine-leave-active):not(.move-up-singlewine-enter-to):not(.move-up-singlewine-leave):not(.move-up-singlewine-enter):not(.move-up-singlewine-leave-to ){
			transform: translateX(50%);
		}

		.label {
			font-size: 14px;
			font-weight: 400;
			color: $white-color;
		}

		.row {
			height: 100%;
			align-items: flex-start;
		}

		.col {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.background-image {
			flex-grow: 1;
			padding: 0 20%;
			position: relative;
			text-align: center;
			color: $white-color;

			div {
				z-index: 2;
				position: relative;
			}

			&:before {
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
				width: 100%;
				content: "";
				height: 100%;
				position: absolute;
				background-color: rgba($black-color, .3);
			}
		}
	}
</style>