<template>
	<transition name="move-up-singlewine">
		<div class="single__wine" v-if="this.active">
			<div class="close_btn" @click="closeThis()">
				<i class="far fa-times"></i>
			</div>
			<div class="container-fluid">
				<div class="rowwrap position-relative p-3 p-sm-5">
					<div class="row">
						<div class="col-12 col-sm single__image--col">
							<div v-if="this.wine.images.length > 0" class="single__image">
								<img :src="'/storage' + this.wine.images[0].path" alt="">
							</div>
							<div v-else class="single__image">
								<img src="/images/placeholder_etiqette.jpg" alt="">
							</div>
						</div>
						<div class="col-12 col-sm">
							<h1 class="mt-4">{{ this.wine.name }}</h1>
							<h2 class="slim mt-3">
								<span class="d-block d-sm-inline" v-if="this.wine.vineyard.name != 'Ukendt'">{{ this.wine.vineyard.name }}</span>
								<span class="d-none d-sm-inline" v-if="this.wine.vineyard.name != 'Ukendt'"> - </span>
								<span class="d-block d-sm-inline"><span v-if="this.wine.vineyard.region.name != 'Ukendt'">{{ this.wine.vineyard.region.name }}, </span>{{ this.wine.vineyard.region.country.name }}</span>
							</h2>
							<div v-if="!this.offer" class="single__wine--prices mt-4">
								<div class="d-inline-block">
									<i class="fas fa-wine-glass-alt mr-1"></i>
									{{ this.wine.glass_price | numberformat }},-
								</div>
								<div class="d-inline-block ml-3">
									<i class="fas fa-wine-bottle rotate--45"></i>
									{{ this.wine.bottle_price | numberformat }},-
								</div>
								<div class="d-inline-block float-right single__wine--position">
									#{{ this.wine.position }}
								</div>
							</div>
							<div v-else class="single__wine--prices mt-4">
								<div class="d-inline-block line-through">
									<i class="far fa-wine-glass-alt"></i>
									{{ this.wine.glass_price | numberformat }},-
								</div>
								<div class="d-inline-block ml-3">
									<i class="fas fa-wine-glass-alt"></i>
									{{ this.wine.offer_price | numberformat }},-
								</div>
							</div>
						</div>
					</div>
				</div>

				<!--<div class="row cta-background p-2" @click="openSendLink()">
					<div class="col-12 text-center">
						Klik og køb vinen med hjem!
					</div>
				</div>-->

				<div class="row px-3 px-sm-5">
					<div class="row py-4">
						<div class="col-12 col-sm-8">
							<div v-if="this.wine.description">
								<h2 class="primary">Om vinen</h2>
								<div class="prewrap mb-3">{{ this.wine.description }}</div>
							</div>
							<div v-if="this.wine.vineyard.description">
								<h2 class="primary">Om producenten</h2>
								<div class="mb-3">{{ this.wine.vineyard.description }}</div>
							</div>
							<div v-if="this.wine.reviews.length > 0">
								<h2 class="primary">Anmeldelser</h2>
								<div class="mb-3">{{ this.wine.reviews[0].content }}</div>
							</div>
						</div>
						<div class="col-12 col-sm-4 mt-4 mt-sm-0 single__wine--list">
							<div v-if="this.wine.vineyard.region.country.name">
								<h3 class="primary">Land</h3>
								<div class="mb-4">{{ this.wine.vineyard.region.country.name }}</div>
							</div>
							<div v-if="this.wine.vineyard.region.name">
								<h3 class="primary">Region</h3>
								<div class="mb-4">{{ this.wine.vineyard.region.name }}</div>
							</div>
							<div v-if="this.wine.vineyard.name">
								<h3 class="primary">Vingård</h3>
								<div class="mb-4">{{ this.wine.vineyard.name }}</div>
							</div>
							
							<div v-if="this.wine.year">
								<h3 class="primary">År</h3>
								<div class="mb-4">{{ this.wine.year }}</div>
							</div>
							<div v-if="this.wine.alc_vol">
								<h3 class="primary">Alkohol</h3>
								<div class="mb-4">{{ this.wine.alc_vol }} %</div>
							</div>
							<div v-if="this.wine.cask != 0">
								<h3 class="primary">Fadlagring</h3>
								<div class="mb-4">{{ this.wine.cask_month }}</div>
							</div>
							<div v-if="this.wine.grapes">
								<h3 class="primary">Druer</h3>
								<div class="mb-4">
									<span class="commaseparated" v-for="(grape, k) in this.wine.grapes" :key="k">{{ grape.name }}</span>
								</div>
							</div>

							<div class="mt-3 button success noheight w-100" @click="openSendLink()">
								Klik og køb vinen med hjem!
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		mounted() {},
		created() {},
		data() {
			return {
				
			}
		},
		props: ['wine', 'active', 'parentCloseThis', 'parentOpenSendLink', 'offer'],
		methods: {
			closeThis: function() {
				this.parentCloseThis();
			},
			openSendLink: function() {
				this.parentOpenSendLink();
			},
		}
	}
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';

	.single {
		&__wine {
			top: 0;
			//left: 0;
			right: 50%;
			width: 100%;
			z-index: 49;
			height: 100vh;
			overflow: auto;
			position: fixed;
			max-width: 768px;
			background-color: $body-bg-front;

			&:not(.move-up-singlewine-enter-active):not(.move-up-singlewine-leave-active):not(.move-up-singlewine-enter-to):not(.move-up-singlewine-leave):not(.move-up-singlewine-enter):not(.move-up-singlewine-leave-to ){
				transform: translateX(50%);
			}

			.fakelink {
				color: $champagne-color;
				text-decoration: underline;
			}

			&--prices {
				font-size: 20px;

				@media(min-width: $sm){
					font-size: 24px;
				}
			}
			
			&--position {
				font-weight: 400;
			}
			
			&--list {
				/*display: flex;
				flex-wrap: wrap;*/
				padding-bottom: 65px;
				
				@media(min-width: $sm){
					padding-bottom: 65px;
				}

				> div {
					max-width: 50%;
					flex-basis: 50%;

					&.w-100 {
						max-width: 100%;
						flex-basis: 100%;
					}

					@media(min-width: $sm){
						max-width: 100%;
						flex-basis: 100%;
					}
				}
			}
		}

		&__image {
			padding: 10px;
			max-width: 150px;
			text-align: center;
			border-radius: 4px;
			background-color: $white-color;

			&--col {
				max-width: 180px;
			}
			
			img {
				max-width: 130px;
				max-height: 175px;
			}
		}
	}

	.close_btn {
		top: 15px;
    	right: 15px;
		width: 40px;
		z-index: 25;
		height: 40px;
		display: flex;
		cursor: pointer;
		font-size: 26px;
		position: absolute;
		text-align: center;
		border-radius: 0%;
		color: $white-color;
		align-items: center;
		justify-content: center;
		transition: .3s ease-out;
		border: 2px solid $white-color;
		background-color: rgba($black-color, .25);
		box-shadow: 0 0 5px rgba($black-color, .25);

		.front & {
			width: 46px;
			height: 46px;
			border-radius: 50%;
			border-width: 4px;
		}

		&.dark {
			color: $darkestgray-color;
			border-color: $darkestgray-color;

			body:not(.front) & {
				&:hover {
					color: $white-color;
					background-color: $darkestgray-color;
				}
			}
		}

		i {
			transition: transform .3s ease-out;
		}

		&:hover {
			i {
				transform: rotate(270deg);
				transform-origin: center;
			}
		}

		.navigation & {
			top: auto;
			right: 10px;
			bottom: 10px;

			@media(min-width: $sm){
				top: 25px;
				right: 25px;
				bottom: auto;
			}
		}

		@media(min-width: $sm){
			top: 25px;
			right: 25px;
			bottom: auto;
			box-shadow: none;
			background-color: transparent;
		}

		&.small_move {
			top: 0px;
			right: 15px;

			.front & {
				right: 12px;
			}
		}
	}
</style>