<template>
	<div class="navigation__wrapper">
		<div class="navigation staff">
			<div class="row">
				<a v-for="(nav, k) in navigation" :key="k" :href="nav.route" class="d-flex col-12 navigation__content--wrap flex-grow-1" :class="{ 'col-sm-6' : navigation.length > 1 }">
					<div class="navigation__content">
						<h2>{{ nav.title }}</h2>
						<div v-html="nav.description"></div>
					</div>
				</a>
			</div>
			<div class="close_btn" @click="toggleMenuOpen()" v-if="!this.active">
				<i class="far fa-times"></i>
			</div>
		</div>

		<div class="frontend-navigation" @click="toggleMenuOpen()">
			<div class="burger">
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {},
		created() {
			// console.log('asdas');
			// console.log(this.wine_bar_slug);
		},
		data() {
			return {
				navigation: [
					{
						title: 'Vinkort',
						route: `/s/${this.wine_bar_slug}/wines`,
					},
					{
						title: 'Vinsmagning',
						route: `/s/${this.wine_bar_slug}/courses`,
					},
					{
						title: 'Cocktails',
						route: `/s/${this.wine_bar_slug}/drinks`,
					},
					{
						title: 'Opskrifter',
						route: `/s/${this.wine_bar_slug}/recipes`,
					},
				],
			}
		},
		props: ['active', 'wine_bar_slug'],
		methods: {
			toggleMenuOpen() {
				$('body').toggleClass('open--menu');
			},
		}
	}
</script>

<style lang="scss">
	@import '../../../../sass/utils/_variables.scss';
	@import '../../../../sass/utils/_placeholders.scss';

	.navigation {
		&.staff {
			.row {
				> a {
					&:nth-child(5n + 1) {
						background-color: $menu_1;
						transition: opacity .3s ease-out .6s;
					}

					&:nth-child(5n + 2) {
						background-color: $menu_2;
						transition: opacity .3s ease-out 0.7s;
					}

					&:nth-child(5n + 3) {
						background-color: $menu_3;
						transition: opacity .3s ease-out 0.8s;
					}

					&:nth-child(5n + 4) {
						background-color: $menu_5;
						transition: opacity .3s ease-out 0.9s;
					}

					&:nth-child(5n + 5) {
						background-color: $darkgray-color;
						transition: opacity .3s ease-out 1.0s;
					}
					
					&:nth-child(6n + 6) {
						background-color: $menu_4;
						transition: opacity .3s ease-out 1.0s;
					}
				}
			}
		}
	}
</style>