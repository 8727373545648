<template>
	<div class="navigation__wrapper">
		<div class="navigation">
			<div class="row" v-for="(nav, k) in navigation" :key="k" :class="{ 'flex-column-reverse' : navigation.length == 1 }">
				<a :href="nav.route" class="d-flex col-12 navigation__content--wrap flex-grow-1" :class="{ 'col-sm-6' : navigation.length > 1 }">
					<div class="navigation__content">
						<h2 v-html="nav.title"></h2>
						<div v-html="nav.description"></div>
					</div>
				</a>
				<a :href="nav.route" class="d-none d-sm-block navigation__image flex-grow-1" :style="'background-image: url(' + nav.image + ');'" :class="{ 'col-6' : navigation.length > 1, 'col-12' : navigation.length == 1 }"></a>
			</div>
			<div class="close_btn" @click="toggleMenuOpen()" v-if="!this.active">
				<i class="far fa-times"></i>
			</div>
		</div>

		<div class="frontend-navigation" @click="toggleMenuOpen()">
			<div class="burger">
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {},
		created() {
			this.fixTitle();
		},
		data() {
			return {
				navigation: [
					{
						title: 'VINKÆLDER<br><br>Antal vine: 0<br>Værdi: 0,-',
						image: 'https://app.vivinobar.com/images/wine-cellar1.jpg',
						route: `/${this.wine_bar.slug}/wines`,
					},
					{
						title: 'COCKTAILSKORT',
						image: 'https://app.vivinobar.com/images/cocktailsmenu1.jpg',
						route: `/${this.wine_bar.slug}/drinks`,
					},
					{
						title: 'OPSKRIFTER',
						image: 'https://app.vivinobar.com/images/drink-recipes1.jpg',
						route: `/${this.wine_bar.slug}/recipes`,
					},
				],
			}
		},
		props: ['active', 'wine_bar'],
		methods: {
			toggleMenuOpen: function() {
				$('body').toggleClass('open--menu');
			},
			fixTitle: function() {
				axios.get(`/api/wines-and-amount/${this.wine_bar.id}`).then((res) => {
					this.navigation[0].title = 'VINKÆLDER';
					this.navigation[0].description = `
						<div class="mt-3">
							Antal unikke vine: ${res.data.uniqueWines}<br>
							Antal flasker: ${res.data.bottleCount}<br>
							Værdi: ${res.data.value},-
						</div>
					`;
				}).catch((err) => {
					console.log(err);
				});
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../../sass/utils/_variables.scss';
	@import '../../../../sass/utils/_placeholders.scss';
</style>