<template>
	<div>
		<div class="row" v-if="!wine">
			<div class="col-12">
				<h1>{{ this.headline }}</h1>
			</div>
			<div class="col-12 col-xl-8 mb-5">
				<div class="row">
					<div class="col-12 col-sm-5 col-md-3">
						<small class="d-block mb-2">Søg</small>
						<input type="text" @input="searchFunction" v-model="search_field">
					</div>
					<div class="col-12 col-sm-5 offset-sm-2 col-md-3 offset-md-6 col-lg-2 offset-lg-7">
						<small class="d-block mb-2 text-right">Visninger pr. side:</small>
						<select class="pr_page" v-model="pr_page" @change="set_per_page">
							<option value="5">5</option>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</select>
					</div>
				</div>
				<div class="row my-row head-row">
					<div v-if="!this.winemenu.is_offer_menu" class="col-2 col-md-1">Nr.</div>
					<div class="d-none d-md-flex" :class="{ 'col-3 col-md-6' : !this.winemenu.is_offer_menu, 'col-5 col-md-7' : this.winemenu.is_offer_menu }">Navn</div>
					<div class="col-2 col-md-1">
						<span v-if="!this.winemenu.is_offer_menu" class="d-none d-sm-block">Pris&nbsp;pr. glas</span>
						<span v-else class="d-none d-sm-block">Pris&nbsp;pr. glas (før)</span>
						<i class="d-block d-sm-none fas fa-wine-glass-alt"></i>
					</div>
					<div class="col-2 col-md-1">
						<span v-if="!this.winemenu.is_offer_menu" class="d-none d-sm-block">Pris&nbsp;pr. flaske</span>
						<span v-else class="d-none d-sm-block">Pris&nbsp;pr. glas (ny)</span>
						<i class="d-block d-sm-none fas fa-wine-bottle"></i>
					</div>
					<div class="col-2 col-md-1 center-row d-none d-md-flex winemenu__price">Redigér priser</div>
					<div class="col-2 col-md-1 center-row d-none d-md-flex winemenu__price">Redigér vin</div>
					<div class="col-2 col-md-1 center-row d-none d-md-flex winemenu__price">Fjern</div>
				</div>

				<div v-if="!this.winemenu.is_offer_menu">
					<div class="row my-row body-row" v-for="wine in wine_menu_wines.data" :key="wine.id">
						<div class="col-2 col-md-1">#{{ wine.position }}</div>
						<div class="d-none d-md-block col-3 col-md-6 text-ellipsis">{{ wine.name }}</div>
						<div class="col-2 col-md-1 winemenu__price">
							<div v-if="wine.glass_price != null">{{ wine.glass_price | numberformat }},-</div>
							<div v-else>-</div>
						</div>
						<div class="col-2 col-md-1 winemenu__price">
							<div v-if="wine.bottle_price != null">{{ wine.bottle_price | numberformat }},-</div>
							<div v-else>-</div>
						</div>
						<div class="col-2 col-md-1 winemenu__price text-center">
							<i v-if="hasPermission('wine-menu-edit-wine')" class="far fa-money-check-edit-alt interact" @click="editWineOnWineMenu(wine)"></i>
						</div>
						<div class="col-2 col-md-1 winemenu__price text-center">
							<i v-if="hasPermission('wine-edit')" class="far fa-edit interact" @click="editWine(wine)"></i>
						</div>
						<div class="col-2 col-md-1 winemenu__price text-center">
							<i v-if="hasPermission('wine-menu-remove-wine')" class="far fa-minus-square interact" @click="removeWineFromWineMenu(wine)"></i>
						</div>
					</div>
				</div>

				<div v-if="this.winemenu.is_offer_menu">
					<div class="row my-row body-row" v-for="wine in wine_menu_wines.data" :key="wine.id">
						<div class="d-none d-md-block col-5 col-md-7 text-ellipsis">{{ wine.name }}</div>
						<div class="col-2 col-md-1 winemenu__price">
							<div v-if="wine.glass_price != null">{{ wine.glass_price | numberformat }},-</div>
							<div v-else>-</div>
						</div>
						<div class="col-2 col-md-1 winemenu__price">
							<div v-if="wine.offer_price != null">{{ wine.offer_price | numberformat }},-</div>
							<div v-else>-</div>
						</div>
						<div class="col-2 col-md-1 winemenu__price text-center">
							<i v-if="hasPermission('wine-menu-edit-wine')" class="far fa-money-check-edit-alt interact" @click="editWineOnWineMenu(wine)"></i>
						</div>
						<div class="col-2 col-md-1 winemenu__price text-center">
							<i v-if="hasPermission('wine-edit')" class="far fa-edit interact" @click="editWine(wine)"></i>
						</div>
						<div class="col-2 col-md-1 winemenu__price text-center">
							<i v-if="hasPermission('wine-menu-remove-wine')" class="far fa-trash interact" @click="removeWineFromWineMenu(wine)"></i>
						</div>
					</div>
				</div>
				

				<pagination class="mt-3" :data="wine_menu_wines" :limit="limit" :show-disabled="true" @pagination-change-page="getWineMenuWines" v-if="wine_menu_wines.data && (wine_menu_wines.data.length > 0)"></pagination>
			</div>

			<div v-if="hasPermission('wine-add')" class="col-12 col-xl-4">
				<div class="dark-bg">
					<div class="row">
						<div class="col-6">
							<label for="type">Type</label>
							<v-select label="name" :options="types.data" v-model="search.type"></v-select>
						</div>
						<div class="col-6">
							<label for="country">Land</label>
							<v-select label="name" :options="countries.data" v-model="search.country"></v-select>
						</div>
						<div class="col-6">
							<label for="">Søg</label>
							<input type="text" placeholder="Søg efter vin" class="input" v-model="search.text">
						</div>
						<div class="col-6 text-right">
							<label for="">&nbsp;</label>
							<div>
								<button @click="searchWines">Filtrér</button>
							</div>
						</div>
					</div>

					<div class="row">
						<nav class="w-100">
							<div class="nav nav-tabs" id="nav-tab" role="tablist">
								<a class="tab active" id="nav-my-winedatabase-tab" data-toggle="tab" href="#nav-my-winedatabase" role="tab" aria-controls="nav-my-winedatabase" aria-selected="true">Min vindatabase</a>
								<a class="tab" id="nav-global-winedatabase-tab" data-toggle="tab" href="#nav-global-winedatabase" role="tab" aria-controls="nav-global-winedatabase" aria-selected="false">Global vindatabase</a>
							</div>
						</nav>
					</div>

					<div class="tab-content pt-2" id="nav-tabContent">
						<div class="tab-pane fade show active" id="nav-my-winedatabase" role="tabpanel" aria-labelledby="nav-my-winedatabase-tab">
							<div class="row my-row body-row" v-for="wine in wine_bar_wines.data" :key="wine.id">
								<div class="col-10 col-md-11">{{ wine.name }}</div>
								<div class="col-2 col-md-1">
									<i class="far fa-plus-square interact" @click="showModalForAddingWineToWineMenu(wine)"></i>
								</div>
							</div>

							<pagination class="mt-3" :data="wine_bar_wines" :limit="limit" :show-disabled="true" @pagination-change-page="wineBarWinePaginator" v-if="wine_bar_wines.data && (wine_bar_wines.data.length > 0)"></pagination>
						</div>
						
						<div class="tab-pane fade" id="nav-global-winedatabase" role="tabpanel" aria-labelledby="nav-global-winedatabase-tab">
							<div class="row my-row body-row" v-for="wine in global_wines.data" :key="wine.id">
								<div class="col-10 col-md-11">{{ wine.name }}</div>
								<div class="col-2 col-md-1">
									<i v-if="allow_wine_creation" class="far fa-plus-square interact" @click="addWineToWineBar(wine)"></i>
								</div>
							</div>

							<pagination class="mt-3" :data="global_wines" :limit="limit" :show-disabled="true" @pagination-change-page="globalWinePaginator" v-if="global_wines.data && (global_wines.data.length > 0)"></pagination>
						</div>
					</div>
				</div>
			</div>

			<transition name="fade">
				<div id="modal" class="modal" v-if="showmodal == true">
					<div class="modal__background" @click="hideModalForAddingWineToWineMenu"></div>
					<div class="modal__wrapper">
						<span @click="hideModalForAddingWineToWineMenu" class="modal__close--button"><i class="far fa-times"></i></span>
						<strong class="modal__headline">{{ modal.wine }}</strong>
						<span id="modal-wine-menu"></span>
						<form id="modal-wine-form" v-on:submit.prevent="addWineToWineMenu">
							<div class="input__wrapper">
								<label for="modal-wine-price-glass">Pris pr. glas</label>
								<div class="append">kr.</div>
								<input type="number" id="modal-wine-price-glass" class="input" v-model="modal.price.glass">
							</div>

							<div v-if="!this.winemenu.is_offer_menu" class="input__wrapper">
								<label for="modal-wine-price-bottle">Pris pr. flaske</label>
								<div class="append">kr.</div>
								<input type="number" id="modal-wine-price-bottle" class="input" v-model="modal.price.bottle">
							</div>

							<div v-else class="input__wrapper">
								<label for="modal-wine-offer-price">Pris pr. glas (ny)</label>
								<div class="append">kr.</div>
								<input type="number" id="modal-wine-offer-price" class="input" v-model="modal.price.offer_price">
							</div>

							<div v-if="!this.winemenu.is_offer_menu">
								<label for="modal-wine-position">Placering</label>
								<v-select id="modal-wine-position" :options="this.available_positions" v-model="modal.position"></v-select>
							</div>

							<input type="hidden" id="modal-wine-id" v-model="modal.wine_id">
							<input type="submit" class="button primary w-100" :value="modal.buttontext">
						</form>
					</div>
				</div>
			</transition>
		</div>

		<div class="row" v-if="wine">
			<winecellar-create-wine title="Gem vin" :action="this.action" :wine="this.wine" :parentCloseEditOpen="closeEditOpen" :copy_wine="this.copy_wine"></winecellar-create-wine>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.getWineMenuWines();
			this.wineBarWinePaginator();
			this.globalWinePaginator();
			this.getTypes();
			this.getCountries();
			this.getAvailablePositions();

			var ww = $(window).width();

			if(ww < 768){
				this.limit = -1;
			}
		},
		created() {},
		data() {
			return {
				limit: 1,
				copy_wine: false,
				wine_menu_wines: [],
				wine_bar_wines: [],
				global_wines: [],
				types: [],
				countries: [],
				available_positions: [''],
				current_page: 1,
				pr_page: 10,
				showmodal: false,
				modal: {
					wine: '',
					price: {
						glass: null,
						bottle: null,
						offer_price: null,
						bottle_amount: null,
					},
					position: null,
					buttontext: 'Tilføj til vinkort',
				},
				search: {
					country: '',
					type: '',
					text: '',
				},
				wine: null,
				action: null,
				title: '',
				is_searching: false,
				search_field: '',
			}
		},
		props: {
			winemenu: {
				type: Object,
			},
			headline: {
				type: String,
			},
			permissions: {
				type: Array,
			},
			allow_wine_creation: {
				type: Boolean,
			},
		},
		methods: {
			set_per_page: function(page = 1) {
				this.getWineMenuWines(page);
				this.wineBarWinePaginator(page);
				this.globalWinePaginator(page);
			},
			wineBarWinePaginator: function(page = 1) {
				if (this.is_searching) {
					this.searchWines(page);
				} else {
					this.getWineBarWines(page);
				}
			},
			globalWinePaginator: function(page = 1) {
				if (this.is_searching) {
					this.searchWines(page);
				} else {
					this.getGlobalWines(page);
				}
			},
			searchWines: function(page = 1) {
				let filters = {
					country: null,
					type: null,
					text: null,
				};

				filters.country = (this.search.country != '') ? this.search.country.id : null;
				filters.type = (this.search.type != '') ? this.search.type.id : null;
				filters.text = (this.search.text != '') ? this.search.text : null;

				if ($('#nav-my-winedatabase-tab').attr('aria-selected') == "true") {
					this.searchWineBarWines(page, filters);
				} else if ($('#nav-global-winedatabase-tab').attr('aria-selected') == "true") {
					this.searchGlobalWines(page, filters);
				}

				this.is_searching = true;
			},
			searchWineBarWines: function(page = 1, filters) {
				axios.post('/api/search/winebar-wines?page=' + page + '&limit=' + this.pr_page, {
					wine_bar_id: this.winemenu.wine_bar_id,
					wine_menu_id: this.winemenu.id,
					filters: filters,
				})
				.then((response) => {
					this.wine_bar_wines = response.data;
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			searchGlobalWines: function(page = 1, filters) {
				axios.post('/api/search/global-wines?page=' + page + '&limit=' + this.pr_page, {
					wine_bar_id: this.winemenu.wine_bar_id,
					filters: filters,
				})
				.then((response) => {
					this.global_wines = response.data;
					console.log(this.global_wines);
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			getTypes() {
				axios.get('/api/wine-types')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.types = response.data;

							this.types.data.unshift({
								id: null,
								name: 'Ingen valgt'
							});
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getCountries() {
				axios.get('/api/countries?limit=10000')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.countries = response.data;

							this.countries.data.unshift({
								id: null,
								name: 'Ingen valgt'
							});
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getAvailablePositions() {
				axios.get('/api/wine-positions/' + this.winemenu.id)
					.then((response) => {
						this.available_positions = $.map(response.data, function(value, index){
							return [value];
						});
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getWineMenuWines(page = 1) {
				this.current_page = page;

				axios.get('/api/winemenu-wines/' + this.winemenu.id + '?page=' + page + '&limit=' + this.pr_page)
					.then((response) => {
						if (page <= 1) {
							 this.wine_menu_wines = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.wine_menu_wines = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getWineMenuWines(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			showModalForAddingWineToWineMenu(wine) {
				$('#modal-wine-menu').text(wine.name);
				this.modal.wine_id = wine.id;
				this.resetPositionSelect();
				this.showmodal = true;
				this.modal.wine = wine.name;
				this.modal.price.glass = wine.glass_price;
				this.modal.price.bottle = wine.bottle_price;
				this.modal.price.offer_price = wine.offer_price;
				this.modal.price.bottle_amount = wine.bottle_amount;
				this.modal.position = wine.position;
				this.modal.buttontext = 'Tilføj til vinkort';
			},
			hideModalForAddingWineToWineMenu() {
				var split = this.available_positions.indexOf(this.modal.position);
				this.available_positions.splice(split, 1);

				this.showmodal = false;
				this.modal.wine = '';
				this.modal.price.glass = null;
				this.modal.price.bottle = null;
				this.modal.price.offer_price = null;
				this.modal.price.bottle_amount = null;
				this.modal.position = null;
			},
			resetPositionSelect() {
				$('#default-option').prop('selected', true);
				$('option.remove-me').remove();
			},
			addWineToWineMenu(submitEvent) {
				let glassPrice = this.modal.price.glass;
				let wine_id = this.modal.wine_id;
				let glassPriceNew = this.modal.price.offer_price ? this.modal.price.offer_price : null;
				let position = this.modal.position ? this.modal.position : null;
				let bottlePrice = this.modal.price.bottle ? this.modal.price.bottle : null;

				axios.post('/api/winemenu-wines', {
						'bottle_price': bottlePrice,
						'glass_price': glassPrice,
						'offer_price': glassPriceNew,
						'position': position,
						'wine_id': wine_id,
						'wine_menu_id': this.winemenu.id
					}).then((response) => {})
					.catch((error) => {
						console.warn(error);
					}).then(() => {
						this.hideModalForAddingWineToWineMenu();
						this.getWineMenuWines();
						this.getWineBarWines();
						this.getAvailablePositions();
					});
			},
			removeWineFromWineMenu(wine) {
				axios.delete('/api/winemenu-wines/' + this.winemenu.id + '/' + wine.id)
					.then((response) => {
						this.getAvailablePositions();
					})
					.catch((error) => {
						console.warn(error);
					}).then(() => {
						this.getWineMenuWines();
						this.getWineBarWines();
					});
			},
			editWineOnWineMenu(wine) {
				$('#modal-wine-menu').text(wine.name);
				this.modal.wine_id = wine.id;

				$('option.remove-me').remove();
				$('select#modal-wine-position').prepend('<option class="remove-me" value="' + wine.position + '">' + wine.position + '</option>');
				$('select#modal-wine-position').find('option[value="' + wine.position + '"]').prop('selected', true);

				this.available_positions.push(wine.position);
				this.available_positions.sort(function(a, b){return a-b});

				this.modal.wine = wine.name;
				this.modal.price.glass = wine.glass_price;
				this.modal.price.bottle = wine.bottle_price;
				this.modal.price.offer_price = wine.offer_price;
				this.modal.price.bottle_amount = wine.bottle_amount;
				this.modal.position = wine.position;
				this.modal.buttontext = 'Redigér vin';
				this.showmodal = true;
			},
			getWineBarWines(page = 1) {
				console.log(`getWineBarWines(${page})`);
				this.current_page = page;

				axios.get('/api/winebar-wines/' + this.winemenu.wine_bar_id + '/' + this.winemenu.id + '?page=' + page + '&limit=' + this.pr_page + '&exclude=true')
					.then((response) => {
						if (page <= 1) {
							 this.wine_bar_wines = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.wine_bar_wines = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getWineBarWines(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getGlobalWines(page = 1) {
				console.log(`getGlobalWines(${page})`);
				this.current_page = page;
				
				axios.get('/api/global-wines/' + this.winemenu.wine_bar_id + '?page=' + page + '&limit=' + this.pr_page)
					.then((response) => {
						console.log('Gloable vhine');
						console.log(response);
						if (page <= 1) {
							 this.global_wines = response.data;
						} else {
							if (response.data.data.length > 0) {
								this.global_wines = response.data;
							} else {
								page = page - 1;
								if (page > 0) {
									this.getGlobalWines(page);
								}
							}
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			addWineToWineBar(wine) {
				this.wine = wine;
				this.wine['wine_bar_id'] = this.winemenu.wine_bar_id;
				this.action = 'add';
				this.title = 'Tilføj vin';
				this.copy_wine = true;
			},
			editWine: function(wine) {
                this.wine = wine;
				this.action = 'edit';
				this.title = 'Rediger vin';
			},
			closeEditOpen: function(){
				this.wine = null;
			},
			hasPermission: function(permission) {
				return this.permissions.findIndex(x => x.name === permission) > -1;
			},
			searchFunction: function() {
				axios.post('/api/search/wines', {
					wine_menu: this.winemenu.id,
					search: this.search_field
				}).then((res) => {
					this.wine_menu_wines = res.data;
				}).catch((err) => {
					console.log(err);
				});
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';

	.center-row {
		text-align: center;
		justify-content: center;
	}

	.head-row {
		> div {
			display: flex;
			line-height: 1.2;
			align-items: flex-end;
		}

		i {
			font-size: 16px;
		}
	}

	.winemenu {
		&__price {
			@media(max-width: $sm - 1px){
				padding: 0;
			}
		}
	}
</style>