<template>
	<transition name="move-up-singlewine">
		<div class="single__wine" v-if="this.active">
			<div class="close_btn" @click="closeThis()">
				<i class="far fa-times"></i>
			</div>
			<div class="container-fluid">
				<div class="rowwrap position-relative p-3 p-sm-5">
					<div class="row">
						<div class="col-12 col-sm single__image--col">
							<div v-if="this.wine.images.length > 0" class="single__image">
								<img :src="'/storage' + this.wine.images[0].path" alt="">
							</div>
							<div v-else class="single__image">
								<img src="/images/placeholder_etiqette.jpg" alt="">
							</div>
						</div>
						<div class="col-12 col-sm">
							<h1 class="mt-4">{{ this.wine.name }}</h1>
							<h2 class="slim mt-3">
								<span class="d-block d-sm-inline" v-if="this.wine.vineyard.name != 'Ukendt'">{{ this.wine.vineyard.name }}</span>
								<span class="d-none d-sm-inline" v-if="this.wine.vineyard.name != 'Ukendt'"> - </span>
								<span class="d-block d-sm-inline"><span v-if="this.wine.vineyard.region.name != 'Ukendt'">{{ this.wine.vineyard.region.name }}, </span>{{ this.wine.vineyard.region.country.name }}</span>
							</h2>
							<div class="single__wine--prices mt-4">
								<div class="d-inline-block">
									<i class="fas fa-wine-bottle rotate--45"></i>
									{{ this.wine.bottle_price | numberformat }},-
								</div>
								<div class="d-inline-block float-right single__wine--position">
									#{{ this.wine.position }}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row px-3 px-sm-5">
					<div class="row py-4">
						<div class="col-12 col-sm-8">
							<div v-if="this.wine.description">
								<h2 class="primary">Om vinen</h2>
								<div class="prewrap mb-3">{{ this.wine.description }}</div>
							</div>
							<div v-if="this.wine.vineyard.description">
								<h2 class="primary">Om producenten</h2>
								<div class="mb-3">{{ this.wine.vineyard.description }}</div>
							</div>
							<div v-if="this.wine.reviews.length > 0">
								<h2 class="primary">Anmeldelser</h2>
								<div class="mb-3">{{ this.wine.reviews[0].content }}</div>
							</div>
						</div>
						<div class="col-12 col-sm-4 mt-4 mt-sm-0 single__wine--list">
							<div v-if="this.wine.bottle_amount">
								<h3 class="primary">Antal</h3>
								<div class="mb-4">{{ this.wine.bottle_amount }} flasker</div>
							</div>
							<div v-if="this.wine.vineyard.region.country.name">
								<h3 class="primary">Land</h3>
								<div class="mb-4">{{ this.wine.vineyard.region.country.name }}</div>
							</div>
							<div v-if="this.wine.vineyard.region.name">
								<h3 class="primary">Region</h3>
								<div class="mb-4">{{ this.wine.vineyard.region.name }}</div>
							</div>
							<div v-if="this.wine.vineyard.name">
								<h3 class="primary">Vingård</h3>
								<div class="mb-4">{{ this.wine.vineyard.name }}</div>
							</div>
							
							<div v-if="this.wine.year">
								<h3 class="primary">År</h3>
								<div class="mb-4">{{ this.wine.year }}</div>
							</div>
							<div v-if="this.wine.alc_vol">
								<h3 class="primary">Alkohol</h3>
								<div class="mb-4">{{ this.wine.alc_vol }} %</div>
							</div>
							<div v-if="this.wine.cask != 0">
								<h3 class="primary">Fadlagring</h3>
								<div class="mb-4">{{ this.wine.cask_month }} måneder</div>
							</div>
							<div v-if="this.wine.grapes">
								<h3 class="primary">Druer</h3>
								<div class="mb-4">
									<span class="commaseparated" v-for="(grape, k) in this.wine.grapes" :key="k">{{ grape.name }}</span>
								</div>
							</div>
							<div v-if="assessment">
								<h3 class="primary">Vurdering</h3>
								<div class="mb-4">
									<template v-for="(assess, a) in assessment">
										<span :key="`title_${a}`" class="font-weight-bold">{{ capitalize(a) }}</span>
										<ul :key="`values_${a}`" class="pl-0 list-unstyled">
											<li v-for="(val, v) in assess" :key="v">
												{{ capitalize(v) }}: <i>{{ val }}</i>
											</li>
										</ul>
									</template>
								</div>
							</div>
							<div v-if="this.wine.webshops">
								<h3 class="primary">Køb vinen</h3>
								<div class="mb-4">
									<div v-for="(webshop, k) in this.wine.webshops" :key="k" class="text-ellipsis">
										<a :href="webshop.url" target="_blank">{{ webshop.url }}</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		mounted() {},
		created() {},
		data() {
			return {
				assessmentValues: [
					{ label: 'Pale', value: 1 },
					{ label: 'Medium', value: 2 },
					{ label: 'Deep', value: 3 },
					{ label: 'Lemon', value: 4 },
					{ label: 'Gold', value: 5 },
					{ label: 'Amber', value: 6 },
					{ label: 'Light', value: 7 },
					{ label: 'Medium', value: 8 },
					{ label: 'Pronounced', value: 9 },
					{ label: 'Dry', value: 10 },
					{ label: 'Off-Dry', value: 11 },
					{ label: 'Medium', value: 12 },
					{ label: 'Sweet', value: 13 },
					{ label: 'Low', value: 14 },
					{ label: 'Medium', value: 15 },
					{ label: 'High', value: 16 },
					{ label: 'Low', value: 17 },
					{ label: 'Medium', value: 18 },
					{ label: 'High', value: 19 },
					{ label: 'Low', value: 20 },
					{ label: 'Medium', value: 21 },
					{ label: 'High', value: 22 },
					{ label: 'Low', value: 23 },
					{ label: 'Medium', value: 24 },
					{ label: 'Pronounced', value: 25 },
					{ label: 'Short', value: 26 },
					{ label: 'Medium', value: 27 },
					{ label: 'Long', value: 28 },
					{ label: 'Pale', value: 29 },
					{ label: 'Medium', value: 30 },
					{ label: 'Deep', value: 31 },
					{ label: 'Pink', value: 32 },
					{ label: 'Pink-Orange', value: 33 },
					{ label: 'Orange', value: 34 },
					{ label: 'Light', value: 35 },
					{ label: 'Medium', value: 36 },
					{ label: 'Pronounced', value: 37 },
					{ label: 'Dry', value: 38 },
					{ label: 'Off-Dry', value: 39 },
					{ label: 'Medium', value: 40 },
					{ label: 'Sweet', value: 41 },
					{ label: 'Low', value: 42 },
					{ label: 'Medium', value: 43 },
					{ label: 'High', value: 44 },
					{ label: 'Low', value: 45 },
					{ label: 'Medium', value: 46 },
					{ label: 'High', value: 47 },
					{ label: 'Low', value: 48 },
					{ label: 'Medium', value: 49 },
					{ label: 'High', value: 50 },
					{ label: 'Low', value: 51 },
					{ label: 'Medium', value: 52 },
					{ label: 'Pronounced', value: 53 },
					{ label: 'Short', value: 54 },
					{ label: 'Medium', value: 55 },
					{ label: 'Long', value: 56 },
					{ label: 'Pale', value: 57 },
					{ label: 'Medium', value: 58 },
					{ label: 'Deep', value: 59 },
					{ label: 'Purple', value: 60 },
					{ label: 'Ruby', value: 61 },
					{ label: 'Garnet', value: 62 },
					{ label: 'Tawny', value: 63 },
					{ label: 'Light', value: 64 },
					{ label: 'Medium', value: 65 },
					{ label: 'Pronounced', value: 66 },
					{ label: 'Dry', value: 67 },
					{ label: 'Off-Dry', value: 68 },
					{ label: 'Medium', value: 69 },
					{ label: 'Sweet', value: 70 },
					{ label: 'Low', value: 71 },
					{ label: 'Medium', value: 72 },
					{ label: 'High', value: 73 },
					{ label: 'Low', value: 74 },
					{ label: 'Medium', value: 75 },
					{ label: 'High', value: 76 },
					{ label: 'Low', value: 77 },
					{ label: 'Medium', value: 78 },
					{ label: 'High', value: 79 },
					{ label: 'Low', value: 80 },
					{ label: 'Medium', value: 81 },
					{ label: 'High', value: 82 },
					{ label: 'Low', value: 83 },
					{ label: 'Medium', value: 84 },
					{ label: 'Pronounced', value: 85 },
					{ label: 'Short', value: 86 },
					{ label: 'Medium', value: 87 },
					{ label: 'Long', value: 88 },
				]
			}
		},
		props: ['wine', 'active', 'parentCloseThis', 'parentOpenSendLink', 'offer'],
		methods: {
			capitalize: function(value) {
                value = value.toString();

                return value.charAt(0).toUpperCase() + value.slice(1);
            },
			closeThis: function() {
				this.parentCloseThis();
			},
			openSendLink: function() {
				this.parentOpenSendLink();
			},
		},
		computed: {
			assessment() {
				if (!this.wine.assessment) return null

				const assessment = {}
				const translate = false
				let wineAssessment = this.wine.assessment

				delete wineAssessment.id
				delete wineAssessment.wine_id
				delete wineAssessment.created_at
				delete wineAssessment.updated_at

				Object.entries(wineAssessment).forEach((entry) => {
					let column = entry[0]
					let value = entry[1]
					let split = column.split('_')
					let splitColumn = split[0]
					let splitValue = split[1]

					if (translate) {
						if (splitColumn == 'nose') {
							splitColumn = 'næse'
						} else if (splitColumn == 'appearance') {
							splitColumn = 'udseende'
						}
	
						if (splitValue == 'sweetness') {
							splitValue = 'sødhed'
						} else if (splitValue == 'acidity') {
							splitValue = 'surhedsgrad'
						} else if (splitValue == 'tannin') {
							splitValue = 'tannin / Garvesyre'
						} else if (splitValue == 'alcohol') {
							splitValue = 'alkohol'
						} else if (splitValue == 'flavor') {
							splitValue = 'smag'
						} else if (splitValue == 'intensity') {
							splitValue = 'Intensitet'
						} else if (splitValue == 'color') {
							splitValue = 'farve'
						}
					}

					if (!(splitColumn in assessment)) {
						assessment[splitColumn] = {}
					}

					let assessmentObject = this.assessmentValues.find(a => a.value == value)

					if (assessmentObject) {
						assessment[splitColumn][splitValue] = assessmentObject.label
					}
				})

				return assessment
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../../sass/utils/_variables.scss';
	@import '../../../../sass/utils/_placeholders.scss';

	.single {
		&__wine {
			top: 0;
			//left: 0;
			right: 50%;
			width: 100%;
			z-index: 49;
			height: 100vh;
			overflow: auto;
			position: fixed;
			max-width: 768px;
			background-color: $body-bg-front;

			&:not(.move-up-singlewine-enter-active):not(.move-up-singlewine-leave-active):not(.move-up-singlewine-enter-to):not(.move-up-singlewine-leave):not(.move-up-singlewine-enter):not(.move-up-singlewine-leave-to ){
				transform: translateX(50%);
			}

			.fakelink {
				color: $champagne-color;
				text-decoration: underline;
			}

			&--prices {
				font-size: 20px;

				@media(min-width: $sm){
					font-size: 24px;
				}
			}
			
			&--position {
				font-weight: 400;
			}
			
			&--list {
				/*display: flex;
				flex-wrap: wrap;*/

				> div {
					max-width: 50%;
					flex-basis: 50%;

					@media(min-width: $sm){
						max-width: 100%;
						flex-basis: 100%;
					}
				}
			}
		}

		&__image {
			padding: 10px;
			max-width: 150px;
			text-align: center;
			border-radius: 4px;
			background-color: $white-color;

			&--col {
				max-width: 180px;
			}
			
			img {
				max-width: 130px;
				max-height: 175px;
			}
		}
	}

	.close_btn {
		top: 15px;
    	right: 15px;
		width: 40px;
		z-index: 25;
		height: 40px;
		display: flex;
		cursor: pointer;
		font-size: 26px;
		position: absolute;
		text-align: center;
		border-radius: 0%;
		color: $white-color;
		align-items: center;
		justify-content: center;
		transition: .3s ease-out;
		border: 2px solid $white-color;
		background-color: rgba($black-color, .25);
		box-shadow: 0 0 5px rgba($black-color, .25);

		.front & {
			width: 46px;
			height: 46px;
			border-radius: 50%;
			border-width: 4px;
		}

		&.dark {
			color: $darkestgray-color;
			border-color: $darkestgray-color;

			body:not(.front) & {
				&:hover {
					color: $white-color;
					background-color: $darkestgray-color;
				}
			}
		}

		i {
			transition: transform .3s ease-out;
		}

		&:hover {
			i {
				transform: rotate(270deg);
				transform-origin: center;
			}
		}

		.navigation & {
			top: auto;
			right: 10px;
			bottom: 10px;

			@media(min-width: $sm){
				top: 25px;
				right: 25px;
				bottom: auto;
			}
		}

		@media(min-width: $sm){
			top: 25px;
			right: 25px;
			bottom: auto;
			box-shadow: none;
			background-color: transparent;
		}

		&.small_move {
			top: 0px;
			right: 15px;

			.front & {
				right: 12px;
			}
		}
	}
</style>