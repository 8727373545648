<template>
	<div>
		<div class="row px-3 px-sm-5">
			<div class="row py-3 mt-3 mt-sm-5">
				<div class="col-12 col-sm-6 mb-4" v-for="drink in wine_menu_drinks" :key="drink.id">
					<div class="wine__item" @click="openDrink(drink)">
						<div class="wine__item--prices"></div>

						<div class="wine__item--content flex-row">
							<div class="wine__item--drink">
								<div class="wine__item--content__image mb-2">
									<img v-if="(drink.primary_image) && (drink.primary_image != null) && (drink.images.length > 0)" :src="'/storage' + drink.images[drink.primary_image].path" alt="">
									<img v-else-if="drink.images.length > 0" :src="'/storage' + drink.images[0].path" alt="">
									<img v-else src="/images/placeholder_etiqette.jpg" alt="">
								</div>
							</div>

							<div class="wine__item--drink">
								<h2 class="wine__item--content__title justify-content-start flex-grow-0">{{ drink.name }}</h2>
								<div class="text-left flex-grow-1">{{ implodeIngredients(drink) }}</div>
							</div>
						</div>

					</div>
				</div>

				<div class="col-12 d-flex justify-content-center align-items-center">
					<scroll-loader :loader-method="getWineMenuDrinks" :loader-disable="disable" :loader-size="55" :loader-distance="200"></scroll-loader>
				</div>
			</div>
		</div>

		<private-single-drink :active="single.active" :drink="single.drink" :parentCloseThis="parentCloseThis" :wine_bar="this.wine_bar"></private-single-drink>

		<private-navigation :active="false" :wine_bar="this.wine_bar"></private-navigation>
	</div>
</template>

<script>
	export default {
		mounted() {
			
		},
		data() {
			return {
				wine_menu_drinks: [],
				disable: false,
				current_page: 1,
				pageSize: 1000,
				single: {
					active: false,
					drink: {},
				},
			}
		},
		props: ['drinkmenu', 'winebar', 'wine_bar'],
		methods: {
			implodeIngredients: function(drink) {
				let ingredients = '';

				drink.ingredients.forEach((ingredient, i) => {
					let seperator = i + 1 == drink.ingredients.length ? ' ' : ', ';

					ingredients += ingredient.name + seperator;
				});

				return ingredients;
			},
			getWineMenuDrinks() {
				axios.get('/api/drinkmenu-drinks/' + this.drinkmenu.id + '?page=' + this.current_page)
				// axios.get('/api/drinks/' + this.winemenu.id + '?page=' + this.current_page)
				// axios.get('/api/drinks/')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.wine_menu_drinks = this.wine_menu_drinks.concat(response.data.data);
						}

						this.current_page = this.current_page + 1;
						this.disable = response.data.data.length < this.pageSize;
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			openDrink(drink) {
				this.single.active = true;
				this.single.drink = drink;
			},
			openDrinkParent: function(drink){
				this.single.active = true;
				this.single.drink = drink;
			},
			toggleMenuOpen() {
				$('body').toggleClass('open--menu');
			},
			parentCloseThis: function() {
				this.single.active = false;
			},
		}
	}
</script>

<style lang="scss">
	@import '../../../../sass/utils/_variables.scss';
	@import '../../../../sass/utils/_placeholders.scss';
</style>