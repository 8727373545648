<template>
	<div class="white-background pb-5 pt-3 mh-100">
        <div :class="{ 'pb-5' : !hidenav }">
            <div class="container-fluid px-3 px-sm-5" :class="{ 'pb-5' : !hidenav }">
                <div class="row" v-for="(item, i) in JSON.parse(this.foodmenu.content).content" :key="i">
                    <div class="col-12 mb-3">
                        <hr class="spacer">
                        <h1 class="text-center slim mt-4 mb-1">{{ item.name }}</h1>
                        <div class="text-center" v-if="item.description != ''">{{ item.description }}</div>
                    </div>
                    <div class="col-12">
                        <table class="foodmenu__table" v-for="(product_list, k) in item.content" :key="k">
                            <thead v-if="product_list.name != ''">
                                <tr>
                                    <th colspan="2">
                                        {{ product_list.name }}
                                        <span v-if="product_list.description != ''">({{ product_list.description }})</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(product, l) in product_list.products" :key="l">
                                    <td>{{ product.name }}</td>
                                    <td align="right">{{ product.price | priceformat }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <frontend-navigation :active="false" :wine_bar_slug="this.wine_bar.slug"></frontend-navigation>
        </div>
	</div>
</template>

<script>
	export default {
		mounted() {
            // Setup food menu
            this.content = JSON.parse(this.foodmenu.content);
        },
		created() {},
		data() {
			return {
                content: ''
            }
		},
		props: ['foodmenu', 'hidenav', 'wine_bar'],
		methods: {
        }
	}
</script>