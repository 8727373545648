<template>
	<div>
		<div class="row">
			<div class="col-12">
				<h1>{{ this.headline }}</h1>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-lg-8 col-xl-7">
				<div class="input__wrapper">
					<label for="name">Mail navn</label>
					<input name="name" id="name" @input="editsmade = true;" type="text" v-model="name">
				</div>
				<div class="input__wrapper">
					<label for="subject">Mail emne</label>
					<input name="subject" id="subject" @input="editsmade = true;" type="text" v-model="subject">
				</div>
				<div class="input__wrapper">
					<label for="subject">Indhold</label>
					<div class="input__shorcodes">
						<ul>
							<li class="arrow" :class="{ 'open' : showShortcodes }" @click="toggleShortcodes"></li>
							<li @click="addToContent('name')" v-if="showShortcodes">Modtagerens navn</li>
							<li @click="addToContent('wines')" v-if="showShortcodes">Vin</li>
							<li @click="addToContent('links')" v-if="showShortcodes">Links</li>
							<li @click="addToContent('headline')" v-if="showShortcodes">Overskrift</li>
							<li @click="addToContent('bold')" v-if="showShortcodes">Fed</li>
						</ul>
					</div>
					<textarea id="body" @focus="removeShortcodes" @input="editsmade = true;setPreview();" rows="15" v-model="body"></textarea>
				</div>
			</div>
			
			<div class="col-12 col-lg-4 col-xl-5">
				<div class="d-flex align-items-sm-end flex-column flex-sm-row-reverse justify-content-between mb-3">
					<div class="buttons mb-5 mb-sm-0">
						<div v-if="false" class="button icon__left btn-faded" @click="closeSingleMail">
							<i class="far fa-chevron-left"></i>
							Tilbage
						</div>
						<button class="primary mt-3 mt-md-0" :disabled="!editsmade" :class="{ 'success icon' : edit_success }" @click="save()" v-if="this.type == 'edit'">
							<span v-if="!edit_success">Gem ændringer</span>
							<span v-if="edit_success">Ændringer gemt <i class="far fa-check"></i></span>
						</button>
						<button class="primary icon mt-3 mt-md-0" :disabled="!editsmade" v-if="this.type == 'create'">
							<span>Opret mail</span>
							<i class="far fa-plus"></i>
						</button>
					</div>

					<h3>Forhåndsvisning</h3>
				</div>
				<div class="preview mail auto">
					<div class="preview__wrap mail">
						<table>
							<thead>
								<tr>
									<td>{{ mail.wine_bar.name }}</td>
								</tr>
							</thead>

							<tbody>
								<tr>
									<td v-html="html.body"></td>
								</tr>
							</tbody>

							<tfoot>
								<tr>
									<td></td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
		props: [
			'headline',
			'type',
			'mail',
			'parentCloseSingleMail'
		],
		data() {
			return {
				editsmade: false,
				edit_success: false,
				showShortcodes: false,
				name: '',
				subject: '',
				body: '',
				html: {
					body: '',
				}
			}
		},
		mounted() {
			if(this.mail != ''){
				this.name = this.mail.name;
				this.subject = this.mail.subject;
				this.body = this.mail.body;

				this.setPreview();
			}
		},
		methods: {
			removeShortcodes: function(){
				this.showShortcodes = false;
			},
			toggleShortcodes: function(){
				this.showShortcodes = (this.showShortcodes) ? false : true;
			},
			addToContent: function(type){
				var myField = document.getElementById('body');
				var myValue = '[' + type + ']';
				var endPos = myField.selectionEnd;

				if(type == 'bold' || type == 'headline'){
					var selectedText = '';
					if (myField.selectionStart !== undefined) {// Standards Compliant Version
						var startPos = myField.selectionStart;
						var endPos = myField.selectionEnd;
						selectedText = myField.value.substring(startPos, endPos);
					} else if (document.selection !== undefined) {// IE Version
						myField.focus();
						var sel = document.selection.createRange();
						selectedText = sel.text;
					}

					var type_text = '';
					var type_tag = '';
					if(type == 'bold'){
						type_text = 'fed';
						type_tag = 'b';
					} else if(type == 'headline'){
						type_text = 'til overskrift';
						type_tag = 'h';
					}
					
					if(!selectedText){
						alert('Du skal markere den tekst du vil gøre ' + type_text);
					} else {
						replaceSelectedText(myField, '[' + type_tag + ']' + selectedText + '[/' + type_tag +']');
					}
				} else {
					//IE support
					if (document.selection) {
						myField.focus();
						sel = document.selection.createRange();
						sel.text = myValue;
					}
					//MOZILLA and others
					else if (myField.selectionStart || myField.selectionStart == '0') {
						var startPos = myField.selectionStart;
						myField.value = myField.value.substring(0, startPos)
							+ myValue
							+ myField.value.substring(endPos, myField.value.length);
					} else {
						myField.value += myValue;
					}

					myField.focus();
					myField.selectionEnd = endPos + myValue.length;
				}

				this.editsmade = true;
				this.body = myField.value;

				this.removeShortcodes();
				this.setPreview();
			},
			setPreview: function(){
				var html = this.body.replace('[h]', '<h1>');
				html = html.replace('[/h]', '</h1>');
				html = html.replace('[b]', '<strong>');
				html = html.replace('[/b]', '</strong>');
				
				var html = this.body.split('[h]').join('<h1>');
				html = html.split('[/h]').join('</h1>');
				html = html.split('[b]').join('<strong>');
				html = html.split('[/b]').join('</strong>');

				this.html.body = html;
			},
			save() {
				var _this = this;

				axios.patch('/api/mails/' + this.mail.id, {
					name: this.name,
					subject: this.subject,
					body: this.body,
				})
				.then(function (response) {
					_this.edit_success = true;

					setTimeout(function() {
						_this.editsmade = false;
					}, 2000);
				})
				.catch(function (error) {
					console.warn(error);
				});
			},
			closeSingleMail: function(){
				this.parentCloseSingleMail();
			}
		}
    }
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';

	.preview.mail {
		table {
			width: 100%;
			background-color: $white-color;

			thead,
			tfoot {
				color: $white-color;
				text-align: center;
				background-color:$primary-color;
			}

			thead {
				td {
					height: 150px;
					line-height: 150px;
				}
			}
			
			tfoot {
				td {
					padding: 15px;
					font-size: 12px;
				}
			}

			tbody {
				td {
					font-size: 14px;
					padding: 35px 20px;
					border-style: solid;
					white-space: pre-wrap;
					border-width: 0px 2px;
					border-color: $lightgray-shade;
				}
			}
		}

		h1 {
			margin: 0;
			text-transform: uppercase;

			+ p {
				margin-top: 0;
			}
		}
	}
</style>