<template>
	<div>
		<div>
			<form id="wine-course-form" v-on:submit.prevent="shouldUpdate ? updateWineCourse() : storeWineCourse()">
				<div class="row" style="width: calc(100% + 30px);">
					<div class="col-12">
						<h1 class="mb-3">{{ modaltitle }}</h1>
					</div>
					<div class="col-12">
						<div @click="closeModalParent" id="close-modal" class="button btn-faded float-right icon__left">
							<i class="far fa-chevron-left"></i>
							Tilbage
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="row">
							<div class="col-12">
								<div class="input__wrapper">
									<label for="title">Titel</label>
									<input type="text" id="title" class="input" v-model="wine_course.title">
								</div>
							</div>

							<div class="col-12">
								<div class="input__wrapper">
									<label for="subtitle">Undertitel</label>
									<input type="text" id="subtitle" class="input" v-model="wine_course.subtitle">
								</div>
							</div>

							<div class="col-12">
								<label>Vælg vin</label>
							</div>
							<div class="col-12">
								<p>Du kan vælge alle de vin du har lyst til, fra din liste af aktive vine.<br>
								Brug evt. søgefeltet i højre side, for at finde specifikke vine.</p>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 search align-items-end justify-content-end d-flex">
						<div class="winecourse__search">
							<div class="row">
								<div class="col-12 col-md-6">
									<label for="country">Land</label>
									<v-select label="name" :options="countries.data" v-model="search.country" @input="searchAvailableWines"></v-select>
								</div>

								<div class="col-12 col-md-6">
									<label for="type">Type</label>
									<v-select label="name" :options="types.data" v-model="search.type" @input="searchAvailableWines"></v-select>
								</div>

								<div class="col-12">
									<div class="input__wrapper">
										<label for="search">Søg</label>
										<input type="text" id="search" class="input" v-model="search.text" @input="searchAvailableWines">
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12 select__wine--col">
						<div v-for="wine in wines.data" v-bind:key="wine.id" class="select__wine--wrap">
							<label :for="'selected-wine-' + wine.id">
								<input :id="'selected-wine-' + wine.id" type="checkbox" :value="wine.id" class="select__wine--checkbox" v-model="wine_course.selected_wines">
								<div class="select__wine">
									<img v-if="wine.images.length > 0" :src="'/storage' + wine.images[0].path" alt="" width="150" height="200">
									<img v-else src="https://via.placeholder.com/150x200" alt="">
									<div class="select__wine--content">
										<div>{{ wine.name }}</div>
										<strong>{{ wine.bottle_price | numberformat }},- / flaske</strong>
									</div>
									<div class="check"><i class="far fa-check"></i></div>
								</div>
							</label>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<div class="input__wrapper mt-4">
							<label for="price">Pris</label>
							<input type="number" id="price" class="" v-model="wine_course.price">
							<div class="append">kr.</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<div @click="closeModalParent" class="button btn-faded icon__left">
							<i class="far fa-chevron-left"></i>
							Tilbage
						</div>

						<button type="submit" class="icon primary">
							{{ this.modaltitle }}
							<i class="far fa-chevron-right"></i>
						</button>
					</div>
				</div>
			</form>
			<i id="success" class="fad fa-check d-none"></i>
		</div>
		<transition name="fade">
			<div class="scrolldown" v-if="scrolldown" @click="moveScrollDown()">
				<div class="far fa-chevron-down"></div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.getAvailableWineCourseWines();
			this.getCountries();
			this.getTypes();

			var self = this;

			$(window).scroll(function(){
				var ww = $(window).width();
				if(ww < 768){
					var scrolltop = $(window).scrollTop();
					var scrollwhen = $('.select__wine--col').offset().top;
					var pageheight = $('body').height() - ($(window).height() * 1.5);

					if(scrolltop > scrollwhen && scrolltop < pageheight){
						self.scrolldown = true;
					} else {
						self.scrolldown = false;
					}
				}
			});
		},
		created() {},
		data() {
			return {
				wines: [],
				types: [],
				countries: [],
				search: {
					country: '',
					type: '',
					text: '',
				},
				scrolldown: false,
			}
		},
		props: {
			wine_bar: {
				type: Object,
			},
			wine_course: {
				type: Object,
			},
			shouldUpdate: {
				type: Boolean,
			},
			closeModalParent: {
				type: Function
			},
			modaltitle: {
				type: String
			},
		},
		methods: {
			searchAvailableWines: function() {
				let filters = {
					country: null,
					type: null,
					text: null,
				};

				filters.country = (this.search.country != '') ? this.search.country.id : null;
				filters.type = (this.search.type != '') ? this.search.type.id : null;
				filters.text = (this.search.text != '') ? this.search.text : null;

				axios.post('/api/search/available-winecourse-wines', {
					wine_bar_id: this.wine_bar.id,
					filters: filters,
				})
				.then((response) => {
					this.wines = response.data;
					console.log(this.wines);
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			getAvailableWineCourseWines() {
				axios.get('/api/available-winecourse-wines/' + this.wine_bar.id)
					.then((response) => {
						this.wines = response.data;
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			storeWineCourse() {
				var self = this;

				axios.post('/api/winecourses', {
					title: this.wine_course.title,
					subtitle: this.wine_course.subtitle,
					selected_wines: this.wine_course.selected_wines,
					price: this.wine_course.price,
					wine_bar_id: this.wine_bar.id,
				}).then((response) => {
					$('#success').removeClass('d-none');
					self.closeModalParent();
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			updateWineCourse() {
				var self = this;

				axios.patch('/api/winecourses/' + this.wine_course.id, {
					title: this.wine_course.title,
					subtitle: this.wine_course.subtitle,
					selected_wines: this.wine_course.selected_wines,
					price: this.wine_course.price,
					wine_bar_id: this.wine_bar.id,
				}).then((response) => {
					$('#success').removeClass('d-none');
					self.closeModalParent();
				})
				.catch((error) => {
					console.warn(error);
				});
			},
			getCountries() {
				axios.get('/api/countries?limit=10000')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.countries = response.data;

							this.countries.data.unshift({
								id: null,
								name: 'Ingen valgt'
							});
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			getTypes() {
				axios.get('/api/wine-types')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.types = response.data;

							this.types.data.unshift({
								id: null,
								name: 'Ingen valgt'
							});
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			moveScrollDown() {
				$('body, html').animate( {'scrollTop': $('body').height()}, 800 );
			}
		}
	}
</script>