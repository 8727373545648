<template>
	<div class="row">
		<div class="col-12 col-md-6">
			<div class="input__wrapper">
				<h6>Vinbar info</h6>
			</div>

			<div class="input__wrapper" :class="{ 'error': winebar.name.error, 'required': winebar.name.required }">
				<label for="company">Navn</label>
				<input type="text" name="company" @focus="winebar.name.error = false" id="company" v-model="winebar.name.content">
				<div v-if="winebar.name.error" class="input__error">{{ winebar.name.errormsg }}</div>
			</div>

			<div class="input__wrapper">
				<label for="type">Type</label>
				<v-select label="name" @input="changeType" :options="types" v-model="winebar.type"></v-select>
			</div>
			
			<div class="input__wrapper" :class="{ 'error': winebar.billing_mail.error, 'required': winebar.billing_mail.required }" v-if="!existing">
				<label for="billing_mail">Fakturerings email</label>
				<input type="email" @focus="winebar.billing_mail.error = false" id="billing_mail" v-model="winebar.billing_mail.content">
				<div v-if="winebar.billing_mail.error" class="input__error">{{ winebar.billing_mail.errormsg }}</div>
			</div>

			<div class="input__wrapper" :class="{ 'error': winebar.customer.error, 'required': winebar.customer.required }" v-if="existing">
				<label for="customer">Kunde</label>
				<v-select label="name" :options="customers.data" id="customer" v-model="winebar.customer.content"></v-select>
				<div v-if="winebar.customer.error" class="input__error">{{ winebar.customer.errormsg }}</div>
			</div>

			<div class="input__wrapper">
				<label for="existing">Tilføj til eksisterende kunde</label>
				<label class="switch">
					<input id="existing" type="checkbox" v-model="existing">
					<span class="switch__slider"></span>
				</label>
			</div>
		</div>

		<div class="col-12 col-md-6">
			<div class="input__wrapper">
				<h6>Brugeroplysninger</h6>
			</div>

			<div class="input__wrapper" :class="{ 'error': user.name.error, 'required': user.name.required }">
				<label for="name">Navn</label>
				<input type="text" @focus="user.name.error = false" id="name" v-model="user.name.content">
				<div v-if="user.name.error" class="input__error">{{ user.name.errormsg }}</div>
			</div>

			<div class="input__wrapper" :class="{ 'error': user.email.error, 'required': user.email.required }">
				<label for="email">Email</label>
				<input type="email" @focus="user.email.error = false" id="email" v-model="user.email.content">
				<div v-if="user.email.error" class="input__error">{{ user.email.errormsg }}</div>
			</div>

			<div class="input__wrapper" :class="{ 'error': user.password.error, 'required': user.password.required }">
				<label for="password">Kodeord</label>
				<input type="password" @focus="user.password.error = false" id="password" v-model="user.password.content">
				<div v-if="user.password.error" class="input__error">{{ user.password.errormsg }}</div>
			</div>
		</div>

		<div class="col-12">
			<button v-if="!created" class="primary mt-3" @click="createWinebar">{{ button_text }}</button>
			<button v-if="created" class="primary mt-3">
				Oprettet
				<i class="fas fa-check"></i>
			</button>
		</div>
    </div>
</template>

<script>
    export default {
		props: {
            
        },
		data() {
			return {
				created: false,
				button_text: 'Opret vinbar',
				existing: false,
				customers: [],
				types: [
					{
						name: 'Erhverv (Vinbar)',
						slug: 'not_private',
						type: 1,
					},
					{
						name: 'Privat (Vinkælder)',
						slug: 'private',
						type: 2,
					},
				],
				winebar: {
					name: {
						required: true,
						content: '',
						error: false,
						errormsg: 'Fejl!',
					},
					type: {
						name: 'Erhverv (Vinbar)',
						slug: 'not_private',
						type: 1,
					},
					billing_mail: {
						required: true,
						content: '',
						error: false,
						errormsg: 'Fejl!',
					},
					customer: {
						required: true,
						content: [],
						error: false,
						errormsg: 'Fejl!',
					}
				},
				user: {
					name: {
						required: true,
						content: '',
						error: false,
						errormsg: 'Fejl!',
					},
					email: {
						required: true,
						content: '',
						error: false,
						errormsg: 'Fejl!',
					},
					password: {
						required: true,
						content: '',
						error: false,
						errormsg: 'Fejl!',
					},
				}
			}
		},
        created() {
			this.getCustomers();
		},
		methods: {
			getCustomers: function() {
				axios.get('/api/customers?limit=99999')
					.then((response) => {
						if (response.data.data.length > 0) {
							this.customers = response.data;
						}
					})
					.catch((error) => {
						console.warn(error);
					});
			},
			changeType: function() {
				switch(this.winebar.type.slug) {
					case 'private':
						this.button_text = 'Opret vinkælder';
						break;
					default:
						this.button_text = 'Opret vinbar';
						break;
				}
			},
			createWinebar: function() {
				let errors = this.validateForm();

				if (!errors) {
					let data = {
						winebar: this.winebar.name.content,
						new_customer: !this.existing,
						is_private: this.winebar.type.type == 2,
						name: this.user.name.content,
						email: this.user.email.content,
						password: this.user.password.content,
					};

					if (this.existing) {
						data.customer_id = this.winebar.customer.content.id;
					} else {
						data.billing_mail = this.winebar.billing_mail.content;
					}

					console.log(data);

					axios.post('/api/winebars', data)
						.then((response) => {
							console.log(response);
							this.created = true;
						})
						.catch((error) => {
							console.warn(error);
						});
				}
			},
			validateForm: function() {
				this.winebar.name.error = this.winebar.name.content == '';
				this.winebar.billing_mail.error = !this.existing && (this.winebar.billing_mail.content == '');
				this.winebar.customer.error = this.existing && (this.winebar.customer.content == '');

				this.user.name.error = this.user.name.content == '';
				this.user.email.error = this.user.email.content == '';
				this.user.password.error = this.user.password.content == '';

				return this.winebar.name.error ||
					this.winebar.billing_mail.error ||
					this.winebar.customer.error ||
					this.user.name.error ||
					this.user.email.error ||
					this.user.password.error;
			}
		}
    }
</script>

<style lang="scss">
	@import '../../../sass/utils/_variables.scss';
	@import '../../../sass/utils/_placeholders.scss';
</style>